import React, {FC, forwardRef, InputHTMLAttributes, Ref, useState} from 'react';
import s from './index.module.scss';
import classNames from 'classnames/bind'
import {TextField} from "@components/modules";
import {TextFieldPropsType} from "@components/UI/ReactForm/TextField/index.props";
import {EyeCloseIcon, EyeOpenIcon } from '@icons/eyeSvg';

const cx = classNames.bind(s)



export const TextFieldPassword: FC<Omit<TextFieldPropsType, 'rightControl'> & InputHTMLAttributes<HTMLInputElement>> =
  forwardRef(({classNameInputWrap, showPassword = false, ...propsField}, ref: Ref<HTMLInputElement>) => {
    const [show, setShow] = useState(showPassword)
    const toggle = () => setShow(!show)
    return (
      <TextField
        // @ts-ignore
        ref={ref}
        classNameInputWrap={cx('InputWrap', classNameInputWrap || '')}
        rightControl={<button type={'button'} onClick={toggle}>{show ? <EyeOpenIcon/> : <EyeCloseIcon/>} </button>}
        {...propsField}
        type={!show ? "password" : "text"}
      />
    )
  })

