import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  /**
   * Leverages the internal Python implmeentation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
};

export type CreateDay = {
  day?: Maybe<DayType>;
  errors?: Maybe<ErrorEnum>;
};

export type CreateEvent = {
  event?: Maybe<EventType>;
  errors?: Maybe<ErrorEnum>;
};

export type CreateOrganizer = {
  organizer?: Maybe<OrganizerType>;
  errors?: Maybe<ErrorEnum>;
};

export type CreateTicket = {
  ticket?: Maybe<TicketType>;
  errors?: Maybe<ErrorEnum>;
};

export enum CurrencyEnum {
  Eur = 'EUR'
}



export type DayInput = {
  id?: Maybe<Scalars['UUID']>;
  eventId?: Maybe<Scalars['UUID']>;
  begins?: Maybe<Scalars['DateTime']>;
  ends?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  pin?: Maybe<PinInput>;
  locationForAllDays?: Maybe<Scalars['Boolean']>;
};

export type DayType = {
  id?: Maybe<Scalars['UUID']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  begins?: Maybe<Scalars['DateTime']>;
  ends?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  pin?: Maybe<PinType>;
};


export type EditUser = {
  user?: Maybe<UserType>;
  errors?: Maybe<ErrorEnum>;
};

export enum ErrorEnum {
  NotFoundError = 'NOT_FOUND_ERROR',
  UserNotFoundError = 'USER_NOT_FOUND_ERROR',
  InvalidPasswordOrEmail = 'INVALID_PASSWORD_OR_EMAIL',
  InvalidPassword = 'INVALID_PASSWORD',
  ValidationError = 'VALIDATION_ERROR',
  ValidationPhoneError = 'VALIDATION_PHONE_ERROR',
  ValidationEmailError = 'VALIDATION_EMAIL_ERROR',
  ValidationPasswordError = 'VALIDATION_PASSWORD_ERROR',
  DuplicateEmail = 'DUPLICATE_EMAIL',
  DuplicatePhone = 'DUPLICATE_PHONE',
  ErrorResult = 'ERROR_RESULT'
}

export type EventInput = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  defaultLanguage?: Maybe<LanguageEnum>;
  translations?: Maybe<EventTranslationsInput>;
  isAllLanguages?: Maybe<Scalars['Boolean']>;
};

export enum EventStatusEnum {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Past = 'PAST',
  Canceled = 'CANCELED'
}

export type EventTranslationsInput = {
  nameEn?: Maybe<Scalars['String']>;
  nameLn?: Maybe<Scalars['String']>;
  descriptionEn?: Maybe<Scalars['String']>;
  descriptionLn?: Maybe<Scalars['String']>;
  imageEn?: Maybe<Scalars['String']>;
  imageLn?: Maybe<Scalars['String']>;
};

export type EventType = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  defaultLanguage?: Maybe<LanguageEnum>;
  status?: Maybe<EventStatusEnum>;
  begins?: Maybe<Scalars['DateTime']>;
  ends?: Maybe<Scalars['DateTime']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  days?: Maybe<Array<Maybe<DayType>>>;
  organizers?: Maybe<Array<Maybe<OrganizerType>>>;
  tickets?: Maybe<Array<Maybe<TicketType>>>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
};

export type ForgotPassword = {
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<ErrorEnum>;
};


export enum LanguageEnum {
  En = 'EN',
  Ln = 'LN'
}

export type Mutations = {
  createOrganizer?: Maybe<CreateOrganizer>;
  createDay?: Maybe<CreateDay>;
  createTicket?: Maybe<CreateTicket>;
  createEvent?: Maybe<CreateEvent>;
  updateEvent?: Maybe<UpdateEvent>;
  signUp?: Maybe<SignUp>;
  signIn?: Maybe<SignIn>;
  resetPassword?: Maybe<ResetPassword>;
  forgotPassword?: Maybe<ForgotPassword>;
  editUser?: Maybe<EditUser>;
  verifyToken?: Maybe<Verify>;
  refreshToken?: Maybe<Refresh>;
};


export type MutationsCreateOrganizerArgs = {
  organizerData: OrganizerInput;
};


export type MutationsCreateDayArgs = {
  dayData: DayInput;
};


export type MutationsCreateTicketArgs = {
  ticketData: TicketInput;
};


export type MutationsCreateEventArgs = {
  eventData: EventInput;
};


export type MutationsUpdateEventArgs = {
  eventData: EventInput;
};


export type MutationsSignUpArgs = {
  signup: SignUpInput;
};


export type MutationsSignInArgs = {
  signin: SignInInput;
};


export type MutationsResetPasswordArgs = {
  resetPassword: ResetPasswordInput;
};


export type MutationsForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationsEditUserArgs = {
  userData: UserInput;
};


export type MutationsVerifyTokenArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationsRefreshTokenArgs = {
  token?: Maybe<Scalars['String']>;
};

export type OrganizerContactInput = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type OrganizerContactType = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
};

export type OrganizerInput = {
  id?: Maybe<Scalars['UUID']>;
  eventId?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<OrganizerContactInput>>>;
};

export type OrganizerType = {
  id?: Maybe<Scalars['UUID']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  contacts?: Maybe<Array<Maybe<OrganizerContactType>>>;
};

export type PinInput = {
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type PinType = {
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
};

export type Query = {
  me?: Maybe<UserType>;
};

export type Refresh = {
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type ResetPassword = {
  ok?: Maybe<Scalars['Boolean']>;
  errors?: Maybe<ErrorEnum>;
};

export type ResetPasswordInput = {
  oldPass?: Maybe<Scalars['String']>;
  newPass?: Maybe<Scalars['String']>;
  rptPass?: Maybe<Scalars['String']>;
};

export type SignIn = {
  user?: Maybe<UserType>;
  token?: Maybe<Scalars['String']>;
  errors?: Maybe<ErrorEnum>;
};

export type SignInInput = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type SignUp = {
  user?: Maybe<UserType>;
  token?: Maybe<Scalars['String']>;
  errors?: Maybe<ErrorEnum>;
};

export type SignUpInput = {
  email?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type TicketInput = {
  id?: Maybe<Scalars['UUID']>;
  eventId?: Maybe<Scalars['UUID']>;
  withPlaces?: Maybe<Scalars['Boolean']>;
};

export type TicketType = {
  id?: Maybe<Scalars['UUID']>;
  created?: Maybe<Scalars['DateTime']>;
  modified?: Maybe<Scalars['DateTime']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  ticketType?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Decimal']>;
  currency?: Maybe<CurrencyEnum>;
  image?: Maybe<Scalars['String']>;
};


export type UpdateEvent = {
  event?: Maybe<EventType>;
  errors?: Maybe<ErrorEnum>;
};

/** An enumeration. */
export enum UserCurrency {
  /** EUR */
  Eur = 'EUR'
}

export type UserInput = {
  id: Scalars['UUID'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /** avatar must be base64 format */
  avatar?: Maybe<Scalars['String']>;
};

/** An enumeration. */
export enum UserLanguage {
  /** English */
  En = 'EN',
  /** Lithuanian */
  Ln = 'LN'
}

export type UserType = {
  password: Scalars['String'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  id: Scalars['UUID'];
  /** Required. 254 characters or fewer. Letters, digits and @/./+/-/_ only. */
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  isEmailVerified: Scalars['Boolean'];
  isPhoneVerified: Scalars['Boolean'];
  isStaff: Scalars['Boolean'];
  isSuperuser: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  language?: Maybe<UserLanguage>;
  currency?: Maybe<UserCurrency>;
  dateJoined: Scalars['Date'];
};

export type Verify = {
  payload: Scalars['GenericScalar'];
};

export type EditUserMutationVariables = Exact<{
  userData: UserInput;
}>;


export type EditUserMutation = { editUser?: Maybe<{ errors?: Maybe<ErrorEnum>, user?: Maybe<{ password: string, lastLogin?: Maybe<any>, id: any, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, phone?: Maybe<string>, isActive: boolean, isEmailVerified: boolean, isPhoneVerified: boolean, isStaff: boolean, isSuperuser: boolean, avatar?: Maybe<string>, language?: Maybe<UserLanguage>, currency?: Maybe<UserCurrency>, dateJoined: any }> }> };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { forgotPassword?: Maybe<{ ok?: Maybe<boolean>, errors?: Maybe<ErrorEnum> }> };

export type ResetPasswordMutationVariables = Exact<{
  resetPassword: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { resetPassword?: Maybe<{ ok?: Maybe<boolean>, errors?: Maybe<ErrorEnum> }> };

export type SignInMutationVariables = Exact<{
  signin: SignInInput;
}>;


export type SignInMutation = { signIn?: Maybe<{ token?: Maybe<string>, errors?: Maybe<ErrorEnum>, user?: Maybe<{ password: string, lastLogin?: Maybe<any>, id: any, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, phone?: Maybe<string>, isActive: boolean, isEmailVerified: boolean, isPhoneVerified: boolean, isStaff: boolean, isSuperuser: boolean, avatar?: Maybe<string>, language?: Maybe<UserLanguage>, currency?: Maybe<UserCurrency>, dateJoined: any }> }> };

export type SignUpMutationVariables = Exact<{
  signup: SignUpInput;
}>;


export type SignUpMutation = { signUp?: Maybe<{ token?: Maybe<string>, errors?: Maybe<ErrorEnum>, user?: Maybe<{ password: string, lastLogin?: Maybe<any>, id: any, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, phone?: Maybe<string>, isActive: boolean, isEmailVerified: boolean, isPhoneVerified: boolean, isStaff: boolean, isSuperuser: boolean, avatar?: Maybe<string>, language?: Maybe<UserLanguage>, currency?: Maybe<UserCurrency>, dateJoined: any }> }> };

export type UserTypeFragmentFragment = { password: string, lastLogin?: Maybe<any>, id: any, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, phone?: Maybe<string>, isActive: boolean, isEmailVerified: boolean, isPhoneVerified: boolean, isStaff: boolean, isSuperuser: boolean, avatar?: Maybe<string>, language?: Maybe<UserLanguage>, currency?: Maybe<UserCurrency>, dateJoined: any };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { me?: Maybe<{ password: string, lastLogin?: Maybe<any>, id: any, email?: Maybe<string>, firstName?: Maybe<string>, lastName?: Maybe<string>, phone?: Maybe<string>, isActive: boolean, isEmailVerified: boolean, isPhoneVerified: boolean, isStaff: boolean, isSuperuser: boolean, avatar?: Maybe<string>, language?: Maybe<UserLanguage>, currency?: Maybe<UserCurrency>, dateJoined: any }> };

export const UserTypeFragmentFragmentDoc = gql`
    fragment UserTypeFragment on UserType {
  password
  lastLogin
  id
  email
  firstName
  lastName
  phone
  isActive
  isEmailVerified
  isPhoneVerified
  isStaff
  isSuperuser
  avatar
  language
  currency
  dateJoined
}
    `;
export const EditUserDocument = gql`
    mutation EditUser($userData: UserInput!) {
  editUser(userData: $userData) {
    user {
      ...UserTypeFragment
    }
    errors
  }
}
    ${UserTypeFragmentFragmentDoc}`;
export type EditUserMutationFn = Apollo.MutationFunction<EditUserMutation, EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      userData: // value for 'userData'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: Apollo.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, options);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email) {
    ok
    errors
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($resetPassword: ResetPasswordInput!) {
  resetPassword(resetPassword: $resetPassword) {
    ok
    errors
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      resetPassword: // value for 'resetPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SignInDocument = gql`
    mutation SignIn($signin: SignInInput!) {
  signIn(signin: $signin) {
    user {
      ...UserTypeFragment
    }
    token
    errors
  }
}
    ${UserTypeFragmentFragmentDoc}`;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      signin: // value for 'signin'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($signup: SignUpInput!) {
  signUp(signup: $signup) {
    user {
      ...UserTypeFragment
    }
    token
    errors
  }
}
    ${UserTypeFragmentFragmentDoc}`;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      signup: // value for 'signup'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...UserTypeFragment
  }
}
    ${UserTypeFragmentFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    