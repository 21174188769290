import React from 'react'
import s from './index.module.scss'
import classNames from "classnames/bind";
import Skeleton from "react-loading-skeleton";

const cx = classNames.bind(s);

function Placeholder() {
  return <div className={cx('Card')}>
    <div className={cx('CardImg')}>
      <Skeleton className={cx('SkeletonImg')}/>
    </div>
    <div className={cx('CardInfoWrap')}>
      <div>
        <Skeleton  className={cx('SkeletonTitle')}/>
      </div>
      <div className={cx('CardInfo')}>
        <Skeleton className={cx('SkeletonInfo')}
                  count={2}/>
      </div>
      <div className={cx('CardBtn')}>
        <Skeleton className={cx('SkeletonBtn')}/>
      </div>
    </div>
  </div>
}

export const CardEventsPlaceholder = () => (
  <>
    <Placeholder/>
    <Placeholder/>
    <Placeholder/>
    <Placeholder/>
    <Placeholder/>
    <Placeholder/>
  </>
)
