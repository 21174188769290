import React, {Suspense} from 'react';
import App from "./App";
import {BrowserRouter as Router} from 'react-router-dom'
import {store} from "@store/store";
import {Provider} from 'react-redux'
import {client} from "../api";
import {ApolloProvider} from "@apollo/client";
import '../locales';
import Preloader from '@components/Preloader';
import 'react-quill/dist/quill.snow.css';
import '@components/UI/ReactForm/TextFieldEditor/restyle-quill.scss'
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";

export function AppProviderUtils() {
  return (

    <ApolloProvider client={client}>
      <Provider store={store}>
        <Router>
          <Suspense fallback={<Preloader/>}>
            <App/>
            <ToastContainer/>
          </Suspense>
        </Router>
      </Provider>
    </ApolloProvider>
  )
}
