import classNames from "classnames/bind";
import React from "react";
import styles from './index.module.scss'

const cx = classNames.bind(styles)

const Preloader = ({text = 'Loading'}: { text?: string }) => {
  return (

    <div className={cx('Preloader')}>
      <h1 className={cx('Text')}>{text}...</h1>
    </div>
  )
}

export default Preloader;
// export default ReactDelayRender({delay: 300})(Preloader);
