import React, {Dispatch, FC, forwardRef, memo, SetStateAction, useEffect, useState} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import {
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  format, isAfter,
  isBefore, isFirstDayOfMonth,
  isSameMonth,
  isToday,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths
} from 'date-fns';
import {chevron as ChevronSvg, ChevronOrientation} from "@icons/chevron";
import isSameDay from 'date-fns/fp/isSameDay';
import {Maybe} from '../../helpers/types';
import {PeriodProps} from './index.props';

const cx = classNames.bind(s);


const Period: FC<PeriodProps> = forwardRef(({
                                              onChange,
                                              classNameWrap,
                                            }, ref) => {


  const [currentDate, setCurrentDate] = useState(new Date())
  const [fromPeriod, setFromPeriod] = useState<Maybe<Date>>(null)
  const [toPeriod, setToPeriod] = useState<Maybe<Date>>(null)

  useEffect(() => {
    if (fromPeriod && toPeriod) onChange(fromPeriod, toPeriod)
  }, [fromPeriod, toPeriod])
  const handlePeriod = (date: Date) => {
    if (!fromPeriod) {
      setFromPeriod(date)
    } else if (fromPeriod && !toPeriod) {
      if (isBefore(date, fromPeriod)) {
        const from = fromPeriod;
        setFromPeriod(date)
        setToPeriod(from)
      } else {
        setToPeriod(date)
      }
    } else {
      setFromPeriod(null);
      setToPeriod(null)
    }
  }
  return (
    <div className={`${cx("Calendar")} ${classNameWrap}`} ref={ref as any}>
      <div>{<Header currentDate={currentDate} setCurrentDate={setCurrentDate}/>}</div>
      <div>{<DaysOfWeek currentDate={currentDate} setCurrentDate={setCurrentDate}/>}</div>
      <div>{<Cells currentDate={currentDate} fromPeriod={fromPeriod} toPeriod={toPeriod}
                   handlePeriod={handlePeriod}/>}</div>
    </div>
  );
});

/*render header */
const Header: FC<{
  currentDate: Date,
  setCurrentDate: Dispatch<SetStateAction<Date>>
}> = ({currentDate, setCurrentDate}) => {
  const monthFormat = 'MMMM';
  const yearFormat = 'yyyy';

  function prevMonth() {
    setCurrentDate(subMonths(currentDate, 1))
  }

  function nextMonth() {
    setCurrentDate(addMonths(currentDate, 1))
  }

  function nowMonth() {
    const now = new Date()
    setCurrentDate(now)
  }

  return (
    <div>
      <div className={cx('Header')}>
        <div className={cx('HeaderDate')}>
          <strong>{format(currentDate, monthFormat)}</strong>
          <span>{format(currentDate, yearFormat)}</span>
        </div>

        <div className={cx('HeaderControls')}>
          <div className={cx("HeaderArrow")} onClick={prevMonth}>
            <ChevronSvg orientation={ChevronOrientation.Left}/>
          </div>

          <div className={cx("HeaderToday")} onClick={nowMonth}>
            <span>today</span>
          </div>
          <div className={cx("HeaderArrow")} onClick={nextMonth}>
            <ChevronSvg orientation={ChevronOrientation.Right}/>
          </div>
        </div>
      </div>
    </div>
  )
}


/* render days of week */
const DaysOfWeek: FC<{
  currentDate: Date,
  setCurrentDate: Dispatch<SetStateAction<Date>>
}> = ({currentDate}) => {
  const dateFormat = "EEE";
  const days = [];
  let startDate = startOfWeek(currentDate)

  for (let i = 0; i < 7; i++) {
    const day = addDays(startDate, i)
    const today = isToday(day)
    days.push(
      <div
        className={cx('WeekItem', {
          Today: today
        })}
        key={i}>
        {format(day, dateFormat)}
      </div>
    )
  }
  return <div className={cx('Week')}>{days}</div>
}


const Cells: FC<{
  currentDate: Date,
  fromPeriod: Maybe<Date>,
  toPeriod: Maybe<Date>,
  handlePeriod: Function
}> =
  ({currentDate, handlePeriod, fromPeriod, toPeriod}) => {
    const monthStart = startOfMonth(currentDate);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const rows = [];
    let days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const cloneDay = day as Date;
        const now = subDays(Date.now(), 1)
        const isPrevMonth = !isSameMonth(day, monthStart)
        const isCurrentMonth = !isPrevMonth;
        days.push(
          <div
            className={cx('Cell', {
              CellDisabled: isBefore(cloneDay, now),
              CellNextMonth: !isCurrentMonth && !isBefore(cloneDay, now),
              CellSelected: (fromPeriod && (isSameDay(day, fromPeriod))) || (toPeriod && (isSameDay(day, toPeriod))),
              CellFrom: fromPeriod && (isSameDay(day, fromPeriod)),
              CellTo: toPeriod && (isSameDay(day, toPeriod)),
              CellToday: isToday(day),
              CellPeriod: fromPeriod && isAfter(day, fromPeriod) && toPeriod && isBefore(day, toPeriod)
            })}
            key={day.toString()}

            onClick={() => handlePeriod(cloneDay)}>
            <Day day={day} fromPeriod={fromPeriod} toPeriod={toPeriod}/>
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className={cx("Row")} key={day.toString()}> {days} </div>
      );
      days = [];
    }
    return <div className={cx('Body')}>{rows}</div>;
  }
const Day: FC<{
  day: Date, fromPeriod: Maybe<Date>, toPeriod: Maybe<Date>
}> = ({day, fromPeriod, toPeriod}) => {
  const dayWithMonth = <><span>{format(day, "d")}</span> <small>{format(day, "MMM")}</small></>
  return <span>{
    isFirstDayOfMonth(day) &&
    ((fromPeriod ? !isSameDay(day, fromPeriod) : true) && (toPeriod ? !isSameDay(day, toPeriod) : true))
      ? dayWithMonth : format(day, "d")}</span>
}
export default memo(Period);

