import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {ModalType} from "@ts_types/modal-types";
import {EventsQueryVariables, Sort} from "@api/generated";

type LandingInitialState = {
  isOpenFilter: boolean
  modalType: ModalType,
  filter: EventsQueryVariables
}


const initialState: LandingInitialState = {
  isOpenFilter: false,
  modalType: ModalType.Login,
  filter: {
    q: null,
    dateFrom: null,
    dateTill: null,
    priceFrom: null,
    isFeatured: null,
    limit: null,
    skip: null,
    sort: Sort.Asc,
    priceTill: null
  }
}

export const landingSlice = createSlice({
  name: 'landing',
  initialState,
  reducers: {
    setOpenFilter: (state, action: PayloadAction<boolean>) => {
      state.isOpenFilter = action.payload
    },
    setModalType: (state, action: PayloadAction<ModalType>) => {
      state.modalType = action.payload
    },
    setFilterField: (state,
                     action: PayloadAction<{
                       field: keyof EventsQueryVariables,
                       value: EventsQueryVariables["q"] | EventsQueryVariables["sort"]
                         | EventsQueryVariables["skip"] | EventsQueryVariables["dateFrom"]
                         | EventsQueryVariables["dateTill"] | EventsQueryVariables["isFeatured"] | EventsQueryVariables["limit"]
                     }>) => {
      state.filter[action.payload.field] = action.payload.value
    }
  },
})

// Action creators are generated for each case reducer function
export const {setOpenFilter, setModalType, setFilterField} = landingSlice.actions

export default landingSlice.reducer
