export const WaitSvg = () => (
  <svg width="74" height="80" viewBox="0 0 74 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M58.2254 7.63595L66.7107 14.712C67.2949 15.1994 68.0046 15.4372 68.7104 15.4372C69.6055 15.4372 70.494 15.0547 71.1121 14.3136C72.2174 12.9881 72.039 11.0175 70.7133 9.9122L62.228 2.83595C60.9029 1.73064 58.9321 1.90892 57.8266 3.23454C56.7213 4.56001 56.8997 6.53048 58.2254 7.63595Z"
      fill="var(--accent)"/>
    <path
      d="M5.29044 15.4369C5.99607 15.4369 6.70591 15.1991 7.29013 14.7118L15.7753 7.63585C17.1008 6.53054 17.2793 4.55991 16.174 3.23444C15.0689 1.90913 13.0981 1.73054 11.7726 2.83585L3.28732 9.91194C1.96185 11.0173 1.78326 12.9879 2.88857 14.3133C3.50685 15.0544 4.39529 15.4369 5.29044 15.4369Z"
      fill="var(--accent)"/>
    <path
      d="M63.0745 17.0505C56.8517 10.8277 48.7959 7.10531 40.125 6.38234V3.125C40.125 1.39906 38.7259 0 37 0C35.2741 0 33.875 1.39906 33.875 3.125V6.38234C25.2041 7.10531 17.1483 10.8275 10.9255 17.0503C3.96063 24.0152 0.125 33.2753 0.125 43.125C0.125 52.9747 3.96063 62.2348 10.9255 69.1995C17.8902 76.1644 27.1503 80 37 80C44.9303 80 52.4872 77.5205 58.8538 72.8291C60.2433 71.8053 60.5395 69.8491 59.5158 68.4595C58.4919 67.0698 56.5355 66.7738 55.1461 67.7975C49.8614 71.6917 43.5866 73.75 37 73.75C20.1133 73.75 6.375 60.0117 6.375 43.125C6.375 26.2383 20.1133 12.5 37 12.5C53.8867 12.5 67.625 26.2383 67.625 43.125C67.625 48.2828 66.3197 53.3809 63.8498 57.8681C63.0177 59.3802 63.5688 61.2805 65.0808 62.1127C66.5923 62.9445 68.493 62.3939 69.3253 60.8817C72.3017 55.4738 73.875 49.3336 73.875 43.125C73.875 33.2753 70.0394 24.0152 63.0745 17.0505Z"
      fill="#979797"/>
    <path
      d="M52.224 24.3126C50.8828 23.2271 48.915 23.4341 47.8291 24.7758L37.9758 36.9516C37.6577 36.9015 37.3319 36.8749 37 36.8749C33.5537 36.8749 30.75 39.6787 30.75 43.1249C30.75 46.5712 33.5537 49.3749 37 49.3749C40.4462 49.3749 43.25 46.5712 43.25 43.1249C43.25 42.3355 43.1014 41.5805 42.8331 40.8846L52.6873 28.7076C53.7731 27.3658 53.5658 25.398 52.224 24.3126Z"
      fill="var(--accent)"/>
  </svg>

)
