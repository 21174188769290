import {ColorEnum, DefaultIconPropsType} from "../../helpers/types"
import {FC} from "react"

export const CheckIcon: FC<Omit<DefaultIconPropsType, 'orientation'>>
  = ({
       className,
       height = 9,
       width = 13,
       fill = ColorEnum.black
     }) => {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 14 10" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path d="M2 4.54414L5.45593 8L12.2994 2" stroke={`var(${fill})`} strokeWidth="2.5" strokeLinecap="round"/>
    </svg>
  )
}
export const CheckIconRectangle: FC<Omit<DefaultIconPropsType, 'orientation'>>
  = ({
       height = 41,
       width = 41,
       fill = ColorEnum.accent
     }
) => {
  return (
    <svg width={width} height={height} viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.5 7.71094C0.5 3.84494 3.63401 0.710938 7.5 0.710938H33.5C37.366 0.710938 40.5 3.84494 40.5 7.71094V33.7109C40.5 37.5769 37.366 40.7109 33.5 40.7109H7.5C3.63401 40.7109 0.5 37.5769 0.5 33.7109V7.71094Z"
        fill={`var(${fill})`} style={{ opacity: '0.5'}}/>
      <path
        d="M30.0419 14.4303C29.9096 14.308 29.7522 14.211 29.5788 14.1448C29.4053 14.0785 29.2193 14.0444 29.0315 14.0444C28.8436 14.0444 28.6576 14.0785 28.4842 14.1448C28.3108 14.211 28.1534 14.308 28.0211 14.4303L17.419 24.1613L12.9647 20.0654C12.8273 19.9438 12.6652 19.8482 12.4875 19.784C12.3098 19.7198 12.1201 19.6883 11.9291 19.6913C11.7382 19.6944 11.5498 19.7318 11.3746 19.8016C11.1994 19.8714 11.041 19.9721 10.9083 20.098C10.7756 20.2239 10.6713 20.3726 10.6013 20.5354C10.5313 20.6983 10.4969 20.8722 10.5002 21.0472C10.5035 21.2223 10.5444 21.395 10.6205 21.5555C10.6967 21.7161 10.8065 21.8613 10.9439 21.9829L16.4086 26.9919C16.5409 27.1142 16.6983 27.2112 16.8717 27.2774C17.0451 27.3437 17.2311 27.3778 17.419 27.3778C17.6069 27.3778 17.7929 27.3437 17.9663 27.2774C18.1397 27.2112 18.2971 27.1142 18.4294 26.9919L30.0419 16.3478C30.1863 16.2256 30.3016 16.0774 30.3804 15.9124C30.4593 15.7474 30.5 15.5692 30.5 15.389C30.5 15.2089 30.4593 15.0307 30.3804 14.8657C30.3016 14.7007 30.1863 14.5524 30.0419 14.4303Z"
        fill={`var(${ColorEnum.black})`}/>
    </svg>
  )
}