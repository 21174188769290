export const dollarSign = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M56.6668 80C43.8002 80 33.3335 69.53 33.3335 56.6667C33.3335 43.8034 43.8002 33.3334 56.6668 33.3334C69.5335 33.3334 80.0002 43.8034 80.0002 56.6667C80.0002 69.53 69.5335 80 56.6668 80ZM56.6668 38.3334C46.5568 38.3334 38.3335 46.56 38.3335 56.6667C38.3335 66.7734 46.5568 75 56.6668 75C66.7768 75 75.0002 66.7734 75.0002 56.6667C75.0002 46.56 66.7768 38.3334 56.6668 38.3334Z" fill="white"/>
    <path d="M57.0833 66.6667H52.5C51.12 66.6667 50 65.5467 50 64.1667C50 62.7867 51.12 61.6667 52.5 61.6667H57.0833C57.7733 61.6667 58.3333 61.1067 58.3333 60.4167C58.3333 59.7267 57.7733 59.1667 57.0833 59.1667H56.25C52.8033 59.1667 50 56.3634 50 52.9167C50 49.47 52.8033 46.6667 56.25 46.6667H60.8333C62.2133 46.6667 63.3333 47.7867 63.3333 49.1667C63.3333 50.5467 62.2133 51.6667 60.8333 51.6667H56.25C55.56 51.6667 55 52.2267 55 52.9167C55 53.6067 55.56 54.1667 56.25 54.1667H57.0833C60.53 54.1667 63.3333 56.97 63.3333 60.4167C63.3333 63.8634 60.53 66.6667 57.0833 66.6667Z" fill="var(--accent)"/>
    <path d="M56.6665 71.6667C55.2865 71.6667 54.1665 70.5467 54.1665 69.1667V64.1667C54.1665 62.7867 55.2865 61.6667 56.6665 61.6667C58.0465 61.6667 59.1665 62.7867 59.1665 64.1667V69.1667C59.1665 70.5467 58.0465 71.6667 56.6665 71.6667Z" fill="var(--accent)"/>
    <path d="M56.6665 50.8334C55.2865 50.8334 54.1665 49.7134 54.1665 48.3334V44.1667C54.1665 42.7867 55.2865 41.6667 56.6665 41.6667C58.0465 41.6667 59.1665 42.7867 59.1665 44.1667V48.3334C59.1665 49.7134 58.0465 50.8334 56.6665 50.8334Z" fill="var(--accent)"/>
    <path d="M24.1667 63.3333H9.16667C4.11 63.3333 0 59.2233 0 54.1667V9.16667C0 4.11 4.11 0 9.16667 0H60.8333C65.89 0 70 4.11 70 9.16667V24.1667C70 25.5467 68.88 26.6667 67.5 26.6667C66.12 26.6667 65 25.5467 65 24.1667V9.16667C65 6.87 63.13 5 60.8333 5H9.16667C6.87 5 5 6.87 5 9.16667V54.1667C5 56.4633 6.87 58.3333 9.16667 58.3333H24.1667C25.5467 58.3333 26.6667 59.4533 26.6667 60.8333C26.6667 62.2133 25.5467 63.3333 24.1667 63.3333Z" fill="white"/>
    <path d="M67.5 16.6666H2.5C1.12 16.6666 0 15.5466 0 14.1666C0 12.7866 1.12 11.6666 2.5 11.6666H67.5C68.88 11.6666 70 12.7866 70 14.1666C70 15.5466 68.88 16.6666 67.5 16.6666Z" fill="var(--accent)"/>
  </svg>

)
