export enum StepEnum {
  General = 'general',
  Dates = 'dates',
  Speakers = 'speakers',
  // Schedule = 'schedule',
  Tickets = 'tickets',
  Contacts = 'contacts',
  Finance = 'finance'
}

export const stepsList = [
  StepEnum.General,
  StepEnum.Dates,
  StepEnum.Speakers,
  // STEPS_ENUM.SCHEDULE,
  StepEnum.Contacts,
  StepEnum.Tickets,
  StepEnum.Finance
]

