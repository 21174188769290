import React, {FC, memo} from "react";
import Modal, {ModalProps} from "@components/UI/Modal";
import s from './index.module.scss'
import classNames from "classnames/bind";
import {
  ForgotPassword,
  HeaderModal,
  LocationOffer,
  Login,
  Registration,
  SelectEnvironment
} from "./modules";
import {ModalType} from "@ts_types/modal-types";

const cx = classNames.bind(s)

type ModalsPropsType = { modalType: ModalType, changePayload: (modalType: ModalType) => void, close: ()=> void } & ModalProps

const Modals: FC<ModalsPropsType> = ({modalType, className, changePayload, ...modalProps}) => {
  return <Modal
    {...modalProps}
    className={cx('Modal', [`Modal${modalType}`], className || '')}>
    <HeaderModal modalType={modalType}/>
    {modalType === ModalType.LocationOffer && <LocationOffer/>}
    {modalType === ModalType.Login && <Login closeModal={modalProps.close}  changePayload={changePayload}/>}
    {modalType === ModalType.Registration && <Registration closeModal={modalProps.close} changePayload={changePayload}/>}
    {modalType === ModalType.ForgotPassword && <ForgotPassword />}
    {modalType === ModalType.SelectEnvironment && <SelectEnvironment/>}
  </Modal>
}


export default memo(Modals)
