import React, {FC} from 'react';
import s from './index.module.scss';
import classNames from 'classnames/bind'
import {Typography} from "@components/modules";
import {parseChildren} from "../../../../helpers/parseChildren";

const cx = classNames.bind(s)

type CardTicketButtonPropsType = {
  children: any
}

export const CardTicketButton: FC<CardTicketButtonPropsType> = (props) => {
  return (
    <div className={cx('Component')}>
      <Typography className={cx('Description')}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sdvsdgskdjgkdjgkdjlgjlkdjgljklked do eiusmod tempor
        incididuntg elit, sed do eius vsfwo sk ut....
      </Typography>
      <div className={cx('Btns')}>
        {parseChildren(props.children)}
      </div>

    </div>
  );
};

