import {gql} from '@apollo/client';
import * as Apollo from '@apollo/client';

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  /** The `Date` scalar type represents a date object */
  Date: any;
  DateTime: any;
  Duration: any;
  EmailAddress: any;
  GUID: any;
  GeoJSON: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  JSON: any;
  JSONObject: any;
  Long: any;
  MAC: any;
  NaiveDateTime: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  /** The `Time` scalar type represents a time object */
  Time: any;
  URL: any;
  USCurrency: any;
  UUID: any;
  UnsignedInt: any;
};


export enum Currency {
  Usd = 'USD',
  Eur = 'EUR',
  Uah = 'UAH',
  Rub = 'RUB',
  Pln = 'PLN',
  Gbp = 'GBP'
}


export type DayRange = {
  firstDay?: Maybe<Scalars['DateTime']>;
  lastDay?: Maybe<Scalars['DateTime']>;
};


export enum Error {
  NotFound = 'NOT_FOUND',
  Internal = 'INTERNAL',
  Forbidden = 'FORBIDDEN'
}

export type Event = {
  name: Scalars['String'];
  slug: Scalars['String'];
  prices?: Maybe<PriceRange>;
  days?: Maybe<DayRange>;
  status?: Maybe<Status>;
  coverImage: Scalars['String'];
  locations?: Maybe<Array<Maybe<Location>>>;
  description?: Maybe<Scalars['String']>;
  ticketTypes?: Maybe<Array<Maybe<TicketType>>>;
};

export type EventResponse = {
  event?: Maybe<Event>;
  error?: Maybe<Error>;
};

export type EventsResponse = {
  events?: Maybe<Array<Maybe<Event>>>;
  error?: Maybe<Error>;
};


export type Info = {
  ticketsSold?: Maybe<Scalars['Int']>;
  currency?: Maybe<Currency>;
  language?: Maybe<Language>;
  contact?: Maybe<PlatformContact>;
};


export enum Language {
  En = 'en',
  Uk = 'uk',
  Ru = 'ru',
  Lv = 'lv',
  Lt = 'lt'
}

export type Location = {
  name?: Maybe<Scalars['String']>;
};


export type Mutation = {
  prelaunchLocationSubscribe?: Maybe<Response>;
};


export type MutationPrelaunchLocationSubscribeArgs = {
  language: Language;
  locationName: Scalars['String'];
  email: Scalars['EmailAddress'];
  phone: Scalars['PhoneNumber'];
  comments?: Maybe<Scalars['String']>;
};


export type PlatformContact = {
  email?: Maybe<Scalars['EmailAddress']>;
  phone?: Maybe<Scalars['PhoneNumber']>;
  telegram?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  actualAddress?: Maybe<Scalars['String']>;
};


export type Price = {
  beginsAt?: Maybe<Scalars['DateTime']>;
  endsAt?: Maybe<Scalars['DateTime']>;
  amount: Scalars['String'];
  currency: Currency;
};

export type PriceRange = {
  priceMin?: Maybe<Scalars['String']>;
  priceMax?: Maybe<Scalars['String']>;
};

export type Query = {
  info?: Maybe<Info>;
  events?: Maybe<EventsResponse>;
  event?: Maybe<EventResponse>;
};


export type QueryEventsArgs = {
  q?: Maybe<Scalars['String']>;
  sort?: Maybe<Sort>;
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTill?: Maybe<Scalars['DateTime']>;
  priceFrom?: Maybe<Scalars['String']>;
  priceTill?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
};


export type QueryEventArgs = {
  slug: Scalars['String'];
};


export type Response = {
  error?: Maybe<Error>;
};

export enum Sort {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum Status {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
  Ended = 'ENDED'
}

export type Subscription = {
  ticketsSold?: Maybe<TicketsSoldResponse>;
};

export type TicketType = {
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  prices?: Maybe<Array<Maybe<Price>>>;
};

export type TicketsSoldResponse = {
  total?: Maybe<Scalars['Int']>;
};


export type EventFragmentFragment = { name: string, slug: string, description?: Maybe<string>, coverImage: string, prices?: Maybe<{ priceMin?: Maybe<string>, priceMax?: Maybe<string> }>, days?: Maybe<{ firstDay?: Maybe<any>, lastDay?: Maybe<any> }>, locations?: Maybe<Array<Maybe<{ name?: Maybe<string> }>>>, ticketTypes?: Maybe<Array<Maybe<{ id: number, name: string, description?: Maybe<string>, prices?: Maybe<Array<Maybe<{ beginsAt?: Maybe<any>, endsAt?: Maybe<any>, amount: string, currency: Currency }>>> }>>> };

export type PriceFragmentFragment = { beginsAt?: Maybe<any>, endsAt?: Maybe<any>, amount: string, currency: Currency };

export type TicketTypeFragmentFragment = { id: number, name: string, description?: Maybe<string>, prices?: Maybe<Array<Maybe<{ beginsAt?: Maybe<any>, endsAt?: Maybe<any>, amount: string, currency: Currency }>>> };

export type PrelaunchLocationSubscribeMutationVariables = Exact<{
  language: Language;
  locationName: Scalars['String'];
  email: Scalars['EmailAddress'];
  phone: Scalars['PhoneNumber'];
  comments?: Maybe<Scalars['String']>;
}>;


export type PrelaunchLocationSubscribeMutation = { prelaunchLocationSubscribe?: Maybe<{ error?: Maybe<Error> }> };

export type EventQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type EventQuery = { event?: Maybe<{ error?: Maybe<Error>, event?: Maybe<{ name: string, slug: string, description?: Maybe<string>, coverImage: string, prices?: Maybe<{ priceMin?: Maybe<string>, priceMax?: Maybe<string> }>, days?: Maybe<{ firstDay?: Maybe<any>, lastDay?: Maybe<any> }>, locations?: Maybe<Array<Maybe<{ name?: Maybe<string> }>>>, ticketTypes?: Maybe<Array<Maybe<{ id: number,
        name: string,
        description?: Maybe<string>,
        prices?: Maybe<Array<Maybe<{ beginsAt?: Maybe<any>, endsAt?: Maybe<any>, amount: string, currency: Currency }>>> }>>> }> }> };

export type EventsQueryVariables = Exact<{
  q?: Maybe<Scalars['String']>;
  sort?: Maybe<Sort>;
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTill?: Maybe<Scalars['DateTime']>;
  priceFrom?: Maybe<Scalars['String']>;
  priceTill?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
}>;


export type EventsQuery = { events?: Maybe<{ error?: Maybe<Error>, events?: Maybe<Array<Maybe<{ name: string, slug: string, description?: Maybe<string>, coverImage: string, prices?: Maybe<{ priceMin?: Maybe<string>, priceMax?: Maybe<string> }>, days?: Maybe<{ firstDay?: Maybe<any>, lastDay?: Maybe<any> }>, locations?: Maybe<Array<Maybe<{ name?: Maybe<string> }>>>, ticketTypes?: Maybe<Array<Maybe<{ id: number, name: string, description?: Maybe<string>, prices?: Maybe<Array<Maybe<{ beginsAt?: Maybe<any>, endsAt?: Maybe<any>, amount: string, currency: Currency }>>> }>>> }>>> }> };

export type InfoQueryVariables = Exact<{ [key: string]: never; }>;


export type InfoQuery = { info?: Maybe<{ ticketsSold?: Maybe<number>, currency?: Maybe<Currency>, language?: Maybe<Language>, contact?: Maybe<{ email?: Maybe<any>, phone?: Maybe<any>, telegram?: Maybe<string>, legalAddress?: Maybe<string>, actualAddress?: Maybe<string> }> }> };

export type TicketsSoldSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type TicketsSoldSubscription = { ticketsSold?: Maybe<{ total?: Maybe<number> }> };

export const PriceFragmentFragmentDoc = gql`
    fragment PriceFragment on Price {
        beginsAt
        endsAt
        amount
        currency
    }
`;
export const TicketTypeFragmentFragmentDoc = gql`
    fragment TicketTypeFragment on TicketType {
        id
        name
        description
        prices {
            ...PriceFragment
        }
    }
${PriceFragmentFragmentDoc}`;
export const EventFragmentFragmentDoc = gql`
    fragment EventFragment on Event {
        name
        slug
        description
        prices {
            priceMin
            priceMax
        }
        days {
            firstDay
            lastDay
        }
        coverImage
        locations {
            name
        }
        ticketTypes {
            ...TicketTypeFragment
        }
    }
${TicketTypeFragmentFragmentDoc}`;
export const PrelaunchLocationSubscribeDocument = gql`
    mutation PrelaunchLocationSubscribe($language: Language!, $locationName: String!, $email: EmailAddress!, $phone: PhoneNumber!, $comments: String) {
        prelaunchLocationSubscribe(
            language: $language
            locationName: $locationName
            email: $email
            phone: $phone
            comments: $comments
        ) {
            error
        }
    }
`;
export type PrelaunchLocationSubscribeMutationFn = Apollo.MutationFunction<PrelaunchLocationSubscribeMutation, PrelaunchLocationSubscribeMutationVariables>;

/**
 * __usePrelaunchLocationSubscribeMutation__
 *
 * To run a mutation, you first call `usePrelaunchLocationSubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrelaunchLocationSubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [prelaunchLocationSubscribeMutation, { data, loading, error }] = usePrelaunchLocationSubscribeMutation({
 *   variables: {
 *      language: // value for 'language'
 *      locationName: // value for 'locationName'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      comments: // value for 'comments'
 *   },
 * });
 */
export function usePrelaunchLocationSubscribeMutation(baseOptions?: Apollo.MutationHookOptions<PrelaunchLocationSubscribeMutation, PrelaunchLocationSubscribeMutationVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useMutation<PrelaunchLocationSubscribeMutation, PrelaunchLocationSubscribeMutationVariables>(PrelaunchLocationSubscribeDocument, options);
}

export type PrelaunchLocationSubscribeMutationHookResult = ReturnType<typeof usePrelaunchLocationSubscribeMutation>;
export type PrelaunchLocationSubscribeMutationResult = Apollo.MutationResult<PrelaunchLocationSubscribeMutation>;
export type PrelaunchLocationSubscribeMutationOptions = Apollo.BaseMutationOptions<PrelaunchLocationSubscribeMutation, PrelaunchLocationSubscribeMutationVariables>;
export const EventDocument = gql`
    query Event($slug: String!) {
        event(slug: $slug) {
            event {
                ...EventFragment
            }
            error
        }
    }
${EventFragmentFragmentDoc}`;

/**
 * __useEventQuery__
 *
 * To run a query within a React component, call `useEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useEventQuery(baseOptions: Apollo.QueryHookOptions<EventQuery, EventQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<EventQuery, EventQueryVariables>(EventDocument, options);
}

export function useEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventQuery, EventQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<EventQuery, EventQueryVariables>(EventDocument, options);
}

export type EventQueryHookResult = ReturnType<typeof useEventQuery>;
export type EventLazyQueryHookResult = ReturnType<typeof useEventLazyQuery>;
export type EventQueryResult = Apollo.QueryResult<EventQuery, EventQueryVariables>;
export const EventsDocument = gql`
    query Events($q: String, $sort: Sort, $dateFrom: DateTime, $dateTill: DateTime, $priceFrom: String, $priceTill: String, $skip: Int, $limit: Int, $isFeatured: Boolean) {
        events(
            q: $q
            sort: $sort
            dateFrom: $dateFrom
            dateTill: $dateTill
            priceFrom: $priceFrom
            priceTill: $priceTill
            skip: $skip
            limit: $limit
            isFeatured: $isFeatured
        ) {
            events {
                ...EventFragment
            }
            error
        }
    }
${EventFragmentFragmentDoc}`;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      q: // value for 'q'
 *      sort: // value for 'sort'
 *      dateFrom: // value for 'dateFrom'
 *      dateTill: // value for 'dateTill'
 *      priceFrom: // value for 'priceFrom'
 *      priceTill: // value for 'priceTill'
 *      skip: // value for 'skip'
 *      limit: // value for 'limit'
 *      isFeatured: // value for 'isFeatured'
 *   },
 * });
 */
export function useEventsQuery(baseOptions?: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
}

export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
}

export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export const InfoDocument = gql`
    query Info {
        info {
            ticketsSold
            currency
            language
            contact {
                email
                phone
                telegram
                legalAddress
                actualAddress
            }
        }
    }
`;

/**
 * __useInfoQuery__
 *
 * To run a query within a React component, call `useInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useInfoQuery(baseOptions?: Apollo.QueryHookOptions<InfoQuery, InfoQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useQuery<InfoQuery, InfoQueryVariables>(InfoDocument, options);
}

export function useInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InfoQuery, InfoQueryVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useLazyQuery<InfoQuery, InfoQueryVariables>(InfoDocument, options);
}

export type InfoQueryHookResult = ReturnType<typeof useInfoQuery>;
export type InfoLazyQueryHookResult = ReturnType<typeof useInfoLazyQuery>;
export type InfoQueryResult = Apollo.QueryResult<InfoQuery, InfoQueryVariables>;
export const TicketsSoldDocument = gql`
    subscription TicketsSold {
        ticketsSold {
            total
        }
    }
`;

/**
 * __useTicketsSoldSubscription__
 *
 * To run a query within a React component, call `useTicketsSoldSubscription` and pass it any options that fit your needs.
 * When your component renders, `useTicketsSoldSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTicketsSoldSubscription({
 *   variables: {
 *   },
 * });
 */
export function useTicketsSoldSubscription(baseOptions?: Apollo.SubscriptionHookOptions<TicketsSoldSubscription, TicketsSoldSubscriptionVariables>) {
  const options = {...defaultOptions, ...baseOptions}
  return Apollo.useSubscription<TicketsSoldSubscription, TicketsSoldSubscriptionVariables>(TicketsSoldDocument, options);
}

export type TicketsSoldSubscriptionHookResult = ReturnType<typeof useTicketsSoldSubscription>;
export type TicketsSoldSubscriptionResult = Apollo.SubscriptionResult<TicketsSoldSubscription>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}

const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
export default result;
