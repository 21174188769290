import {FC} from "react";
import {ModalInfoPropsType} from "./index.props";
import s from './index.module.scss'
import classNames from 'classnames/bind';
import Modal from "@components/UI/Modal";
import {Button, Typography} from "@components/modules";
import {FontWeight, TextColor, TypographyVariant} from "@components/UI/Typography/index.props";
import {CheckIconRectangle} from "@icons/checkIcon";
import {ColorEnum} from "../../helpers/types";
import {CloseSvg} from "@icons/close";

const cx = classNames.bind(s)


export const ModalInfo: FC<ModalInfoPropsType> =
  ({
     isOpen, close, onConfirm, title, btnText, description, payload,
     icon = <CheckIconRectangle/>, bgColorIcon = ColorEnum.accent, btnTag = 'button', btnTo
   }) => {
    const onClick = () => {
      onConfirm(payload);
      close();
    }
    return (
      <Modal
        className={s.Modal}
        isOpen={isOpen}
        close={close}
        closable
      >
        <div>
          <span className={s.IconWrap} style={{backgroundColor: bgColorIcon}}>
            {icon}
          </span>
        </div>
        <Typography
          className={'mt-24px'}
          color={TextColor.Dark}
          tag={TypographyVariant.h26}
          weight={FontWeight.Bold}>{title}</Typography>

        {description &&
            <Typography className={cx('mt-24px')} color={TextColor.Grey}>{description}</Typography>}
        <Button
          widthMax
          classNames={cx('mt-24px', 'Btn')}
          onClick={onClick}
        >{btnText}
        </Button>
      </Modal>
    )
  }