import React, {Dispatch, FC, SetStateAction} from 'react';
import classNames from "classnames/bind";
import s from './index.module.scss';
import {Customers, Events, Offers, Poster} from "@pages/Dashboard/modules";
import {Header} from "@components/modules";

const cx = classNames.bind(s)



const Dashboard: FC = (props) => {
  return <>
    <div>
      <Poster/>
      <Events/>
      <Customers/>
      <Offers />
    </div>
  </>
}


export default Dashboard;

