import React, {FC, forwardRef, InputHTMLAttributes, Ref} from 'react';
import s from './index.module.scss';
import classNames from 'classnames/bind'
import {Typography} from "@components/modules";
import {FontWeight, TypographyVariant} from "@components/UI/Typography/index.props";

const cx = classNames.bind(s)

type RadioPropsType = {
  name: string,
  classNameComponent?: string
  id: string,
  label?: any,
}

export const Radio: FC<RadioPropsType & InputHTMLAttributes<HTMLInputElement>> =
  forwardRef(({
                classNameComponent,
                label,
                ...inputProps
              }, ref: Ref<HTMLInputElement>) => {
    return (
      <div className={cx('Component', classNameComponent ?? '')}>
        <input
          ref={ref}
          tabIndex={1}
          type={'radio'}
          {...inputProps}
        />
        <label htmlFor={inputProps?.id}>
          <span/>
            {label || inputProps.value}
        </label>
      </div>
    )
  })


