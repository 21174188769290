import React, {FC} from 'react';
import s from './index.module.scss';
import classNames from 'classnames/bind'
import {Typography} from "@components/modules";
import {FontWeight, TypographyVariant} from "@components/UI/Typography/index.props";

const cx = classNames.bind(s)

type ListAvatarsPropsType = {
  avatars: Array<string>,
  showBy?: number,
  text?: string,
  className?: string
}

export const ListAvatars: FC<ListAvatarsPropsType> =
  ({
     avatars, showBy = 2,
     text, className
   }) => {
    // const rest = avatars.length - showBy <= 0 ? null : avatars.length - showBy
    return (
      <div className={cx('Component', className || '')}>
        {text && (
          <Typography
            className={cx('Text')}
            tag={TypographyVariant.p14}
            weight={FontWeight.Medium}
          >
            {text}
          </Typography>
        )}
        <ul className={cx('Members')}>
          {avatars.slice(0, showBy).map((ava, i) => (
            <div key={i} className={cx('MemberImg')}>
              <img src={ava ?? ''}/>
            </div>
          ))}
        </ul>
      </div>
    );
  };

