import {ApplicationState} from "@store/store";
import {useSelector} from 'react-redux'

export function useStore<TSelected = unknown>(
  selector: (state: ApplicationState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
) {
  return useSelector(selector, equalityFn)
}

