import {Redirect, Route, Switch} from "react-router-dom";
import Dashboard from "@pages/Dashboard";
import React from "react";
import {StepEnum} from "@pages/ManageEvent/StepEnum";
import {
  PageError,
  PageEvent,
  PageLandingOrganizer,
  PageManageEvent, PageManageOrganizers, PageOrganizers,
  PageReservations,
  PageSettings
} from "@pages/modules";
import {pages} from "./pagesMap";


const Routes = () => (
  <div className={'Routes'}>
    <Switch>
      <Route exact path={'/manage-event'}
             render={() => <Redirect to={pages.ManageEventPage.push({step: StepEnum.General})}/>}
      ></Route>
      <Route exact path={pages.DashboardPage.path} component={Dashboard}/>
      <Route path={pages.EventPage.path}
             component={PageEvent}/>
      <Route path={pages.ManageEventPage.path}
             exact component={PageManageEvent}/>
      <Route path={pages.LandingPages.becomeOrganizer.path} exact component={PageLandingOrganizer}/>
      <Route path={pages.SettingsPage.path} exact component={PageSettings}/>
      <Route path={pages.ReservationPage.path} exact component={PageReservations}/>
      <Route path={pages.OrganizersPage.path} exact component={PageOrganizers}/>
      <Route path={pages.ManageOrganizersPage.path} exact component={PageManageOrganizers}/>
      <Route component={PageError}/>
    </Switch>
  </div>
)

export default Routes;
