import {FC, forwardRef, InputHTMLAttributes, Ref} from "react";
import {TextFieldPropsType} from "./index.types";
import s from './index.module.scss';
import classNames from "classnames/bind";
import {TextError} from "../TextError";

const cx = classNames.bind(s);

export const TextArea: FC<TextFieldPropsType & InputHTMLAttributes<HTMLTextAreaElement>> =
  forwardRef(({
                label,
                error,
                classNameTextareaWrap,
                view = 'border-gray',
                classNameComponent,
                ...inputProps
              }, ref: Ref<HTMLTextAreaElement>) => {
    return (
      <div
        className={cx('Component', {ComponentError: !!error}, view && 'Component_' + view, classNameComponent ?? '')}>
        {label && <label htmlFor={inputProps?.id}>{label}</label>}
        <div className={cx(classNameTextareaWrap || '')}>
          <textarea ref={ref} {...inputProps} />
        </div>
        <TextError error={error}/>
      </div>
    )
  })
