import {useMeQuery, UserType} from "@api/types_schema"
import {ApolloClient, useApolloClient } from "@apollo/client"
import {editBase} from "@store/reducers/base"
import {useDispatch} from "react-redux"
import {Dispatch} from "redux"
import {JWT_TOKEN_KEY, LOCAL_STORAGE_LNG_KEY} from "./constants/"
import { useStore } from "./hooks/useStore"


export const useSignIn = () => {
  const dispatch = useDispatch()
  return (token: string, user: UserType) => signIn(token, user, dispatch)
}
export const signIn = (token: string, user: UserType, dispatch: Dispatch) => {
  window.localStorage.setItem(JWT_TOKEN_KEY, token)
  dispatch(editBase([{field: 'isAuth', value: true}, {field: 'user', value: user}]))
}

export const useSignOut = () => {
  const apollo = useApolloClient()
  const dispatch = useDispatch()
  return () => signOut(dispatch, apollo)
}

export const signOut = (dispatch: Dispatch, apollo: ApolloClient<any>) => {
  window.localStorage.clear()
  apollo.cache.reset()
  dispatch(editBase([{field: 'isAuth', value: false}, {field: 'user', value: null}]))
}
export const useMe = () => {
  const dispatch = useDispatch();
  const isAuth = useStore(state => state.base.isAuth)
  useMeQuery({

    skip: !isAuth,
    onCompleted: (data) => {
        data.me && dispatch(editBase({field: 'user', value: data.me}))
    }
  })
}
