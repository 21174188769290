import React, {FC, useState} from "react";
import {EyeCloseIcon, EyeOpenIcon} from "@icons/eyeSvg";
import {InputPropsType, TextErrorPropsType} from "./index.props";
import {generateGap} from "../../helpers/componentUtils/styleUtils";
import {RegisterOptions} from "react-hook-form";
import { ColorEnum } from "../../helpers/types"

export const TextError: FC<TextErrorPropsType> = ({error, defaultTextError}) => {
  return error ? <p className={'InputTextError'}>{(error as any)?.message || defaultTextError}</p> : null

}


export const Input: FC<InputPropsType> =
  ({
     classNameInput,
     classNameError,
     placeholder,
     error,
     register,
     registerOption = {} as RegisterOptions,
     password = false,
     gap,
     id,
     defaultValue,
     defaultTextError,
     onBlur,
     color = ColorEnum.light_gray,
     maxWidth, label
   }) => {
    const [visible, setVisible] = useState(true)
    const classError = error ? ` InputError${classNameError ? ' ' + classNameError : ''}` : ''
    const classPassword = password ? ' InputPassword' : ''
    const inputStyle = {
      border: `1px solid var(${color})`,

    }
    const mainDivStyle = {
      maxWidth: maxWidth ? maxWidth : '100%',
      ...generateGap(gap)
    }
    return <div
      style={mainDivStyle}
      className={'InputWrap'}>
      {label && <label className={'InputLabel'} htmlFor={id}>{label}</label>}
      <div className={'InputContainer'}>
        <input
          style={inputStyle}
          defaultValue={defaultValue ?? ''}
          id={id || ''}
          className={`Input${classNameInput ? ' ' + classNameInput : ''}${classError}${classPassword}`}
          placeholder={placeholder}
          type={password && visible ? 'password' : 'text'}
          {...register(id || placeholder || (Date.now() as any), {...registerOption})}
          onBlur={() => onBlur && onBlur()}
        />
        {password && <button type={'button'}
                             className={'InputPasswordIcon'}
                             onClick={() => setVisible(!visible)}>
          {visible ? <EyeCloseIcon/> : <EyeOpenIcon/>}
        </button>}
      </div>
      {error && <TextError error={error} defaultTextError={defaultTextError ?? 'Field is not valid'}/>}
    </div>
  };

