import React, {FC, memo} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import {useDispatch, useSelector} from "react-redux";
import {setOpenFilter} from "@store/reducers/landingReducer";
import {ApplicationState} from "@store/store";
import {filter as FilterSvg} from "@icons/filter";
import {chevron as ChevronSvg, ChevronOrientation} from "@icons/chevron";
import { ColorEnum } from "../../../../../helpers/types"

const cx = classNames.bind(s);

type FilterProps = {}

const Filter: FC<FilterProps> = (): JSX.Element => {
  const dispatch = useDispatch()
  const isOpenFilter = useSelector((state: ApplicationState) => state?.landing?.isOpenFilter)
  return (
    <div className={cx('Filter', 'FilterComponent')}>
      <div className={cx('FilterIcon')}>
        <FilterSvg width='18'/>
      </div>
      <div className={cx('FilterItem')}>
        <span className={cx('FilterItemLabel')}>Other filters <ChevronSvg orientation={ChevronOrientation.Bottom}
                                                                          fill={ColorEnum.light_gray}/> </span>
        <span id={'additionalFilter'} className={cx('FilterItemTarget')}
              onClick={() => dispatch(setOpenFilter(!isOpenFilter))}
        >Free</span>
      </div>
    </div>
    //
    // <Button
    //     icon={<FilterSvg/>}
    //     iconGap={21}
    //     height={'100%'}
    //     outline
    //     onClick={() => dispatch(setOpenFilter(!isOpenFilter))}>
    //     Filter
    //   </Button>
  );
};

export default memo(Filter);
