import React, {FC, useState} from "react";
import {EyeCloseIcon, EyeOpenIcon} from "@icons/eyeSvg";
import {TextFieldPropsType} from "./index.props";
import {generateGap} from "helpers/componentUtils/styleUtils";
import {ColorEnum} from "helpers/types"
import {TextError} from "@components/modules";
import {FieldError} from "react-hook-form";


export const TextField: FC<TextFieldPropsType> =
  ({
     classNameInput,
     classNameError,
     error,
     password = false,
     gap,
     id,
     defaultValue,
     defaultTextError,
     color = ColorEnum.light_gray,
     maxWidth, label, style, styleMainDiv,
     ...props
   }) => {
    const [visible, setVisible] = useState(true)
    const classError = error ? ` InputError${classNameError ? ' ' + classNameError : ''}` : ''
    const classPassword = password ? ' InputPassword' : ''
    const inputStyle = {
      ...style,
      border: `1px solid var(${color})`,

    }
    const mainDivStyle = {
      maxWidth: maxWidth ? maxWidth : '100%',
      ...generateGap(gap),
      ...(styleMainDiv || {})
    }
    return <div
      style={mainDivStyle}
      className={'InputWrap'}>
      {label && <label className={'InputLabel'} htmlFor={id}>{label}</label>}
      <div className={'InputContainer'}>
        <input
          style={inputStyle}
          defaultValue={defaultValue ?? ''}
          id={id || ''}
          className={`Input${classNameInput ? ' ' + classNameInput : ''}${classError}${classPassword}`}
          type={password && visible ? 'password' : 'text'}
          {...props}
        />
        {password && <button type={'button'}
                             className={'InputPasswordIcon'}
                             onClick={() => setVisible(!visible)}>
          {visible ? <EyeCloseIcon/> : <EyeOpenIcon/>}
        </button>}
      </div>
      <TextError error={error as FieldError}/>
    </div>
  };

