import React, {FC, useState} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import Button from "@components/Button";
import {useForm} from "react-hook-form";
import TextButton from "@components/TextButton";
import {AuthWithApp} from '@components/AuthWithApps';
import {ModalType} from "@ts_types/modal-types";
import {Checkbox} from '@components/UI/ReactForm/Checkbox';
import {TextField, Typography} from '@components/modules';
import {TextFieldPassword} from "@components/UI/ReactForm/TextField/view/TextFieldPassword";
import {Radio} from "@components/UI/Radio";
import {useTranslation} from "react-i18next";
import {FontWeight, TextColor, TypographyVariant} from "@components/UI/Typography/index.props";
import {
  ErrorEnum,
  ForgotPasswordMutationVariables,
  SignInMutationVariables,
  useForgotPasswordMutation,
  useSignInMutation
} from "@api/types_schema";
import {toast} from 'react-toastify';
import {useSignIn} from '@helpers/sing';
import {useMemo} from 'react';
import {FormMessage} from '@helpers/types';
import {regexEmail} from '@helpers/regex';
import {useModal} from '@helpers/hooks/useModal';

const cx = classNames.bind(s);

type LoginProps = {
  changePayload: (modalType: ModalType) => void
  closeModal: () => void

}


export const Login: FC<LoginProps> = ({changePayload, closeModal}): JSX.Element => {
  const [t] = useTranslation()
  const signIn = useSignIn()
  const [singInMutation] = useSignInMutation()
  const [globalError, setGlobalError] = useState<{ message: string } | null>(null)
  const goToRegistration = () => changePayload(ModalType.Registration)
  const goToForgotPassword = () => changePayload(ModalType.ForgotPassword)
  const {
    register,
    setError,
    setFocus,
    resetField,
    handleSubmit,
    formState: {errors}
  } = useForm<SignInMutationVariables>();
  const requiredMsg = useMemo(() => t(FormMessage.Required), [t])
  const notValidMsg = useMemo(() => t(FormMessage.NotValid), [t])


  const onSubmit = async (data: SignInMutationVariables) => {
    try {
      const result = await singInMutation({
        variables: {
          ...data
        }
      })
      if (result.data?.signIn?.errors === ErrorEnum.UserNotFoundError || result.data?.signIn?.errors === ErrorEnum.InvalidPasswordOrEmail) {
        setError('signin.email', {type: 'value'})
        setError('signin.password', {type: 'value'})
        resetField('signin.password')
        setFocus('signin.email')
        setGlobalError({
          message: t(result.data?.signIn?.errors ?? ErrorEnum.UserNotFoundError)
        })
        setTimeout(() => setGlobalError(null), 2000)
      } else if (result.data?.signIn?.user && result.data?.signIn?.token) {
        signIn(result.data.signIn.token, result.data.signIn.user)
        closeModal()
      } else {
        toast(t(result?.data?.signIn?.errors ?? ErrorEnum.NotFoundError), {type: "error"})
      }
    } catch (e: any) {
      toast(e?.networkError?.result?.errors?.[0]?.extensions ?? e?.graphQLErrors?.[0]?.message ?? 'Error', {
        type: "error",
      })
    }
  }
  return (
    <div className={cx('Component')}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Radio
          classNameComponent={cx('Radio')}
          defaultChecked
          id={'organizer'}
          name={'role'}
          label={'Sign in as a organizer'}
        />
        <Radio
          classNameComponent={cx('Radio')}
          id={'owner'}
          name={'role'}
          label={'Sign in as a owner'}
        />
        <TextField
          classNameComponent={'mt-24px'}
          label={t('Email')}
          {...register('signin.email', {
            required: {
              value: true,
              message: requiredMsg
            },
            pattern: {
              value: regexEmail,
              message: notValidMsg
            }
          })}
          error={errors.signin?.email}

        />
        <TextFieldPassword
          classNameComponent={'mt-24px'}
          label={t('Password')}
          {...register('signin.password', {
            required: {
              value: true,
              message: requiredMsg
            }
          })}
          error={errors.signin?.password}

        />
        <div className={cx('Row')}>
          <Checkbox
            classNameComponent={cx('Checkbox')}
            label={'Remember me'}
          />
          <TextButton
            underline={false}
            onClick={()=>
              goToForgotPassword()

          }
            classNameButton={cx('ForgotPassword')}
          >
            Forgot password?
          </TextButton>
        </div>
      </form>
      <Button type={'submit'} onClick={handleSubmit(onSubmit)} gap={{bottom: 16, top: 32}} widthMax>
        <Typography
          tag={TypographyVariant.p14}
          weight={FontWeight.Bold}
        >
          Log in
        </Typography>
      </Button>
      <AuthWithApp/>
      <div className={cx('Register')}>
        <Typography
          tag={TypographyVariant.p14}
          color={TextColor.Grey}
        >
          {t('Don\'t have an account?')}
        </Typography>
        <TextButton
          onClick={goToRegistration}
          underline={false}
          classNameButton={cx('RegisterBtn')}
        >
          {t('Register')}
        </TextButton>
      </div>
    </div>

  );
};

