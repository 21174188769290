import React, {FC} from 'react';
import s from './index.module.scss';
import classNames from 'classnames/bind'
import {SocialControllerPropsType} from './index.props';
import {Radio} from "@components/UI/Radio";
import {TelegramSvg} from "@icons/apps";
import {ColorEnum} from '@helpers/types';

const cx = classNames.bind(s)

const social = [
  'Facebook',
  'Instagram',
  'Website'
]

export const SocialController: FC<SocialControllerPropsType> = ({onChange}) => {
  return (
    <>
      {social && social.map((s, i) => {
        return (
          <div className={cx('Component')}>
            <div className={cx('Btns')}>
              <TelegramSvg fill={ColorEnum.black}/>
              <button  type={'button'} onClick={() => onChange(s)}>{s}</button>
            </div>
            <Radio name={''} id={'social'}/>
          </div>
        )
      })}
    </>
  );
};

