import {DefaultIconPropsType} from "@helpers/types";
import {FC} from "react";

export const ShareSvg:FC<DefaultIconPropsType> = ({height=14, width=14}) => (
  <svg width={width} height={height} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.85999 5.19338L5.19332 7.86004C5.13084 7.92202 5.08124 7.99575 5.0474 8.07699C5.01355 8.15823 4.99612 8.24537 4.99612 8.33338C4.99612 8.42138 5.01355 8.50852 5.0474 8.58976C5.08124 8.671 5.13084 8.74473 5.19332 8.80671C5.2553 8.86919 5.32903 8.91879 5.41027 8.95264C5.49151 8.98648 5.57865 9.00391 5.66666 9.00391C5.75466 9.00391 5.8418 8.98648 5.92304 8.95264C6.00428 8.91879 6.07801 8.86919 6.13999 8.80671L8.80666 6.14004C8.93219 6.01451 9.00272 5.84424 9.00272 5.66671C9.00272 5.48917 8.93219 5.31891 8.80666 5.19338C8.68112 5.06784 8.51086 4.99731 8.33332 4.99731C8.15579 4.99731 7.98553 5.06784 7.85999 5.19338Z"
      fill="var(--black)"/>
    <path
      d="M7.18666 10.6L6.33332 11.4467C5.85428 11.9403 5.21028 12.2403 4.52418 12.2895C3.83808 12.3387 3.15788 12.1336 2.61332 11.7134C2.3255 11.4762 2.09063 11.1813 1.92388 10.8477C1.75713 10.5141 1.66221 10.1493 1.64525 9.77669C1.62828 9.40413 1.68966 9.03215 1.8254 8.68478C1.96115 8.33742 2.16825 8.02239 2.43332 7.76004L3.37999 6.80671C3.44248 6.74473 3.49207 6.671 3.52592 6.58976C3.55976 6.50852 3.57719 6.42138 3.57719 6.33337C3.57719 6.24537 3.55976 6.15823 3.52592 6.07699C3.49207 5.99575 3.44248 5.92202 3.37999 5.86004C3.31802 5.79756 3.24428 5.74796 3.16304 5.71411C3.0818 5.68027 2.99467 5.66284 2.90666 5.66284C2.81865 5.66284 2.73151 5.68027 2.65027 5.71411C2.56903 5.74796 2.4953 5.79756 2.43332 5.86004L1.58666 6.71337C0.873429 7.40404 0.435047 8.33006 0.352901 9.31949C0.270755 10.3089 0.550426 11.2945 1.13999 12.0934C1.48991 12.5473 1.93242 12.9216 2.43812 13.1914C2.94381 13.4612 3.50113 13.6203 4.07304 13.6581C4.64496 13.6959 5.21837 13.6117 5.7552 13.4108C6.29203 13.21 6.77999 12.8973 7.18666 12.4934L8.13332 11.5467C8.25886 11.4212 8.32939 11.2509 8.32939 11.0734C8.32939 10.8958 8.25886 10.7256 8.13332 10.6C8.00779 10.4745 7.83752 10.404 7.65999 10.404C7.48246 10.404 7.31219 10.4745 7.18666 10.6Z"
      fill="var(--black)"/>
    <path
      d="M12.1067 1.14665C11.3023 0.55096 10.3087 0.268443 9.31124 0.351867C8.31382 0.435292 7.38088 0.878955 6.68668 1.59998L5.96668 2.33332C5.88438 2.39314 5.81557 2.46958 5.76469 2.55769C5.71381 2.64579 5.682 2.7436 5.67131 2.84478C5.66063 2.94596 5.67131 3.04825 5.70267 3.14504C5.73403 3.24183 5.78536 3.33096 5.85334 3.40665C5.91532 3.46914 5.98905 3.51873 6.07029 3.55258C6.15153 3.58642 6.23867 3.60385 6.32668 3.60385C6.41469 3.60385 6.50182 3.58642 6.58306 3.55258C6.6643 3.51873 6.73804 3.46914 6.80001 3.40665L7.66668 2.53332C8.14308 2.03753 8.7863 1.73587 9.47212 1.68662C10.1579 1.63737 10.8377 1.84402 11.38 2.26665C11.67 2.50385 11.9067 2.79949 12.0748 3.13429C12.2429 3.4691 12.3385 3.83556 12.3555 4.2098C12.3725 4.58404 12.3104 4.95766 12.1733 5.30632C12.0363 5.65497 11.8273 5.97083 11.56 6.23332L10.6133 7.18665C10.5509 7.24863 10.5013 7.32236 10.4674 7.4036C10.4336 7.48484 10.4161 7.57198 10.4161 7.65998C10.4161 7.74799 10.4336 7.83513 10.4674 7.91637C10.5013 7.99761 10.5509 8.07134 10.6133 8.13332C10.6753 8.1958 10.7491 8.2454 10.8303 8.27924C10.9115 8.31309 10.9987 8.33052 11.0867 8.33052C11.1747 8.33052 11.2618 8.31309 11.3431 8.27924C11.4243 8.2454 11.498 8.1958 11.56 8.13332L12.5067 7.18665C12.9094 6.78005 13.2212 6.29252 13.4214 5.75634C13.6215 5.22017 13.7055 4.64758 13.6677 4.07652C13.6298 3.50546 13.4711 2.94894 13.202 2.44384C12.9329 1.93874 12.5595 1.49658 12.1067 1.14665Z"
      fill="var(--black)"/>
  </svg>

)
