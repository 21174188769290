import {FC} from "react";
import {ColorEnum, DefaultIconPropsType} from "../../helpers/types";

export const PointIcon: FC<DefaultIconPropsType & { className?:string }> =
  ({fill=ColorEnum.accent, width = 18, height = 21, className}) => {
    return (
      <svg className={className} width={width} height={height} viewBox="0 0 18 21" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 20.7503L3.16634 14.9166C2.01256 13.7628 1.22684 12.2928 0.908518 10.6925C0.590198 9.09212 0.753581 7.43333 1.37801 5.92585C2.00243 4.41836 3.05986 3.12989 4.41656 2.22338C5.77326 1.31686 7.36831 0.833008 9 0.833008C10.6317 0.833008 12.2267 1.31686 13.5834 2.22338C14.9402 3.12989 15.9976 4.41836 16.622 5.92585C17.2464 7.43333 17.4098 9.09212 17.0915 10.6925C16.7732 12.2928 15.9874 13.7628 14.8337 14.9166L9 20.7503ZM13.5375 13.6204C14.4348 12.723 15.0459 11.5797 15.2934 10.335C15.541 9.09029 15.4138 7.80015 14.9282 6.6277C14.4425 5.45524 13.62 4.45313 12.5648 3.74809C11.5096 3.04305 10.2691 2.66673 9 2.66673C7.73093 2.66673 6.49036 3.04305 5.43516 3.74809C4.37996 4.45313 3.55752 5.45524 3.07184 6.6277C2.58616 7.80015 2.45905 9.09029 2.70658 10.335C2.95412 11.5797 3.56518 12.723 4.4625 13.6204L9 18.1579L13.5375 13.6204ZM9 10.9163C8.51377 10.9163 8.04746 10.7231 7.70364 10.3793C7.35982 10.0355 7.16667 9.56917 7.16667 9.08294C7.16667 8.59671 7.35982 8.13039 7.70364 7.78657C8.04746 7.44276 8.51377 7.2496 9 7.2496C9.48623 7.2496 9.95255 7.44276 10.2964 7.78657C10.6402 8.13039 10.8333 8.59671 10.8333 9.08294C10.8333 9.56917 10.6402 10.0355 10.2964 10.3793C9.95255 10.7231 9.48623 10.9163 9 10.9163Z"
          fill={`var(${fill})`}/>
      </svg>
    )
  }
