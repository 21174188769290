import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Event, Language, Maybe} from "@api/generated";


export type DayType = {
  day: { start: Date | number | null, end: Date | number | null },
  address: Maybe<string>,
  link: Maybe<string>
}
export type SpeakerType = {
  id: string| null,
  name: string,
  occupation: string,
  photo?: Maybe<string>,
  details: string,
  order: number,
  socials?: [
    name?: Maybe<string>,
    link?: Maybe<string>
  ]
}
export type LandingInitialState = {
  event: Event & { nameRu?: string, nameLt?: string, nameUk?: string, nameEn?: string, nameLv?: string },
  languages: {
    [Language.Lt]: boolean,
    [Language.Uk]: boolean,
    [Language.Ru]: boolean,
    [Language.En]: boolean,
    [Language.Lv]: boolean
  },

  defaultLanguage: Language,
  days: Array<DayType> | [],
  isOneLocation: boolean,
  isOnlineEvent: boolean,
  isSameTimeEveryDay: boolean,
  speakers: Maybe<Array<SpeakerType>> | []
}

const initialState: LandingInitialState = {
  event: {
    slug: '',
    status: null,
    coverImage: '',
    days: {
      firstDay: null,
      lastDay: null
    },
    prices: null,
    name: 'default name event',
    ticketTypes: [],
    locations: null,
    description: '',
    nameUk: 'default Ukraine event',
    nameRu: 'default Russia event',
    nameLv: 'default Lv event',
    nameLt: 'default Lt event',
    nameEn: 'default English event '
  },
  languages: {
    [Language.Ru]: false,
    [Language.En]: true,
    [Language.Lt]: false,
    [Language.Uk]: false,
    [Language.Lv]: false
  },
  defaultLanguage: Language.En,
  days: [],
  isOneLocation: false,
  isOnlineEvent: false,
  isSameTimeEveryDay: false,
  speakers: []
}

export const eventEditSlice = createSlice({
  name: 'eventEdit',
  initialState,
  reducers: {
    nextStep(state) {
    },
    setEventField(state, action: PayloadAction<{ field: keyof Event; value: Event["slug"] | Event["status"] | Event["coverImage"] | Event["days"] | Event["name"] | Event["description"] | Event["locations"] | Event["ticketTypes"] | Event["prices"] }>) {
      // @ts-ignore
      state.event[action.payload.field] = action.payload.value
    },
    setDefaultLanguage(state, action: PayloadAction<Language>) {
      state.defaultLanguage = action.payload
    },
    setEventEditStateField(state, action: PayloadAction<{ field: keyof Pick<LandingInitialState, 'isSameTimeEveryDay' | 'isOnlineEvent' | 'isOneLocation' | 'speakers'>, value: any }>) {
      state[action.payload.field] = action.payload.value
    },
    setDaysField(state, action: PayloadAction<Array<DayType>>) {
      state.days = action.payload
    },
    setLanguage(state, action: PayloadAction<LandingInitialState['languages']>) {
      state.languages = action.payload
    }
  },
})

// Action creators are generated for each case reducer function

export default eventEditSlice.reducer
export const {
  nextStep, setLanguage,
  setDefaultLanguage, setEventField,
  setDaysField, setEventEditStateField
} = eventEditSlice.actions
