import React, {FC, memo} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import {Button} from '@components/modules';
import {loupe as LoupeSvg} from "@icons/loupe";
import {EventsQueryVariables} from "@api/generated";
import {useSelector} from "react-redux";
import {ApplicationState} from "@store/store";
import {useDidUpdateEffect} from "../../../../helpers/hooks/useDidUpdate";
import './index.scss'
import {AdditionFilter, Filter, Period, Search, Location} from './modules';

const cx = classNames.bind(s);

type FilterEventsProps = {
  refetch: (option: EventsQueryVariables) => void
}


const FilterEvents: FC<FilterEventsProps> = ({refetch}): JSX.Element => {
  const {
    q,
    dateFrom,
    dateTill,
    isFeatured,
    limit,
    priceFrom,
    priceTill,
    skip,
    sort
  } = useSelector((state: ApplicationState) => state.landing.filter)
  useDidUpdateEffect(() => {
    refetch({
      q, sort, limit, priceFrom, priceTill, dateTill, isFeatured, dateFrom, skip
    })
  }, [q, dateFrom, dateTill, isFeatured, limit, priceFrom, priceTill, sort])
  return (
    <>
      <div className={cx('Component')}>
        <Search/>
        <Location/>
        <Period/>
        <Filter/>
        {/*<Button height={'100%'} minWidth={190}>Search</Button>*/}
      </div>
      <AdditionFilter/>

    </>
  );
};

export default memo(FilterEvents);
