import {ColorEnum, DefaultIconPropsType} from "helpers/types"

export const EyeOpenIcon = ({
                         fill = ColorEnum.gray,
                         width = 24,
                         height = 24,
                       }:DefaultIconPropsType) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.8699 11.5C21.2299 10.39 17.7099 4.81999 11.7299 4.99999C6.19995 5.13999 2.99995 9.99999 2.12995 11.5C2.04218 11.652 1.99597 11.8245 1.99597 12C1.99597 12.1755 2.04218 12.348 2.12995 12.5C2.75995 13.59 6.12995 19 12.0199 19H12.2699C17.7999 18.86 21.0099 14 21.8699 12.5C21.9577 12.348 22.0039 12.1755 22.0039 12C22.0039 11.8245 21.9577 11.652 21.8699 11.5ZM12.2199 17C7.90995 17.1 5.09995 13.41 4.21995 12C5.21995 10.39 7.82995 7.09999 11.8299 6.99999C16.1199 6.88999 18.9399 10.59 19.8299 12C18.7999 13.61 16.2199 16.9 12.2199 17Z"
      fill={`var(${fill})`}/>
    <path
      d="M12 8.5C11.3078 8.5 10.6311 8.70527 10.0555 9.08986C9.47993 9.47444 9.03133 10.0211 8.76642 10.6606C8.50152 11.3001 8.4322 12.0039 8.56725 12.6828C8.7023 13.3618 9.03564 13.9854 9.52513 14.4749C10.0146 14.9644 10.6383 15.2977 11.3172 15.4327C11.9961 15.5678 12.6999 15.4985 13.3394 15.2336C13.9789 14.9687 14.5256 14.5201 14.9101 13.9445C15.2947 13.3689 15.5 12.6922 15.5 12C15.5 11.0717 15.1313 10.1815 14.4749 9.52513C13.8185 8.86875 12.9283 8.5 12 8.5ZM12 13.5C11.7033 13.5 11.4133 13.412 11.1666 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5007 12.2999 10.4709 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5006 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z"
      fill={`var(${fill})`}/>
  </svg>
)
export const EyeCloseIcon = ({
                               fill = ColorEnum.gray,
                               width = 24,
                               height = 24,
                             }:DefaultIconPropsType) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.70994 3.29C4.6167 3.19676 4.50601 3.1228 4.38419 3.07234C4.26237 3.02188 4.1318 2.99591 3.99994 2.99591C3.86808 2.99591 3.73751 3.02188 3.61569 3.07234C3.49387 3.1228 3.38318 3.19676 3.28994 3.29C3.10164 3.47831 2.99585 3.7337 2.99585 4C2.99585 4.2663 3.10164 4.5217 3.28994 4.71L8.91994 10.34C8.56375 11.0026 8.43053 11.7624 8.54003 12.5067C8.64953 13.2509 8.99593 13.9401 9.52788 14.4721C10.0598 15.004 10.749 15.3504 11.4933 15.4599C12.2376 15.5694 12.9973 15.4362 13.6599 15.08L19.2899 20.71C19.3829 20.8037 19.4935 20.8781 19.6154 20.9289C19.7372 20.9797 19.8679 21.0058 19.9999 21.0058C20.132 21.0058 20.2627 20.9797 20.3845 20.9289C20.5064 20.8781 20.617 20.8037 20.7099 20.71C20.8037 20.617 20.8781 20.5064 20.9288 20.3846C20.9796 20.2627 21.0057 20.132 21.0057 20C21.0057 19.868 20.9796 19.7373 20.9288 19.6154C20.8781 19.4936 20.8037 19.383 20.7099 19.29L4.70994 3.29ZM11.9999 13.5C11.6021 13.5 11.2206 13.342 10.9393 13.0607C10.658 12.7794 10.4999 12.3978 10.4999 12V11.93L12.0599 13.49L11.9999 13.5Z"
      fill={`var(${fill})`}/>
    <path
      d="M12.2199 17C7.91995 17.1 5.09995 13.41 4.21995 12C4.84642 11.0007 5.59931 10.0865 6.45995 9.27999L4.99995 7.87C3.87127 8.93347 2.90435 10.1564 2.12995 11.5C2.04218 11.652 1.99597 11.8245 1.99597 12C1.99597 12.1755 2.04218 12.348 2.12995 12.5C2.75995 13.59 6.12995 19 12.0199 19H12.2699C13.3775 18.9671 14.4707 18.7404 15.4999 18.33L13.9199 16.75C13.3643 16.8962 12.7941 16.98 12.2199 17Z"
      fill={`var(${fill})`}/>
    <path
      d="M21.87 11.5C21.23 10.39 17.7 4.81999 11.73 4.99999C10.6225 5.03285 9.52924 5.25961 8.5 5.66999L10.08 7.24999C10.6356 7.10382 11.2058 7.01997 11.78 6.99999C16.07 6.88999 18.89 10.59 19.78 12C19.1381 13.0023 18.3682 13.9167 17.49 14.72L19 16.13C20.1428 15.0693 21.1234 13.8462 21.91 12.5C21.9918 12.3445 22.0311 12.1702 22.0241 11.9946C22.0171 11.8191 21.9639 11.6485 21.87 11.5Z"
      fill={`var(${fill})`}/>
  </svg>

)
