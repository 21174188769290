import {ColorEnum, DefaultIconPropsType, Orientation} from "@helpers/types"
import { CSSProperties, FC } from "react"

export const RectangleIcon: FC<DefaultIconPropsType> = ({
                                                          height = '6',
                                                          width = '10',
                                                          fill = ColorEnum.black,
                                                          orientation = Orientation.Bottom
                                                        }) => {

  const style: CSSProperties = {
    transform: orientation === Orientation.Top ? 'rotate(-180deg)' : orientation === Orientation.Left ? 'rotate(90deg)' : orientation === Orientation.Right ? 'rotate(-90deg)' : 'rotate(0deg)',
    transition: 'transform .25s ease-in-out'

  }
  return <svg style={style} width={width} height={height}
              viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd"
          d="M8.93248 0C9.3564 0 9.58798 0.494429 9.31659 0.820092L5.38411 5.53907C5.18421 5.77894 4.81579 5.77894 4.61589 5.53907L0.683409 0.820091C0.412023 0.494428 0.643602 0 1.06752 0L8.93248 0Z"
          fill="#3B3E44"/>
  </svg>

}
