import React, {memo, FC} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import {logo as LogoSvg} from '@icons/logo'
import { Link } from 'react-router-dom';

const cx = classNames.bind(s);

type LogoProps = {
  className?:string
}

const Logo: FC<LogoProps> = ({className}): JSX.Element => {
  return (
    <Link to={'/'} className={cx('Logo', className || '')}>
      <LogoSvg/>
    </Link>
  );
};

export default memo(Logo);
