import {FC} from "react";
import {AvatarPropsType} from "./prop.type";
import s from './index.module.scss'
import {Typography} from "@components/modules";
import {FontWeight, TextColor, TypographyVariant} from "@components/UI/Typography/index.props";

export const Avatar: FC<AvatarPropsType> =
  ({
     avatar,
     firstName,
     className,
     classNameComponent,
     classNameLabel,
     label,
     labelOption,
   }) => {
    return (
      <div className={`${s.Component} ${classNameComponent || ''}`}>
        <div className={`${s.Avatar} ${className || ''}`}>
          {avatar
            ? <img src={avatar ?? ''} alt=""/>
            : firstName ? firstName?.charAt(0) : '?'}
        </div>
        {label && (
          <Typography
            className={classNameLabel || ''}
            tag={labelOption?.variant || TypographyVariant.p14}
            color={labelOption?.color || TextColor.Dark}
            weight={labelOption?.weight || FontWeight.Medium}
          >{label}</Typography>
        )}
      </div>
    );
  };