import {GapType} from "../../helpers/types";
import {ColorEnum} from "../../helpers/types"
import {CSSProperties} from "react";


export enum IconOrientation {
  Left = 'left',
  Right = 'right'
}

export type ButtonPropsType = {
  outlineColor?: ColorEnum,
  disabled?: boolean,
  textColor?: ColorEnum
  color?: ColorEnum
  children: any
  outline?: boolean
  onClick?: any
  widthMax?: boolean
  iconOrientation?: IconOrientation
  icon?: JSX.Element
  iconGap?: number
  size?: 'small' | 'medium' | 'large'
  minWidth?: number | string
  gap?: GapType,
  type?: 'button' | 'submit'
  to?: string
  asLink?: boolean,
  classNames?: string
  loading?: boolean
  style?: CSSProperties
}
