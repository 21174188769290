export const stepVariantsYOpacity = {
  initial: (direction: number) => {
    return {
      y: 20,
      opacity: 0,
      zIndex: 1
    }
  },
  animate: {
    zIndex: 1,
    y: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 1,
      y: 20,
      opacity: 0,
    }
  },
}
