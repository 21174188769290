import useMatchMedia from "use-match-media-hook";
import s from './index.module.scss'

// $breakpoint-desktop: 1440px;
// $breakpoint-laptop: 1280px;
// $breakpoint-ipad: 1024px;
// $breakpoint-tablet: 768px;
// $breakpoint-phone: 576px;

const queries = [
  '(max-width: 1439px)',
  '(max-width: 1279px)',
  '(max-width: 1023px)',
  '(max-width: 767px)',
  '(max-width: 575px)',
]
export const AdaptiveHelper = () => {
  const [laptop, ipad, tablet, landscape, phone] = useMatchMedia(queries)
  return (
    <div className={s.Component}>
      <p>
        {
          phone ? 'phone' : landscape ? 'landscape' : tablet ? 'tablet' : ipad ? 'ipad' : laptop ? 'laptop' : 'desktop'
        }
      </p>
    </div>
  )
}
