import React, {FC} from 'react';
import {Footer, Header} from "@components/modules";



export const AppLayout: FC = ({children}) => {
  return (
    <main>
      <Header/>
      <div>
        {children}
      </div>
      <Footer/>
    </main>
  );
};

