import Button from "@components/Button";
import React, {FC} from "react";
import {BtnAuthTypeInner} from "../modules";
import TextButton from "@components/TextButton";
import classNames from "classnames/bind";
import s from './index.module.scss';
import {SubmitHandler, useForm} from "react-hook-form";
import Center from "@components/Center";
import {ModalType} from "@ts_types/modal-types";
import {TextField, Typography} from "@components/modules";
import {useTranslation} from "react-i18next";
import {ErrorEnum, ForgotPasswordMutationVariables, useForgotPasswordMutation} from "@api/types_schema";
import {useModal} from "@helpers/hooks/useModal";
import {toast} from "react-toastify";

const cx = classNames.bind(s);

type ForgotPasswordPropsType = {}
export const ForgotPassword: FC<ForgotPasswordPropsType> = () => {
  const {register, handleSubmit, formState: {errors}} = useForm<ForgotPasswordMutationVariables>();
  const goToHelp = () => window.open('/help')
  const [t] = useTranslation()
  const [forgotPasswordMutation] = useForgotPasswordMutation()

  const onSubmitForgotPassword = async (data: ForgotPasswordMutationVariables) => {
    const result = await forgotPasswordMutation({
      variables: {
        email: data?.email
      }
    })
    if (result.data?.forgotPassword?.ok) {
      toast('Please check your email', {type: 'info'})
    } else if (result.data?.forgotPassword?.errors) {
      toast(t(result?.data?.forgotPassword?.errors ?? ErrorEnum.ErrorResult), {type: 'error'})
    }
  }
  return (
    <div className={cx('Component')}>
      <Typography className={'mb-24px'}>
        Enter the E-mail address that you used during registration:
      </Typography>
      <form>
        <TextField
          {...register('email')}
          label={t('Email')}/>
      </form>
      <div className={cx('Btn')}>
        <Button
          onClick={handleSubmit(onSubmitForgotPassword)}
          gap={{bottom: 16, top: 32}}
          widthMax
        >
          {t('Continue')}
        </Button>
        <Center><TextButton underline={false} onClick={goToHelp}>{t('I need help')}</TextButton></Center>
      </div>
    </div>
  )
};
