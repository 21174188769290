import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserType} from "@api/types_schema";
import { JWT_TOKEN_KEY } from "@helpers/constants/";


type InitialStateType = {
  user: UserType | null,
  isAuth: boolean,
  openSignModal: boolean,
}

const initialState: InitialStateType = {
  user: null,
  isAuth: !!localStorage.getItem(JWT_TOKEN_KEY),
  openSignModal: false,
}

export const baseSlice = createSlice({
  name: 'base',
  initialState,
  reducers: {
    openSignModal(state, action: PayloadAction<boolean>) {
      state.openSignModal = action.payload
    },
    editBase(state, action: PayloadAction<{ field: keyof InitialStateType, value: any } | { field: keyof InitialStateType, value: any }[]>) {
      // @ts-ignore
      if (Array.isArray(action.payload)) {
        action.payload.forEach(({field, value}) => {
          // @ts-ignore
          state[field] = value
        })
      } else {
        // @ts-ignore
        state[action.payload.field] = action.payload.value
      }

    },
    editUser(state, action: PayloadAction<{ field: keyof UserType, value: any }>) {
      // @ts-ignore
      state.user[action.payload.field] = action.payload.value
    }
  },
})

// Action creators are generated for each case reducer function
export const {editBase, openSignModal, editUser} = baseSlice.actions

export default baseSlice.reducer
