import {useDropdown} from "@helpers/hooks/useDropdown";
import {useTranslation} from "react-i18next";
import {useModal} from "@helpers/hooks/useModal";
import {useStore} from "@helpers/hooks/useStore";
import {Avatar} from "@components/Avatar";
import {Orientation} from "@helpers/types";
import {Link} from "react-router-dom";
import React, {useCallback} from "react";
import s from './index.module.scss'
import classNames from "classnames/bind";
import {HeartIcon, HistoryIcon, RectangleIcon, SignOutIcon, SupportIcon, UserIcon} from "@icons/modules";
import {ModalInfo} from "@modals/ModalInfo";
import {useSignOut} from "@helpers/sing";
import {useDispatch} from "react-redux";
import {AuthControl, BtnTicketBuy} from "@components/Header/Components";


const cx = classNames.bind(s);

export const UserAction = ({openSignModal}: any) => {
  const dropdown = useDropdown()
  const [t] = useTranslation()
  const {open, isOpen, close} = useModal()
  const dispatch = useDispatch()
  const {isAuth, user} = useStore(state => state.base)
  const openSignModalBtn = useCallback(() => dispatch(openSignModal(true)), [dispatch])
  const signOut = useSignOut()
  const onClickLogoutBtn = () => signOut()

  if (!isAuth)
    return (
      <>
        <AuthControl openModal={openSignModal}/>
        <BtnTicketBuy/>
      </>
    )
  return (
    <>
      <ModalInfo
        isOpen={isOpen}
        close={close}
        onConfirm={onClickLogoutBtn}
        title={t('Are you sure you want to sign out your account?')}
        btnText={t('Yes, sign out')}
      />
      <div ref={dropdown.ref} className={cx('Component')}>
        <div className={cx('Wrap')}>
          <button type='button' onClick={dropdown.toggle} className={cx('Control')}>
            <Avatar
              className={cx('Avatar')}
              avatar={user?.avatar}
              firstName={user?.firstName}
              label={user?.firstName ?? 'Unknown'}
            />
            <RectangleIcon orientation={dropdown.open ? Orientation.Top : Orientation.Bottom}/>
          </button>
        </div>
        {dropdown.open && (
          <div className={cx('Content')}>
            <Link onClick={dropdown.toggle} to='/settings'>
              <UserIcon width={16} height={16}/><span>{t('Account')}</span>
            </Link>
            <Link onClick={dropdown.toggle} to='/reservations'>
              <HistoryIcon/><span>{t('History')}</span>
            </Link>
            <Link onClick={dropdown.toggle} to='/my-teams'>
              <HeartIcon width={18} height={18}/><span>{t('My Team')}</span>
            </Link>
            <Link onClick={dropdown.toggle} to='/contact-us'>
              <SupportIcon/><span>{t('Support')}</span>
            </Link>
            <button onClick={open}>
              <SignOutIcon/><span>{t('Log out')}</span>
            </button>
          </div>
        )}
      </div>
    </>
  )
}
