import React, {createContext, FC, useContext, useEffect, useRef, useState} from 'react'
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";
import {stepVariantsYOpacity} from '@pages/ManageEvent/Steps/General/stepVariantsYOpacity';
import {parseChildren} from "@helpers/componentUtils/parseChildren";

const DropdownCtx = createContext({})

export const Dropdown: FC<{ children: any, className?: string, initialValue?: boolean }> =
  ({
     children,
     className,
     initialValue = false,
   }) => {
    const [show, setShow] = useState(initialValue)
    const wrapperRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
      document.addEventListener('click', handleClickOutside)
      return () => document.removeEventListener('click', handleClickOutside)
    }, [])

    const handleClickOutside = (event: any) => {
      const isContain = wrapperRef && !wrapperRef?.current?.contains(event.target)
      if (isContain) {
        setShow(false)
      }
    }
    return <div><DropdownCtx.Provider value={{show, setShow}}>
      <div ref={wrapperRef} className={className}>{children}</div>
    </DropdownCtx.Provider></div>
  }

export const DropdownHeader: FC<{ render: any, className?: string }> = ({render, className}) => {

  const {show, setShow} = useContext(DropdownCtx) as any
  return <div className={className}>
    {render(show, setShow)}
  </div>
}

export const DropdownContent: FC<{ children: JSX.Element, className?: string }> = ({children, className}): any => {

  const {show, setShow} = useContext(DropdownCtx) as any
  return <AnimatePresence>
    {show ? <motion.div
      initial={'initial'}
      animate={'animate'}
      exit={'exit'}
      variants={stepVariantsYOpacity}
      // onClick={() => setShow(!show)}
      className={className}>
      {parseChildren(children)}
    </motion.div> : false
    }
  </AnimatePresence>
}
