import {StepEnum} from "@pages/ManageEvent/StepEnum";

export const pages = {
  DashboardPage: {
    get path() {
      return '/'
    },
    title: 'Dashboard'
  },
  EventPage: {
    get path() {
      return '/event/:slug'
    },
    title: 'Event page',
    push: (slug?: string) => `/event/${slug}`
  },
  ManageEventPage: {
    get path() {
      return '/manage-event/:type/:step/:eventId?'
    },
    title: 'Manage event',
    push: ({
             eventId = '',
             type = 'add',
             step = StepEnum.General
           }: { step?: StepEnum, type?: 'add' | 'edit', eventId?: string }) => `/manage-event/${type}/${step}${eventId ? '/' + eventId : ''}`,
  },
  ReservationPage: {
    get path() {
      return '/reservations/:eventId'
    },
    push: (eventId: string) => '/reservations/' + eventId
  },
  OrganizersPage: {
    get path() {
      return '/organizers'
    },
  },
  ManageOrganizersPage: {
    get path() {
      return '/manage-organizers/:type/:organizerId?'
    },
  },
  SettingsPage: {
    get path() {
      return '/settings'
    }
  },
  LandingPages: {
    becomeOrganizer: {
      get path() {
        return '/become-organizer'
      }
    }
  },
}
