import React, {FC, memo} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import Slider from './Slider';
import imgEvent1 from '@img/event1.png'
import imgEvent2 from '@img/event2.png'
import imgEvent3 from '@img/event3.png'
import imgEvent4 from '@img/event4.png'
import imgEvent5 from '@img/event5.png'
import imgEvent6 from '@img/event6.png'
import imgEvent7 from '@img/event7.png'
import imgEvent8 from '@img/event8.png'
import imgEvent9 from '@img/event9.png'
import ContainerBgIcon from '@components/ContainerBgIcon';
import {useTranslation} from "react-i18next";
import {icons} from './index.icon';
import {PosterProps} from './index.props';
import {Typography} from "@components/UI/Typography";
import {TypographyVariant} from "@components/UI/Typography/index.props";
import MyCarousel from "@components/MyCarousel";

const cx = classNames.bind(s);

const Poster: FC<PosterProps> = () => (
  <section className={cx('Component')}>
    <MyCarousel
      time={2000}
      slides={slidesList}
    />
  </section>
);

export default memo(Poster);

const slidesList = [
  {
    img: imgEvent1,
    date: '10-13 December 2021',
    location: 'Terasa Laukas, Nepriklausom...',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
    title: '“Europe BigData”<br/> Conference 2021'
  },
  {
    img: imgEvent2,
    date: '12-13 December 2021',
    location: 'Our Laukas, Nepriklausom...',
    description: 'Lorem 2 dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
    title: '“Europe 2” Conference 2021'
  },
  {
    img: imgEvent3,
    date: '21-13 May 2022',
    location: 'Our, Nepriklausom...',
    description: 'Lorem 3 dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
    title: '“Europe 3” Conference 2022'
  },
  {
    img: imgEvent5,
    date: '22-13 June 2022',
    location: 'Los, Nepriklausom...',
    description: 'Lorem 4 dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et',
    title: '“Europe 3” Conference 2022'
  }
]
