import React, {memo, FC} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import {Link} from 'react-router-dom';

const cx = classNames.bind(s);

type NavProps = {}

const Nav: FC<NavProps> = (): JSX.Element => {
  return (
    <nav className={cx('Nav')}>
      <Link to={'/'}>{'Events'}</Link>
      <Link to={'/manage_events'}>{'Manage events'}</Link>
      <Link to={'/offer_location'}>{'Offer location'}</Link>
    </nav>
  );
};

export default memo(Nav);
