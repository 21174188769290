import React, {FC, memo} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import {parseChildren} from '../../helpers/componentUtils/parseChildren';

const cx = classNames.bind(s);

type CenterProps = {
  children: JSX.Element[] | JSX.Element | string
  height?: number | string
}

const Center: FC<CenterProps> = ({children, height}): JSX.Element => {
  return (
    <div className={cx('Center')} style={{minHeight: height || 'auto'}}>
      {parseChildren(children)}
    </div>
  );
};

export default memo(Center);
