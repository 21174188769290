import React, {FC, memo} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import {CalendarSvg} from '@icons/calendar'
import Button from "@components/Button";
import {Event, Maybe} from "../../../../api/generated";
import CardImg from './CardImg';
import { ColorEnum } from "../../../../helpers/types"
import {LocationSvg} from "@icons/location";
import {StarSvg} from "@icons/star";
import {Typography} from '@components/UI/Typography';
import {FontWeight, TextColor, TypographyVariant} from "@components/UI/Typography/index.props";

const cx = classNames.bind(s);

type CardEventProps = {
  title: string,
  event: Maybe<Event>
}

const CardEvent: FC<CardEventProps> =
  ({event, title}) => {


    return (
      <div className={cx('Card')}>
        <div className={cx('CardPrice')}>
          {event?.prices?.priceMin === '0' ? 'Free, ' : (event?.prices?.priceMin ?? 'From 0')}
          {event?.prices?.priceMax ? event.prices.priceMax : ''} and more
        </div>
        <div className={cx('CardFavor')}>
          <StarSvg fill={ColorEnum.white}/>
        </div>
        <CardImg img={event?.coverImage ?? ''}/>
        <div className={cx('CardInfoWrap')}>
          <Typography tag={TypographyVariant.h26} weight={FontWeight.Bold}
                      color={TextColor.White}>{title} </Typography>
          <Typography >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididuntg elit, sed do eius vsfwo sk ut....
          </Typography>
          <div className={cx('CardInfo')}>
            <div className={cx('CardInfoText')}>
              <div className={cx('CardInfoIcon')}>
                <CalendarSvg width={18} fill={ColorEnum.accent}/>
              </div>
              <p> 10 Aug <span>(Wdn)</span> / 12:00 - 22:00</p>
            </div>
            <div className={cx('CardInfoText')}>
              <div className={cx('CardInfoIcon')}>
                <LocationSvg fill={ColorEnum.accent}/>
              </div>
              <p>Terasa Laukas, Nepriklausomybės ai...</p>
            </div>
          </div>
          <div className={cx('CardBtn')}>

            <Button asLink to={`event/${event?.slug ?? ''}`} widthMax> Show more</Button>
          </div>
        </div>
      </div>
    );
  };

export default memo(CardEvent);
