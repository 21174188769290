import {FC} from "react";
import {ColorEnum, DefaultIconPropsType} from "@helpers/types";

export const MailSvg: FC<DefaultIconPropsType> = ({fill = ColorEnum.accent, width = 21, height = 17}) => (
  <svg width={width} height={height} viewBox="0 0 21 17" fill={`var(${fill})`} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5 0.554688H1.5C1.10218 0.554688 0.720644 0.723258 0.43934 1.02332C0.158035 1.32337 0 1.73034 0 2.15469V14.9547C0 15.379 0.158035 15.786 0.43934 16.0861C0.720644 16.3861 1.10218 16.5547 1.5 16.5547H19.5C19.8978 16.5547 20.2794 16.3861 20.5607 16.0861C20.842 15.786 21 15.379 21 14.9547V2.15469C21 1.73034 20.842 1.32337 20.5607 1.02332C20.2794 0.723258 19.8978 0.554688 19.5 0.554688ZM17.85 2.15469L10.5 7.57869L3.15 2.15469H17.85ZM1.5 14.9547V2.88269L10.0725 9.21069C10.198 9.30359 10.3472 9.35337 10.5 9.35337C10.6528 9.35337 10.802 9.30359 10.9275 9.21069L19.5 2.88269V14.9547H1.5Z"
      fill={`var(${fill})`}/>
  </svg>


)
