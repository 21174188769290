import React from 'react'
import Select, {GroupBase, Props} from 'react-select';
import {Control, Controller} from "react-hook-form";
import {StylesConfig} from "react-select/dist/declarations/src/styles";
import './restyle-select.scss';
import {Typography} from "@components/UI/Typography";
import {TextColor, TypographyVariant} from "@components/UI/Typography/index.props";
import s from './index.module.scss';
import classNames from "classnames/bind";

const cx = classNames.bind(s)


function CustomSelect<Option, IsMulti extends boolean = false, Group extends GroupBase<Option>
  = GroupBase<Option>>(props: Props<Option, IsMulti, Group> & { control: Control, id: string, label?: string }) {
  const customStyles: StylesConfig<Option, boolean, Group> = {
    input: (provider, state) => ({
      ...provider,
      height: 60

    })

  }
  return (
    <div>
      {props.label && <Typography
          color={TextColor.White}
          className={cx('Label' )}
          tag={TypographyVariant.label_14}><label>{props.label}</label></Typography>}
      <Controller
        control={props.control}
        name={props.id}
        render={({
                   field: {onChange, onBlur, value, name, ref},
                   fieldState: {invalid, isTouched, isDirty, error},
                   formState,
                 }) => (
          <Select
            onChange={onChange}
            className={'react-select-container'}
            classNamePrefix={'react-select'}
            onBlur={onBlur}
            value={value}
            styles={customStyles}
            defaultValue={value}
            {...props}
            theme={(theme) => ({...theme, borderRadius: 2})}/>
        )}
      />
    </div>
  );

}

export default CustomSelect
