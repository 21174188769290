export enum OrientationSymmetric  {
  Vertical,
  Horizontal
}
export enum FormMessage {
  Required = 'Field is required',
  NotValid = 'Field is not valid',
  MaxLength = 'MaxLength',
  MinLength = 'MinLength'
}
export enum Orientation {
  Top,
  Right,
  Bottom,
  Left

}