import React, {FC, useState} from 'react';
import s from './index.module.scss';
import classNames from 'classnames/bind'
import {Paper} from "@components/UI/Paper";
import {CalendarSvg} from "@icons/calendar";
import {ColorEnum} from "helpers/types";
import {PointIcon} from "@icons/point";
import {Typography} from "@components/modules";
import {FontWeight, TypographyVariant} from "@components/UI/Typography/index.props";
import {CopyIcon} from "@icons/copy";

const cx = classNames.bind(s)

type CardTicketPropsType = {
  date: string,
  address: string,
  name: string,
  img?: string,
  children?: any
  value?: string,
}

export const CardTicket: FC<CardTicketPropsType> = ({children, date, address, name, value}) => {
  const [isCopy, setIsCopy] = useState<boolean>(false)
  const onCopy = () => {
    navigator.clipboard.writeText(value as any)
    setIsCopy(true)
    setTimeout(() => setIsCopy(false), 1000)
  }
  return (
    <Paper className={cx('Component')}  view={"ticket"}>
      <div>
        <div className={cx('LocalesInfo')}>
          <div className={cx('LocalInfo')}>
            <CalendarSvg height={22} width={22} fill={ColorEnum.accent}/>
            <a href="">
              <Typography weight={FontWeight.Bold}>
                {date}
              </Typography>
            </a>
          </div>
          <div className={cx('LocalInfo')}>
            <PointIcon/>
            <a href="">
              <Typography
                className={cx('Address')}
                weight={FontWeight.Bold}>
                {address}
              </Typography>
            </a>
            <button onClick={onCopy}>
              <CopyIcon className={cx('Copy')}/>
              {isCopy && <Typography>
                  Copy
              </Typography>}
            </button>
          </div>
        </div>
        <Typography
          weight={FontWeight.Medium}
          tag={TypographyVariant.h26}
          className={'mt-24px'}
        >{name}</Typography>
        <div>
          {children}
        </div>
      </div>
      <div>
        <div
          className={cx('Img')}
        />
      </div>
    </Paper>
  );
};

