import React, {FC, memo, useState} from "react";
import Skeleton from "react-loading-skeleton";
import s from '../index.module.scss'
import classNames from "classnames/bind";

const cx = classNames.bind(s);
const CardImg: FC<{ img: string }> = ({img}) => {
  const [isImgLoaded, setIsImgLoaded] = useState(false)
  const handleImgLoaded = () => setIsImgLoaded(true)
  return <div className={cx('CardImg')}>
    <img src={img}
         onLoad={handleImgLoaded}
         hidden={true} alt=""/>

    {(!isImgLoaded) ?
      <Skeleton className={cx('SkeletonImg')}/> :
      <img width={370} height={240}
           src={img} alt="image event"/>
    }
  </div>;
}
export default memo(CardImg)
