import { ColorEnum } from "helpers/types"

type CalendarSvgType = {
  fill?: ColorEnum,
  width?: number | string,
  height?: number | string
}
export const CalendarSvg = ({fill = ColorEnum.black, width = "24", height = "28"}: CalendarSvgType) => (
  <svg width={width} height={height} viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 3.33329H18.6667V1.99996C18.6667 1.64634 18.5262 1.3072 18.2761 1.05715C18.0261 0.807102 17.687 0.666626 17.3333 0.666626C16.9797 0.666626 16.6406 0.807102 16.3905 1.05715C16.1405 1.3072 16 1.64634 16 1.99996V3.33329H8V1.99996C8 1.64634 7.85952 1.3072 7.60948 1.05715C7.35943 0.807102 7.02029 0.666626 6.66667 0.666626C6.31305 0.666626 5.97391 0.807102 5.72386 1.05715C5.47381 1.3072 5.33333 1.64634 5.33333 1.99996V3.33329H4C2.93913 3.33329 1.92172 3.75472 1.17157 4.50487C0.421427 5.25501 0 6.27243 0 7.33329V23.3333C0 24.3942 0.421427 25.4116 1.17157 26.1617C1.92172 26.9119 2.93913 27.3333 4 27.3333H20C21.0609 27.3333 22.0783 26.9119 22.8284 26.1617C23.5786 25.4116 24 24.3942 24 23.3333V7.33329C24 6.27243 23.5786 5.25501 22.8284 4.50487C22.0783 3.75472 21.0609 3.33329 20 3.33329ZM4 5.99996H5.33333V7.33329C5.33333 7.68691 5.47381 8.02605 5.72386 8.2761C5.97391 8.52615 6.31305 8.66663 6.66667 8.66663C7.02029 8.66663 7.35943 8.52615 7.60948 8.2761C7.85952 8.02605 8 7.68691 8 7.33329V5.99996H16V7.33329C16 7.68691 16.1405 8.02605 16.3905 8.2761C16.6406 8.52615 16.9797 8.66663 17.3333 8.66663C17.687 8.66663 18.0261 8.52615 18.2761 8.2761C18.5262 8.02605 18.6667 7.68691 18.6667 7.33329V5.99996H20C20.3536 5.99996 20.6928 6.14044 20.9428 6.39048C21.1929 6.64053 21.3333 6.97967 21.3333 7.33329V12.6666H2.66667V7.33329C2.66667 6.97967 2.80714 6.64053 3.05719 6.39048C3.30724 6.14044 3.64638 5.99996 4 5.99996ZM20 24.6666H4C3.64638 24.6666 3.30724 24.5262 3.05719 24.2761C2.80714 24.0261 2.66667 23.6869 2.66667 23.3333V15.3333H21.3333V23.3333C21.3333 23.6869 21.1929 24.0261 20.9428 24.2761C20.6928 24.5262 20.3536 24.6666 20 24.6666Z"
      fill={`var(${fill})`}/>
    <path
      d="M6.66665 20.6667C7.40303 20.6667 7.99998 20.0697 7.99998 19.3333C7.99998 18.597 7.40303 18 6.66665 18C5.93027 18 5.33331 18.597 5.33331 19.3333C5.33331 20.0697 5.93027 20.6667 6.66665 20.6667Z"
      fill={`var(${fill})`}/>
    <path
      d="M17.3334 18H12C11.6464 18 11.3073 18.1405 11.0572 18.3905C10.8072 18.6406 10.6667 18.9797 10.6667 19.3333C10.6667 19.687 10.8072 20.0261 11.0572 20.2761C11.3073 20.5262 11.6464 20.6667 12 20.6667H17.3334C17.687 20.6667 18.0261 20.5262 18.2762 20.2761C18.5262 20.0261 18.6667 19.687 18.6667 19.3333C18.6667 18.9797 18.5262 18.6406 18.2762 18.3905C18.0261 18.1405 17.687 18 17.3334 18Z"
      fill={`var(${fill})`}/>
  </svg>

)
