import React, {DetailsHTMLAttributes, FC, forwardRef} from 'react'
import {TextColor, TypographyPropsType, TypographyVariant, variants} from "@components/UI/Typography/index.props";

import classNames from 'classnames/bind';
import s from './index.module.scss'
import { motion } from 'framer-motion/dist/framer-motion';

const cx = classNames.bind(s)

export const Typography: FC<TypographyPropsType & DetailsHTMLAttributes<HTMLParagraphElement>>
  = forwardRef(({
       tag = TypographyVariant.p16,
       color = TextColor.Dark,
       weight,
       children,
       className,
       ...props
     }, ref: any) => {
  const Tag = tag ? variants[tag] : 'p'


  const classNames = cx('typography', className || '', {
    [`typography-${tag}`]:tag,
    [`typography-${color}`]:color,
    [`typography-${weight}`]:weight,
  })

  return <Tag
    className={classNames}
    ref={ref}
    {...props}
  >
    {children}
  </Tag>
})
export const MTypography = motion(Typography)
