import {ForwardedRef, forwardRef} from "react";
import {CheckboxPropsType} from "@components/UI/ReactForm/Checkbox/index.props";
import s from './index.module.scss';
import classNames from "classnames/bind";
import {CheckIcon} from "@icons/checkIcon";

const cx = classNames.bind(s);


export const Checkbox = forwardRef<ForwardedRef<HTMLInputElement>, CheckboxPropsType>(
  ({label, classNameComponent, float = 'right', view = 'default', ...props}, ref) => {
    const renderLabel = label ? <p className={cx('Label')}>{label}</p> : ''
    return (
      <label
        className={cx('Component',
          classNameComponent
          || '', {ComponentRoller: view === 'roller'},
          {ComponentBorder: view === "border-gray"})}
      >
        {float === 'left' && renderLabel}
        <input
          ref={ref as any}
          type={'checkbox'}
          {...props}
        />

        <span
          style={{
            marginLeft: float === 'left' ? 10 : 0,
            marginRight: float === 'right' ? 10 : 0
          }}
          className={cx('Checkbox')}>
          {view === 'default' && <CheckIcon className={cx('Icon')}/>}
        </span>
        {float === 'right' && renderLabel}
      </label>
    )
  }
)