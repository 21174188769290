import React, {memo, FC, MouseEventHandler, ReactChild, CSSProperties} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import {parseChildren} from "../../helpers/componentUtils/parseChildren";

const cx = classNames.bind(s);

type TextButtonProps = {
  children:any
  classNameButton?: string
  onClick?: MouseEventHandler<HTMLButtonElement>,
  underline?: boolean
}

const TextButton: FC<TextButtonProps> = ({onClick, classNameButton, underline = true, children}) => {
  const styleBtn: CSSProperties = {
    textDecorationLine: underline ? 'underline' : 'none'
  }
  return (
    <div className={`${cx('Component')}`}>
      <button
        style={styleBtn}
        onClick={onClick}
        type={'button'}
        className={`${classNameButton ? ' ' + classNameButton : ''}`}
      >
        {parseChildren(children)}
      </button>
    </div>
  );
};

export default memo(TextButton);
