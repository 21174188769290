import React, {FC} from 'react';
import s from './index.module.scss';
import classNames from 'classnames/bind'
import {Typography} from "@components/modules";
import {FontWeight, TextColor, TypographyVariant} from "@components/UI/Typography/index.props";
import {ListAvatars} from "@components/UI/List/ListAvatars";

const cx = classNames.bind(s)


const avatars = [
  'https://i.ytimg.com/vi/E-B-mDpYspo/mqdefault.jpg',
  'https://i.ytimg.com/vi/E-B-mDpYspo/mqdefault.jpg',
  'https://i.ytimg.com/vi/E-B-mDpYspo/mqdefault.jpg',
  'https://i.ytimg.com/vi/E-B-mDpYspo/mqdefault.jpg',
  'https://i.ytimg.com/vi/E-B-mDpYspo/mqdefault.jpg'
]

export const CardTicketInfo: FC = (props) => {
  return (
    <div className={cx('Component')}>
      <Typography className={cx('Title')} tag={TypographyVariant.p12}>Tickets sold</Typography>
      <div className={cx('TotalInfo')}>
        <div className={cx('Total')}>
          <Typography
            tag={TypographyVariant.p18}
            weight={FontWeight.Bold}>
            105/130
          </Typography>
          <Typography
            style={{marginLeft: '8px'}}
            tag={TypographyVariant.p12}
            color={TextColor.Grey}>
            Total
          </Typography>
        </div>
        <div className={cx('Results')}>
          <div className={cx('Circle')}/>
          <Typography>
            75/100
            <span style={{color: "gray"}}>
              Early bird
            </span>
          </Typography>
        </div>
        <div className={cx('Results')}>
          <div className={cx('Circle')}/>
          <Typography>
            30/30
            <span style={{color: "gray"}}>
              Late bird
            </span>
          </Typography>
        </div>
      </div>
      <div className={cx('TotalInfo')}>
        <div className={cx('Total')}>
          <Typography
            tag={TypographyVariant.p18}
            weight={FontWeight.Bold}>
            $ 184,00
          </Typography>
        </div>
        <div className={cx('Results')}>
          <Typography>
            $ 50,00
          </Typography>
        </div>
        <div className={cx('Results')}>
          <Typography>
            $ 134,00
          </Typography>
        </div>
      </div>
      <ListAvatars
        className={cx('ListAvatars')}
        avatars={avatars}
        text={'Organizers and volunteers'}
        showBy={3}
      />
    </div>
  );
};

