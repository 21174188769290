import {ColorEnum, DefaultIconPropsType} from "../../helpers/types"
import { FC } from "react"

export const PenIcon: FC<DefaultIconPropsType> = ({fill = `var(${ColorEnum.black})`, width=21, height=20}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M0.894247 16.3276L1.62275 13.0494C1.78866 12.3028 2.16402 11.619 2.70481 11.0782L12.6052 1.17782C14.1756 -0.392603 16.7218 -0.392607 18.2922 1.17781L19.6425 2.52812C21.2129 4.09853 21.2129 6.64469 19.6425 8.21511L9.7421 18.1155C9.20131 18.6563 8.51753 19.0317 7.77095 19.1976L4.49267 19.9261C2.33775 20.4049 0.415377 18.4826 0.894247 16.3276ZM3.58553 13.4855L2.85702 16.7638C2.6974 17.4821 3.33819 18.1229 4.0565 17.9633L7.33478 17.2348C7.69378 17.155 8.02375 16.9784 8.289 16.7244L4.09588 12.5313C3.84195 12.7966 3.66531 13.1265 3.58553 13.4855ZM5.51729 11.1092L9.71109 15.303L15.3266 9.68748L11.1328 5.49368L5.51729 11.1092ZM18.2208 6.79336L16.7484 8.26573L12.5546 4.07193L14.027 2.59956C14.8122 1.81435 16.0852 1.81435 16.8704 2.59956L18.2208 3.94986C19.006 4.73507 19.006 6.00815 18.2208 6.79336Z"
            fill={fill}/>
    </svg>

  )
}