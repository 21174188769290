import {useState} from "react"

export function useModal<T>(initialState = false) {
  const [isOpen, setIsOpen] = useState<boolean>(initialState)
  const [payload, setPayload] = useState<null | T>(null)

  const open = (payload?: T) => {
    setIsOpen(true)
    setPayload(payload ?? null)
  }
  const changePayload = (payload?: T) => {
    setPayload(payload ?? null)
  }

  const close = () => {
    setIsOpen(false)
    setPayload(null)
  }

  return {isOpen, open, close, payload, changePayload}
}