export const EventsNotFoundSvg = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M63.6107 23.332C59.66 9.86961 47.198 0.0105592 32.4748 0.0105592C7.30338 -0.316999 -8.68373 28.7221 5.04826 49.7822V62.5555C5.01375 64.1145 6.66466 65.3029 8.13086 64.7772L17.9946 61.4885C19.7133 62.3468 21.508 63.0472 23.3511 63.5863C28.9832 75.4625 46.3293 85.1715 63.1531 76.9222L71.8694 79.828C73.3392 80.3528 74.9856 79.1653 74.952 77.6063V66.5986C86.1554 49.8182 77.0834 29.6937 63.6107 23.332ZM19.3076 56.8932C19.0386 56.7479 18.3272 56.4406 17.4535 56.7318L9.73212 59.3062V49.089C9.73212 48.6275 9.59582 48.1764 9.34023 47.7922C6.31446 43.2421 4.71508 37.9383 4.71508 32.4541C6.1065 -4.31608 58.8433 -4.31733 60.2344 32.4544C60.4883 53.1586 37.3844 66.9384 19.3076 56.8932ZM70.6954 64.5245C70.4174 64.9192 70.2682 65.3902 70.2682 65.873V74.3568L63.7045 72.1687C63.0963 71.9659 62.4308 72.0215 61.8641 72.3228C50.3757 78.6786 35.8153 74.0503 29.5419 64.7658C49.4364 66.7587 66.7797 49.4139 64.7867 29.5201C75.1475 36.5648 79.1194 52.9375 70.6954 64.5245Z"
      fill="var(--accent)"/>
    <path
      d="M34.5819 40.684C36.8559 42.7959 40.0074 39.6462 37.8929 37.3709C34.9052 34.3853 30.0442 34.385 27.0565 37.3711C26.1417 38.2854 26.1413 39.7683 27.0555 40.683C27.9698 41.5981 29.4527 41.5983 30.3675 40.684C31.5293 39.5227 33.42 39.5228 34.5819 40.684Z"
      fill="var(--gray)"/>
    <path
      d="M42.5089 29.6272C43.8023 29.6272 44.8509 28.5787 44.8509 27.2853V24.7768C44.7364 21.6742 40.2816 21.6739 40.167 24.7768V27.2853C40.167 28.5788 41.2156 29.6272 42.5089 29.6272Z"
      fill="var(--gray)"/>
    <path
      d="M22.441 22.4348C21.1477 22.4348 20.0991 23.4834 20.0991 24.7767V27.2853C20.2136 30.3879 24.6684 30.3882 24.783 27.2853V24.7767C24.783 23.4834 23.7344 22.4348 22.441 22.4348Z"
      fill="var(--gray)"/>
  </svg>

)
