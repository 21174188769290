import React, {FC} from "react";
import {WaitSvg} from "@icons/waitSvg";
import Logo from "@components/Header/Logo";
import classNames from "classnames/bind";
import s from '../index.module.scss';
import {Typography} from "@components/UI/Typography";
import {TypographyVariant} from "@components/UI/Typography/index.props";
import {ModalType} from "@ts_types/modal-types";

const cx = classNames.bind(s)
export const HeaderModal: FC<{ modalType: ModalType }> = ({modalType}) => {
  function getTitle(type: ModalType) {
    let title = '';
    switch (type) {
      case ModalType.Login: {
        title = 'Sign in your account';
        break;
      }
      case ModalType.ForgotPassword:
        title = 'Forgot password?'
        break;
      case ModalType.LocationOffer:
        title = 'Location offer is comming soon'
        break;
      case ModalType.Registration:
        title = 'Create a new account';
        break;
      case ModalType.SelectEnvironment:
        title = 'Language and currency settings';
        break
      default:
        return;
    }
    return title;
  }

  return <div className={cx('Header')}>
    {modalType !== ModalType.LocationOffer && modalType !== ModalType.SelectEnvironment &&
        <Logo className={cx('Logo')}/>
    }
    {modalType === ModalType.LocationOffer && <WaitSvg/>}
    <Typography tag={TypographyVariant.h44}>{getTitle(modalType)}</Typography>
  </div>
}
