import {StepEnum} from "@pages/ManageEvent/StepEnum";

export default {
    title: {
        [StepEnum.General]: 'Choose a language',
        [StepEnum.Finance]: 'Finance',
        [StepEnum.Contacts]: 'Organisers  contacts',
        [StepEnum.Tickets]: 'Tickets',
        [StepEnum.Dates]: 'Dates',
        [StepEnum.Speakers]: 'Speakers',
    },
    'uk': 'Ukraine',
    'ru': 'Russia',
    'lt': 'Latvia',
    'pl': 'Polish',
    'en': 'English',
    'An unrivaled experience start with buying a ticket!': `An <span>unrivaled experience</span> start with buying a ticket!`,
    'Already satisfied customers have used our service': `Already <span>{{value}} satisfied customers</span> have used our service`,
    'Sorry, no events found. Change search or filter options': `Sorry, no events found. <br/>Change search or filter options`,
    'Field is required': 'Field is required. Please fill over field',
    'Settings': 'Settings',
    'Account info': 'Account info',
    'Name': 'Name',
    'Avatar': 'Avatar',
    'Save changes': 'Save changes',
    'Email changes': 'Email changes',
    'New email': 'New email',
    'Phone number changes': 'Phone number changes',
    'Tickets': 'Tickets',
    'Upcoming events': 'Upcoming events',
    'Past events': 'Past events',
    'Sign in with Facebook': 'Sign in with Facebook',
    'Sign in with Apple': 'Sign in with Apple',
    'Don\'t have an account?': 'Don\'t have an account?',
    'Register': 'Register',
    'I need help': 'I need help',
    'Create': 'Create',
    'Choose a language': 'Choose a language',
    'Create an URL':'Create an URL',
    'Event name':'Event name',
    'It will be very complicated to change in future.':'It will be very complicated to change in future.',
    'For all languages':'For all languages',
    'Add speaker':'Add speaker',
    'Add photo':'Add photo',
    'Add a new ograniser or pick from saved. You also can skip this sections going to the text step, then you will auromatically be chosen as an organiser.':'Add a new ograniser or pick from saved. You also can skip this sections going to the text step, then you will auromatically be chosen as an organiser.',
    'Add organisers':'Add organisers',
    'Organisers':'Organisers',
    'Add organizer':'Add organizer',
    'Back to organizers':'Back to organizers',
    '+ Add to calendar':'+ Add to calendar',
    'Schedule':'Schedule',
    'Details':'Details',


}
