import { MTypography } from "@components/UI/Typography";
import { AnimatePresence } from "framer-motion/dist/framer-motion";
import {FieldError} from "react-hook-form";
import {TextColor, TypographyVariant} from "@components/UI/Typography/index.props";

export const TextError = ({
                            error,
                            className
                          }: { error: FieldError | undefined | { message?: string } | null, className?: string }) => {
  return (
    <AnimatePresence>
      {!!error &&
          <MTypography
              tag={TypographyVariant.p14}
              dangerouslySetInnerHTML={{__html: error?.message ?? ''}}
              className={className}
              initial={{opacity: 0, height: 0}}
              animate={{opacity: 1, height: 'auto'}}
              exit={{opacity: 0, height: 0}}
              color={TextColor.Red}
          />}
    </AnimatePresence>
  )
}
