import {GapType} from "../types";

export const generateGap = (gap?: GapType) => {
  return {
    marginLeft: gap?.left ?? '',
    marginRight: gap?.right ?? '',
    marginTop: gap?.top ?? '',
    marginBottom: gap?.bottom ?? ''
  }
}
