import React, {FC, memo, useState} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import {Typography} from "@components/UI/Typography";
import {FontWeight, TextColor, TypographyVariant} from "@components/UI/Typography/index.props";
import {CalendarSvg} from "@icons/calendar";
import {CompassSvg} from "@icons/compass";
import { ColorEnum } from "../../helpers/types"
import {chevron as ChevronSvg, ChevronOrientation} from '@icons/chevron'
import {useSwipeable} from 'react-swipeable';
import {MyCarouselPropsType} from './index.props';

const cx = classNames.bind(s);

const variants = {
  hidden: {opacity: 0},
  visible: {opacity: 1},
}

const MyCarousel: FC<MyCarouselPropsType> = ({slides, time}) => {
  const [current, setCurrent] = useState(0)
  const [isPlay, setPlay] = useState(Boolean(time))
  const [startAnimate, setStartAnimate] = useState(false)
  const slide = slides[current]
  const next = () => setCurrent((current + 1) % slides.length)
  const prev = () => setCurrent((current - 1 + slides.length) % slides.length);
  React.useEffect(() => {
    if (!isPlay) return;
    const id = setTimeout(() => next(), time);
    return () => clearTimeout(id);
  }, [current, isPlay]);
  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => prev(),
  });

  return (<>

      <div className={cx('Component')}
           onMouseEnter={() => setPlay(false)}
           onMouseLeave={() => setPlay(true)}>
        <div className={cx('Slide')} {...handlers}>
          <div className={cx('SlideInfo')}>
            <Typography color={TextColor.White} tag={TypographyVariant.h32} weight={FontWeight.Bold}
                        dangerouslySetInnerHTML={{__html: slide.title}}/>
            <Typography color={TextColor.White}
                        className={cx('Description')}
                        dangerouslySetInnerHTML={{__html: slide.description}}/>
            <ul className={cx('List')}>
              <li className={cx('ListItem')}>
                <div className={cx('SvgContainer')}><CalendarSvg fill={ColorEnum.accent}/></div>
                <Typography color={TextColor.White}
                            tag={TypographyVariant.h26}>{slide.date}</Typography></li>
              <li className={cx('ListItem')}>
                <div className={cx('SvgContainer')}><CompassSvg fill={ColorEnum.accent}/></div>
                <Typography color={TextColor.White}
                            tag={TypographyVariant.h26}>{slide.location}</Typography>
              </li>
            </ul>
          </div>
          <div className={cx('SlideImg')}>
            <div className={cx('SlideImgItemWrap')}>
              <div className={cx('SlideImgItemColor')}/>
              <div className={cx('SlideImgItem')}
                   style={{backgroundImage: `url(${slide.img})`}}
              ></div>
            </div>
          </div>
          <div className={cx('Dots')}>{slides.map((el, i) =>
            <div key={i} onClick={() => setCurrent(i)} className={cx('Dot', {DotActive: i === current})}>
              <span/>
            </div>
          )}</div>
          <div className={cx('Arrows')}>
          <span className={cx('Left')} onClick={prev}>
            <ChevronSvg orientation={ChevronOrientation.Left}/></span>
            <span className={cx('Right')} onClick={next}> <ChevronSvg orientation={ChevronOrientation.Right}/></span>
          </div>

        </div>
      </div>
    </>

  );
};

export default memo(MyCarousel);
