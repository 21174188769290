import {FC} from "react";
import {ColorEnum} from "@helpers/types";

export const TelegramSvg: FC<{ fill?: string }> = ({fill = ColorEnum.accent}) => (
  <svg width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.1821 0.246815C19.9212 0.265792 19.6652 0.326984 19.424 0.428003H19.4207C19.1892 0.519815 18.0883 0.98294 16.4145 1.68494L10.4166 4.211C6.11281 6.02288 1.88213 7.80713 1.88213 7.80713L1.9325 7.78763C1.9325 7.78763 1.64081 7.8835 1.33613 8.09232C1.14789 8.21209 0.985917 8.36882 0.86 8.553C0.7105 8.77238 0.590251 9.10794 0.634938 9.45488C0.708063 10.0415 1.08831 10.3933 1.36131 10.5875C1.63756 10.7841 1.90081 10.8759 1.90081 10.8759H1.90731L5.87475 12.2125C6.05269 12.7837 7.08375 16.1734 7.33156 16.9543C7.47781 17.4206 7.62 17.7123 7.79794 17.9349C7.88406 18.0487 7.98481 18.1438 8.10588 18.2201C8.16881 18.2567 8.23592 18.2857 8.30575 18.3063L8.26513 18.2965C8.27731 18.2998 8.28706 18.3095 8.296 18.3128C8.3285 18.3217 8.35044 18.3249 8.39188 18.3314C9.01994 18.5216 9.5245 18.1316 9.5245 18.1316L9.55294 18.1088L11.8954 15.976L15.8214 18.9879L15.9107 19.0261C16.7289 19.3853 17.5577 19.1854 17.9956 18.8328C18.4368 18.4777 18.6082 18.0235 18.6082 18.0235L18.6367 17.9504L21.6706 2.40807C21.7567 2.02457 21.7786 1.66544 21.6836 1.31688C21.5855 0.964111 21.3592 0.660709 21.049 0.46619C20.7885 0.307846 20.4865 0.231417 20.1821 0.246815ZM20.1 1.91244C20.0968 1.96363 20.1065 1.95794 20.0837 2.05625V2.06519L17.0783 17.4458C17.0653 17.4678 17.0434 17.5157 16.9832 17.5636C16.9199 17.614 16.8695 17.6457 16.6054 17.5409L11.8036 13.8594L8.90294 16.5033L9.51231 12.6114L17.3578 5.29894C17.6812 4.99832 17.5731 4.93494 17.5731 4.93494C17.5959 4.56607 17.0848 4.82688 17.0848 4.82688L7.19181 10.9556L7.18856 10.9393L2.44681 9.34275V9.3395L2.43463 9.33707C2.44294 9.3343 2.45108 9.33105 2.459 9.32732L2.485 9.31432L2.51019 9.30538C2.51019 9.30538 6.74413 7.52113 11.0479 5.70925C13.2027 4.80169 15.3737 3.88763 17.0434 3.18238C18.7131 2.48119 19.9473 1.96688 20.0171 1.93925C20.0837 1.91325 20.0521 1.91325 20.1 1.91325V1.91244Z"
      fill={`var(${fill})`}/>
  </svg>


)
