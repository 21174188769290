import React, {FC, memo} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import {Link} from "react-router-dom";
import {parseChildren} from "../../helpers/componentUtils/parseChildren";
import {ButtonPropsType, IconOrientation} from './index.props';
import {generateGap} from '../../helpers/componentUtils/styleUtils';
import {ColorEnum} from "../../helpers/types"

const cx = classNames.bind(s);


const Button: FC<ButtonPropsType> = (
    {
      loading = false,
      children, color = ColorEnum.accent,
      outline = false, onClick,
      widthMax = false, iconOrientation = IconOrientation.Right,
      icon, iconGap = 8, minWidth,
      textColor = ColorEnum.black,
      gap,
      type = 'button', to,
      asLink, classNames = '',
      disabled, style, outlineColor,
      size = 'large'
    }
  ) => {
    const styles = {
      width: widthMax ? '100%' : 'fit-content',
      minWidth: minWidth ? minWidth : '',
      color: `var(${textColor})`,
      ...generateGap(gap),
      ...style
    }
    if (outlineColor) {
      styles.border = `1px solid var(${outlineColor})`
    }

    const goTo = (url: string) => {
      window.open(url, '_blank')
    }
    const handleClick = () => {
      onClick && onClick();
      to && goTo(to)
    }

    const className = `${cx('Btn', {
      [`BtnSize_${size}`]: size,
      [`Yellow${outline ? 'Outline' : ''}`]: color === ColorEnum.accent,
      [`Gray${outline ? 'Outline' : ''}`]: color === ColorEnum.gray,
      [`LightGray${outline ? 'Outline' : ''}`]: color === ColorEnum.light_gray,
      [`Red${outline ? 'Outline' : ''}`]: color === ColorEnum.red,
      'BtnDisabled': disabled
    })} ${classNames}`

    const renderIconLeft = () => (icon && iconOrientation === IconOrientation.Left) && icon
    const renderIconRight = () => (icon && iconOrientation === IconOrientation.Right) && icon
    const renderChildren = () => (
      <span style={{
        marginRight: (icon && (iconOrientation === IconOrientation.Right) && iconGap) || 0,
        marginLeft: (icon && (iconOrientation === IconOrientation.Left) && iconGap) || 0
      }}>{parseChildren(children)}</span>
    )
    const renderLoading = () => loading ? <span>loading</span> : null
    if (asLink) {
      return (
        <Link to={(to as any)}
              style={styles}
              className={className}>
          {renderIconLeft()}
          {renderChildren()}
          {renderIconRight()}
        </Link>
      )
    } else return (
      <button
        type={type}
        disabled={disabled}
        style={styles}
        onClick={handleClick}
        className={className}
      >
        {renderIconLeft()}
        {renderChildren()}
        {renderIconRight()}
        {renderLoading()}
      </button>
    )
  }
;

export default memo(Button);
