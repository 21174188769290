import {ApolloClient, createHttpLink, from, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {storage} from "@helpers/localStorage";

export const uri = process.env.REACT_APP_API;
const httpLink = createHttpLink({uri});

const authLink = setContext((_, {headers}) => {
  return {
    headers: {
      ...headers,
      // authorization: 'sdfdsf'
    }   ,
  }
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
