import React, {ForwardedRef, forwardRef, useState} from 'react';
import s from './index.module.scss';
import classNames from 'classnames/bind'
import {Maybe} from '@api/generated';
import {UploadFilePropsType} from "@components/UI/UploadImage/inde.props";
import UploadFile from "@components/UI/UploadImage/UploadFile";
import {Typography} from "@components/modules";
import {FontWeight, TextColor, TypographyVariant} from "@components/UI/Typography/index.props";
import {PenIcon} from '@icons/pen';
import {PlusIcon} from "@icons/plus";
import {ColorEnum} from '../../../helpers/types';
import {AddIcon} from "@icons/add";
import {useTranslation} from "react-i18next";

const cx = classNames.bind(s)

const UploadImage =
  forwardRef<ForwardedRef<HTMLInputElement>, Omit<UploadFilePropsType, 'setLoad' | 'loading' | 'onChangeUpload'> & { img: Maybe<string> }>
  (({
      className,
      view = 'simple',
      img,
      children,
      onChange,
      label,
      ...uploadFileProps
    }, ref) => {
      const [loading, setLoading] = useState(false)

      const onUpload = async (file: any) => {
        try {
          await onChange?.(file);
        } catch (e) {
          // @ts-ignore
          toast(e.message, {type: "error"})
        } finally {
          setLoading(false)
        }
      }
      const [t] = useTranslation()
      return (
        <>
          {label && <Typography
              className={cx('Label')}
              tag={TypographyVariant.p14}
              weight={FontWeight.Medium}
              color={TextColor.Grey}
          >
            {label}
          </Typography>}
          <UploadFile
            loading={loading}
            setLoad={setLoading}
            ref={ref as any}
            className={cx('Component',
              className ?? '',
              {Simple: view === 'simple'},
              {Accent: view === 'accent'},
              {WithoutPen: view === 'withoutPen'})}
            onChangeUpload={onUpload}
            {...uploadFileProps}
          >

            {img
              ? <img src={img} alt=""/>
              : (
                view === 'accent'
                  ? <div className={cx('AddPhoto')}>
                    <Typography weight={FontWeight.Bold} tag={TypographyVariant.p14}>{t('Add photo')}</Typography>
                    <AddIcon width={90} height={90}/>
                  </div>
                  : <PlusIcon fill={ColorEnum.black} width={25} height={25} className={cx('Plus')}/>
              )
            }
            {view === 'simple'
              ? <span className={cx('Pen')}>{<PenIcon/>}</span>
              : ''
            }
          </UploadFile>
        </>
      )
    }
  )

export default UploadImage