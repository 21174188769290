import {ColorEnum, DefaultIconPropsType } from "@helpers/types";
import { FC } from "react";

export const UserIcon: FC<DefaultIconPropsType> =
  ({
     fill = `var(${ColorEnum.gray}`,
     width = 18,
     height = 20
   }) => (
    <svg width={width} height={height} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M3.75656 5.5915C3.75656 2.77954 6.10504 0.5 9.00203 0.5C11.899 0.5 14.2475 2.77954 14.2475 5.5915C14.2475 7.35715 13.3216 8.91289 11.9153 9.82618C15.0712 10.9721 17.318 13.9258 17.318 17.3889V19.5H0.686035V17.3889C0.686035 13.9258 2.93285 10.9721 6.08874 9.82618C4.68249 8.91289 3.75656 7.35715 3.75656 5.5915ZM9.00072 8.44826C10.6259 8.44826 11.9433 7.16949 11.9433 5.59205C11.9433 4.01461 10.6259 2.73584 9.00072 2.73584C7.37557 2.73584 6.05813 4.01461 6.05813 5.59205C6.05813 7.16949 7.37557 8.44826 9.00072 8.44826ZM9.00445 11.5542C5.72625 11.5542 3.06076 14.1005 2.99268 17.2666H15.0162C14.9481 14.1005 12.2826 11.5542 9.00445 11.5542Z"
            fill={fill}/>
    </svg>


  )