export interface TypographyPropsType {
  tag?: TypographyVariant,
  color?: TextColor
  weight?: FontWeight
}
export enum TextColor {
  Dark = 'textDark',
  Grey = 'textGray',
  Light = 'textLight',
  White ='textWhite',
  Yellow = 'textYellow',
  Red= 'textRed',
  LightDark = 'textLightDark'
}

export enum FontWeight {
  Normal = 400,
  Medium = 500,
  Bold = 700,
}

export const variants = {
  h50 : "h1",
  h44 : "h2",
  h32 : "h3",
  h26 : "h4",
  h22 : "h4",
  h20 : "h5",
  p18 : 'p',
  p16 : 'p',
  p14 : 'p',
  p12 : 'p',
  label_14 : "label",


} as any;

export enum TypographyVariant {
  h50 = "h50",
  h44 = "h44",
  h32 = "h32",
  h26 = "h26",
  h22 = "h22",
  h20 = "h20",
  p18 = 'p18',
  p16 = 'p16',
  p14 = 'p14',
  p12 = 'p12',
  label_14 = "label_14",
}

