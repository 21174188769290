import React, {FC, memo, useState} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import {CloseSvg} from "@icons/close";
import {useDispatch} from "react-redux";
import {loupe as LoupeSvg} from "@icons/loupe"
import {LocationSvg} from "@icons/location";

const cx = classNames.bind(s);

type LocationPropsType = {}

const Location: FC<LocationPropsType> = () => {
  const [search, setSearch] = useState('')
  const dispatch = useDispatch()
  const reset = () => {
    setSearch('')
    // dispatch(setFilterField({field: '', value: search}))
  }
  // const func = useCallback(debounce((val: string) => {
  // dispatch(setFilterField({field: 'q', value: val}))
  // }, 500), [])

  const handleChange = (e: any) => {
    e.persist()
    const val = e.currentTarget.value
    setSearch(val);
    // func(val)
  }
  return (
    <div className={cx('Filter')}>
      <div className={cx('FilterIcon')}>
        <LocationSvg/>
      </div>
      <div className={cx('FilterItem')}>
        <label htmlFor="searchLocation" className={cx('FilterItemLabel')}>{'Pick the location'}</label>
        <input type="text"
               id='searchLocation'
               onChange={handleChange}
               value={search}
               placeholder={'Search by location'}
               className={cx('FilterItemTarget')}
        />
        {search &&
        <span className={cx('SearchReset')}
              onClick={reset}>
          <CloseSvg/>
        </span>}
      </div>
    </div>
  );
};

export default memo(Location);
