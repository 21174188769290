import React, {memo, FC} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import {useSelector} from "react-redux";
import {ApplicationState} from '@store/store';
import {useForm} from "react-hook-form";
import { Checkbox } from '@components/UI/ReactForm/Checkbox';

const cx = classNames.bind(s);

type AdditionFilterProps = {}

interface FormIAdditionFilter {
  today: Boolean
  tomorrow: Boolean
  thisWeek: Boolean
  free: Boolean
  priceFrom: Number | String
  priceTill: Number | String
}

const AdditionFilter: FC<AdditionFilterProps> = (): JSX.Element => {
  const isOpenFilter = useSelector((state: ApplicationState) => state?.landing?.isOpenFilter)
  const {register, formState: {errors}} = useForm<FormIAdditionFilter>()
  return <div className={cx('AdditionFilter', {'AdditionFilterOpened': isOpenFilter})}>
    <h3>Выберите дату/период:</h3>
    <div><Checkbox  label={'Сегодня'}/></div>
    <div><Checkbox label={'Завтра'}/></div>
    <div><Checkbox label={'На этой неделе'}/></div>
    <h3>Выберите ценовую категорию</h3>
    <div><Checkbox   label={'Бесплатные'}/></div>
    <div className={`AlignCenter ${cx('Price')}`}>
      <input className={`Input`} type="text" placeholder={'$'}/> <span>-</span>
      <input className={`Input`} type="text" placeholder={'$'}/>
    </div>
  </div>
};

export default memo(AdditionFilter);
