import React, {FC} from "react";
import {chevron as ChevronSvg, ChevronOrientation} from "@icons/chevron";
import classNames from "classnames/bind";
import s from '../index.module.scss';

const cx = classNames.bind(s)
export const BtnAuthTypeInner: FC<{ text: string }> = ({text}) =>
  <span className={cx('BtnInner')}>
    {text}
    <ChevronSvg orientation={ChevronOrientation.Right}/>
  </span>

