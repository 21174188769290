import React from "react";

export const CustomersIcon = () => <svg width="120" height="100" viewBox="0 0 120 100" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0)">
    <path
      d="M109.245 0.740356H90.7548C84.8246 0.740356 80 5.56489 80 11.4951V22.1847C80 24.3792 80.657 26.4903 81.9 28.2898C82.9593 29.8235 84.379 31.0432 86.0378 31.8522V38.0879C86.0378 38.5411 86.2992 38.9537 86.7091 39.1474C86.8687 39.2228 87.0395 39.2597 87.2095 39.2597C87.476 39.2597 87.7401 39.1689 87.9538 38.9932L95.3188 32.9394H109.245C115.175 32.9394 120 28.1149 120 22.1847V11.4951C120 5.56489 115.175 0.740356 109.245 0.740356ZM117.656 22.1847C117.656 26.8225 113.883 30.5957 109.245 30.5957H94.8989C94.6275 30.5957 94.3645 30.6899 94.1548 30.8622L88.3816 35.6077V31.09C88.3816 30.6103 88.0891 30.1789 87.6434 30.0014C84.424 28.7186 82.3438 25.6504 82.3438 22.1847V11.4951C82.3438 6.85723 86.117 3.08411 90.7548 3.08411H109.245C113.883 3.08411 117.656 6.85723 117.656 11.4951V22.1847Z"
      fill="#979797"/>
    <path
      d="M95.6955 12.3455C95.858 12.4228 96.0293 12.4594 96.1981 12.4594C96.6361 12.4594 97.0565 12.2126 97.2572 11.7906C97.5352 11.2062 97.2868 10.507 96.7023 10.229C93.6881 8.79509 90.6313 8.79509 87.617 10.229C87.0325 10.507 86.7841 11.2062 87.0622 11.7906C87.3402 12.3751 88.0393 12.6234 88.6238 12.3455C91.0031 11.2137 93.3162 11.2137 95.6955 12.3455Z"
      fill="#979797"/>
    <path
      d="M112.383 10.229C109.369 8.79506 106.312 8.79514 103.298 10.229C102.713 10.507 102.465 11.2062 102.743 11.7906C103.021 12.3751 103.72 12.6234 104.304 12.3455C106.684 11.2136 108.997 11.2136 111.376 12.3455C111.539 12.4228 111.71 12.4594 111.879 12.4594C112.317 12.4594 112.737 12.2126 112.938 11.7906C113.216 11.2062 112.967 10.507 112.383 10.229Z"
      fill="#979797"/>
    <path
      d="M106.732 20.5086C102.202 23.9329 97.798 23.9329 93.2683 20.5086C92.752 20.1183 92.017 20.2205 91.6268 20.7368C91.2365 21.253 91.3386 21.988 91.8549 22.3782C94.5308 24.4011 97.2713 25.4268 100 25.4268C102.729 25.4268 105.469 24.4011 108.145 22.3782C108.661 21.9879 108.763 21.253 108.373 20.7368C107.983 20.2204 107.248 20.1183 106.732 20.5086Z"
      fill="#979797"/>
  </g>
  <path
    d="M30.0001 56.6667C32.6372 56.6667 35.215 55.8847 37.4077 54.4196C39.6003 52.9545 41.3093 50.8721 42.3185 48.4358C43.3276 45.9994 43.5917 43.3185 43.0772 40.7321C42.5628 38.1457 41.2929 35.7699 39.4282 33.9052C37.5635 32.0405 35.1877 30.7707 32.6013 30.2562C30.0149 29.7417 27.334 30.0058 24.8976 31.0149C22.4613 32.0241 20.3789 33.7331 18.9138 35.9257C17.4487 38.1184 16.6667 40.6963 16.6667 43.3333C16.6667 46.8696 18.0715 50.2609 20.572 52.7614C23.0725 55.2619 26.4639 56.6667 30.0001 56.6667ZM30.0001 36.6667C31.3186 36.6667 32.6076 37.0577 33.7039 37.7902C34.8002 38.5228 35.6547 39.5639 36.1593 40.7821C36.6639 42.0003 36.7959 43.3407 36.5387 44.6339C36.2814 45.9271 35.6465 47.115 34.7141 48.0474C33.7818 48.9797 32.5939 49.6147 31.3007 49.8719C30.0075 50.1291 28.667 49.9971 27.4489 49.4925C26.2307 48.988 25.1895 48.1335 24.457 47.0371C23.7244 45.9408 23.3334 44.6519 23.3334 43.3333C23.3334 41.5652 24.0358 39.8695 25.286 38.6193C26.5363 37.3691 28.232 36.6667 30.0001 36.6667Z"
    fill="var(--accent)"/>
  <path
    d="M56.6667 63.3334C58.6446 63.3334 60.578 62.7469 62.2224 61.6481C63.8669 60.5493 65.1487 58.9875 65.9055 57.1602C66.6624 55.333 66.8605 53.3223 66.4746 51.3825C66.0888 49.4427 65.1363 47.6608 63.7378 46.2623C62.3393 44.8638 60.5575 43.9114 58.6177 43.5255C56.6778 43.1397 54.6672 43.3377 52.8399 44.0946C51.0127 44.8515 49.4509 46.1332 48.3521 47.7777C47.2532 49.4222 46.6667 51.3556 46.6667 53.3334C46.6667 55.9855 47.7203 58.5291 49.5957 60.4044C51.471 62.2798 54.0146 63.3334 56.6667 63.3334ZM56.6667 50C57.326 50 57.9705 50.1955 58.5187 50.5618C59.0668 50.9281 59.4941 51.4487 59.7463 52.0578C59.9986 52.6669 60.0647 53.3371 59.936 53.9837C59.8074 54.6303 59.49 55.2242 59.0238 55.6904C58.5576 56.1566 57.9637 56.474 57.3171 56.6027C56.6705 56.7313 56.0002 56.6653 55.3911 56.413C54.7821 56.1607 54.2615 55.7334 53.8952 55.1853C53.5289 54.6371 53.3334 53.9926 53.3334 53.3334C53.3334 52.4493 53.6846 51.6015 54.3097 50.9764C54.9348 50.3512 55.7827 50 56.6667 50Z"
    fill="var(--accent)"/>
  <path
    d="M56.6668 66.6668C52.9728 66.6709 49.3849 67.902 46.4667 70.1668C43.2018 66.9146 39.0469 64.7021 34.5262 63.8082C30.0054 62.9143 25.3211 63.3791 21.0642 65.1439C16.8072 66.9087 13.1681 69.8946 10.6059 73.7249C8.04365 77.5552 6.67298 82.0585 6.66675 86.6668C6.66675 87.5508 7.01794 88.3987 7.64306 89.0238C8.26818 89.6489 9.11603 90.0001 10.0001 90.0001C10.8841 90.0001 11.732 89.6489 12.3571 89.0238C12.9822 88.3987 13.3334 87.5508 13.3334 86.6668C13.3334 82.2465 15.0894 78.0073 18.215 74.8817C21.3406 71.7561 25.5798 70.0001 30.0001 70.0001C34.4204 70.0001 38.6596 71.7561 41.7852 74.8817C44.9108 78.0073 46.6667 82.2465 46.6667 86.6668C46.6667 87.5508 47.0179 88.3987 47.6431 89.0238C48.2682 89.6489 49.116 90.0001 50.0001 90.0001C50.8841 90.0001 51.732 89.6489 52.3571 89.0238C52.9822 88.3987 53.3334 87.5508 53.3334 86.6668C53.3414 82.7615 52.3548 78.9186 50.4668 75.5001C51.9398 74.3361 53.7109 73.6102 55.5772 73.4057C57.4435 73.2011 59.3297 73.526 61.02 74.3434C62.7102 75.1607 64.1362 76.4373 65.1349 78.0272C66.1335 79.6171 66.6644 81.4559 66.6668 83.3334C66.6668 84.2175 67.0179 85.0653 67.6431 85.6905C68.2682 86.3156 69.116 86.6668 70.0001 86.6668C70.8841 86.6668 71.732 86.3156 72.3571 85.6905C72.9822 85.0653 73.3334 84.2175 73.3334 83.3334C73.3334 78.9132 71.5775 74.6739 68.4519 71.5483C65.3263 68.4227 61.087 66.6668 56.6668 66.6668Z"
    fill="var(--accent)"/>
  <defs>
    <clipPath id="clip0">
      <rect width="40" height="40" fill="white" transform="translate(80)"/>
    </clipPath>
  </defs>
</svg>
