import React, {FC, memo} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import {Ambience, AuthControl, BtnTicketBuy, Logo, Nav} from '@components/Header/Components';
import {useLocation} from "react-router-dom";
import Modals from "@modals/ModalSign";
import {ModalType} from "@ts_types/modal-types";
import {useModal} from 'helpers/hooks/useModal';
import {useStore} from "@helpers/hooks/useStore";
import {UserAction} from './UserAction';

const cx = classNames.bind(s);


const Header: FC = (props): JSX.Element => {
  const {pathname} = useLocation()
  const isInverse = /event/.test(pathname)
  const {isAuth} = useStore(state => state.base)
  const {close, payload, isOpen, open, changePayload} = useModal<ModalType>()
  return (<>
      <header className={cx('Header', {'HeaderInverse': isInverse})}>
        <div className={'container'}>
          <div className={cx('HeaderContent')}>
            <Logo/>
            <Nav/>
            <Ambience openModal={open}/>
            <UserAction openSignModal={open}/>
          </div>
        </div>
      </header>
      <Modals
        changePayload={changePayload}
        isOpen={isOpen}
        close={close}
        modalType={payload ?? ModalType.Login}
      />
    </>
  );
};

export default memo(Header);
