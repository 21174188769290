import React, {FC} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import Button from "@components/Button";
import {AuthWithApp} from "@components/AuthWithApps";
import TextButton from "@components/TextButton";
import {SubmitHandler, useForm} from "react-hook-form";
import {ModalType} from "@ts_types/modal-types";
import {Checkbox} from '@components/UI/ReactForm/Checkbox';
import {TextField, Typography} from '@components/modules';
import {TextFieldPhone} from "@components/UI/ReactForm/TextField/controlled/TextFieldPhone";
import {TextFieldPassword} from "@components/UI/ReactForm/TextField/view/TextFieldPassword";
import {Radio} from "@components/UI/Radio";
import {useTranslation} from "react-i18next";
import {FontWeight, TextColor, TypographyVariant} from "@components/UI/Typography/index.props";
import {ErrorEnum, SignUpMutationVariables, useSignUpMutation} from "@api/types_schema";
import {toast} from 'react-toastify';
import {useSignIn} from "@helpers/sing";
import {useMemo} from 'react';
import {FormMessage} from '@helpers/types';
import {regexEmail, regexPassword} from '@helpers/regex';

const cx = classNames.bind(s);

type RegistrationProps = {
  changePayload: (modalType: ModalType) => void
  closeModal: () => void

}

export const Registration: FC<RegistrationProps> = ({changePayload, closeModal}): JSX.Element => {
  const login = () => changePayload(ModalType.Login)
  const [singUpMutation, {loading}] = useSignUpMutation()
  const [t] = useTranslation()
  const singIn = useSignIn()
  const requiredMsg = useMemo(() => t(FormMessage.Required), [t])
  const notValidMsg = useMemo(() => t(FormMessage.NotValid), [t])


  const {register, control, setError, handleSubmit, formState: {errors}} = useForm<SignUpMutationVariables>();
  const onSubmit = async (data: SignUpMutationVariables) => {
    try {
      const result = await singUpMutation({
        variables: {
          signup: {
            ...data.signup, phone: data.signup.phone?.replace(/ /g, '')
          }
        }
      })
      if (result.data?.signUp?.errors) {
        setError('signup.email', {message: t(ErrorEnum.DuplicateEmail)})
      } else if (result.data?.signUp?.user && result.data.signUp?.token) {
        singIn(result.data.signUp.token, result.data.signUp.user)
        closeModal()
      }

    } catch (e) {
      toast(t('Error'), {type: "error", position: 'bottom-right'})
      return
    }
  }

  const goToTerms = () => window.open('/terms', '_blank')
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Radio
          classNameComponent={cx('Radio')}
          defaultChecked
          id={'organizer'}
          name={'role'}
          label={'Sign in as a organizer'}
        />
        <Radio
          classNameComponent={cx('Radio')}
          id={'owner'}
          name={'role'}
          label={'Sign in as a owner'}
        />
        <TextField
          classNameComponent={'mt-sm-24px mt-48px '}
          label={t('First name')}
          {...register('signup.firstName', {
            required: requiredMsg
          })}
          error={errors.signup?.firstName}
        />
        <TextField
          classNameComponent={'mt-sm-16px mt-24px '}
          label={t('Last name')}
          {...register('signup.lastName', {
            required: requiredMsg
          })}
          error={errors.signup?.lastName}
        />
        <TextField
          classNameComponent={'mt-16px'}
          label={t('Email')}
          {...register('signup.email', {
            required: requiredMsg,
            pattern: {
              value: regexEmail,
              message: notValidMsg
            }
          })}
          error={errors.signup?.email}

        />
        <TextFieldPhone
          classNameComponent={'mt-16px'}
          name={'signup.phone'}
          label={'Phone'}
          control={control}
          rules={{
            required: requiredMsg
          }}
          error={errors.signup?.phone}
        />
        <TextFieldPassword
          classNameComponent={'mt-16px'}
          label={t('Password')}
          error={errors.signup?.password}
          {...register('signup.password', {
            required: requiredMsg,
            pattern: {
              value: regexPassword,
              message: `
                   <ul class="${s.ErrorList}">
                     <li>Use at least one uppercase letter</li>
                     <li>Use at least one lowercase letter</li>
                     <li>Use at least one number</li>
                     <li>Exclude characters.</li>
                   </ul>`
            },
          })}
        />
        <div className={cx('Row')}>
          <Checkbox label={'I agree with'}/>
          <TextButton classNameButton={cx('BtnGoTerms')} underline={false} onClick={goToTerms}>terms of
            service</TextButton>
        </div>
      </form>
      <Button onClick={handleSubmit(onSubmit)} gap={{bottom: 16, top: 32}} widthMax>
        <Typography weight={FontWeight.Bold}>
          {t('Create')}
        </Typography>
      </Button>
      <AuthWithApp/>
      <div className={cx('LogIn')}>
        <Typography
          tag={TypographyVariant.p14}
          color={TextColor.Grey}
        >
          {t('Don\'t have an account?')}
        </Typography>
        <TextButton
          onClick={login}
          underline={false}
          classNameButton={cx('LogInBtn')}
        >
          {t('Log in')}
        </TextButton>
      </div>
    </div>
  );
};

