import {Dropdown, DropdownContent, DropdownHeader} from "@components/Dropdown";
import {chevron as ChevronSvg, ChevronOrientation} from "@icons/chevron";
import {ColorEnum} from "@helpers/types";
import React from "react";
import {SelectFieldPropsType} from "./index.props";
import {Typography} from "@components/UI/Typography";
import classNames from "classnames/bind";
import s from "./index.module.scss";
import {FontWeight, TypographyVariant} from "@components/UI/Typography/index.props";

const cx = classNames.bind(s );


export function SelectField({title, renderContent, icon, label, isLabelGap}: SelectFieldPropsType) {
  return <div className={cx('Component')}>
    {(label || isLabelGap) &&
        <label className={cx({Hide: isLabelGap})}>{label ? label : 'A'}</label>}
    <Dropdown>
      <div className={'dropdown-container'}>
        <DropdownHeader render={(show: boolean, setShow: (v: boolean) => any) =>
          <div
            onClick={() => setShow(!show)}
            className={'dropdown-input'}>
            {icon}
            <Typography
              className={'dropdown-input__label'}
              tag={TypographyVariant.p18}
              weight={FontWeight.Medium}
            >
              {title}
            </Typography>
            <ChevronSvg orientation={show ? ChevronOrientation.Top : ChevronOrientation.Bottom}
                        width={13}
                        height={10}
                        fill={ColorEnum.black}/>
          </div>
        }/>
        <DropdownContent>

          <ul className={'dropdown-item'}>
            {renderContent}
          </ul>
        </DropdownContent>
      </div>
    </Dropdown>
  </div>;
}