import React, {FC} from 'react';
import {DefaultIconPropsType} from "@helpers/types";
import plus_icon from "@img/plus_icon.png";


export const AddIcon: FC<DefaultIconPropsType> = ({height, width, className}) => {
  return (
    <img width={width} height={height} className={className} src={plus_icon} alt=""/>
  );
};

