import { ColorEnum } from "../../helpers/types"
import React from "react";

type FilterType = {
  fill?: ColorEnum,
  width?: string | number,
  height?: string | number
}
export const filter = ({fill = ColorEnum.black, height = '24', width = '24'}: FilterType) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 9.00001C18.3811 9.00175 17.7778 9.19489 17.2729 9.55295C16.7681 9.91101 16.3863 10.4165 16.18 11H3C2.73478 11 2.48043 11.1054 2.29289 11.2929C2.10536 11.4804 2 11.7348 2 12C2 12.2652 2.10536 12.5196 2.29289 12.7071C2.48043 12.8947 2.73478 13 3 13H16.18C16.3635 13.5189 16.6861 13.9773 17.1126 14.3251C17.5392 14.6729 18.0532 14.8966 18.5984 14.9718C19.1435 15.0471 19.6989 14.9709 20.2037 14.7516C20.7085 14.5323 21.1432 14.1784 21.4603 13.7286C21.7775 13.2788 21.9647 12.7504 22.0017 12.2013C22.0386 11.6522 21.9238 11.1035 21.6697 10.6153C21.4157 10.1271 21.0323 9.71813 20.5614 9.43324C20.0905 9.14834 19.5504 8.99847 19 9.00001ZM19 13C18.8022 13 18.6089 12.9414 18.4444 12.8315C18.28 12.7216 18.1518 12.5654 18.0761 12.3827C18.0004 12.2 17.9806 11.9989 18.0192 11.8049C18.0578 11.6109 18.153 11.4328 18.2929 11.2929C18.4327 11.1531 18.6109 11.0578 18.8049 11.0192C18.9989 10.9806 19.2 11.0004 19.3827 11.0761C19.5654 11.1518 19.7216 11.28 19.8315 11.4444C19.9414 11.6089 20 11.8022 20 12C20 12.2652 19.8946 12.5196 19.7071 12.7071C19.5196 12.8947 19.2652 13 19 13Z"
      fill={`var(${fill})`}/>
    <path
      d="M3 6.99991H4.18C4.3902 7.57907 4.77363 8.07945 5.27817 8.43307C5.7827 8.78669 6.38388 8.97639 7 8.97639C7.61612 8.97639 8.2173 8.78669 8.72184 8.43307C9.22637 8.07945 9.6098 7.57907 9.82 6.99991H21C21.2652 6.99991 21.5196 6.89456 21.7071 6.70702C21.8946 6.51948 22 6.26513 22 5.99991C22 5.7347 21.8946 5.48034 21.7071 5.29281C21.5196 5.10527 21.2652 4.99991 21 4.99991H9.82C9.6098 4.42076 9.22637 3.92037 8.72184 3.56676C8.2173 3.21314 7.61612 3.02344 7 3.02344C6.38388 3.02344 5.7827 3.21314 5.27817 3.56676C4.77363 3.92037 4.3902 4.42076 4.18 4.99991H3C2.73478 4.99991 2.48043 5.10527 2.29289 5.29281C2.10536 5.48034 2 5.7347 2 5.99991C2 6.26513 2.10536 6.51948 2.29289 6.70702C2.48043 6.89456 2.73478 6.99991 3 6.99991ZM7 4.99991C7.19778 4.99991 7.39112 5.05856 7.55557 5.16844C7.72002 5.27833 7.84819 5.4345 7.92388 5.61723C7.99957 5.79996 8.01937 6.00102 7.98079 6.195C7.9422 6.38899 7.84696 6.56717 7.70711 6.70702C7.56725 6.84687 7.38907 6.94211 7.19509 6.9807C7.00111 7.01928 6.80004 6.99948 6.61732 6.92379C6.43459 6.84811 6.27841 6.71993 6.16853 6.55548C6.05865 6.39104 6 6.1977 6 5.99991C6 5.7347 6.10536 5.48034 6.29289 5.29281C6.48043 5.10527 6.73478 4.99991 7 4.99991Z"
      fill={`var(${fill})`}/>
    <path
      d="M21 16.9999H13.82C13.6098 16.4208 13.2264 15.9204 12.7218 15.5668C12.2173 15.2131 11.6161 15.0234 11 15.0234C10.3839 15.0234 9.7827 15.2131 9.27816 15.5668C8.77363 15.9204 8.3902 16.4208 8.18 16.9999H3C2.73478 16.9999 2.48043 17.1053 2.29289 17.2928C2.10536 17.4803 2 17.7347 2 17.9999C2 18.2651 2.10536 18.5195 2.29289 18.707C2.48043 18.8946 2.73478 18.9999 3 18.9999H8.18C8.3902 19.5791 8.77363 20.0795 9.27816 20.4331C9.7827 20.7867 10.3839 20.9764 11 20.9764C11.6161 20.9764 12.2173 20.7867 12.7218 20.4331C13.2264 20.0795 13.6098 19.5791 13.82 18.9999H21C21.2652 18.9999 21.5196 18.8946 21.7071 18.707C21.8946 18.5195 22 18.2651 22 17.9999C22 17.7347 21.8946 17.4803 21.7071 17.2928C21.5196 17.1053 21.2652 16.9999 21 16.9999ZM11 18.9999C10.8022 18.9999 10.6089 18.9413 10.4444 18.8314C10.28 18.7215 10.1518 18.5653 10.0761 18.3826C10.0004 18.1999 9.98063 17.9988 10.0192 17.8048C10.0578 17.6108 10.153 17.4327 10.2929 17.2928C10.4327 17.153 10.6109 17.0577 10.8049 17.0191C10.9989 16.9805 11.2 17.0003 11.3827 17.076C11.5654 17.1517 11.7216 17.2799 11.8315 17.4443C11.9414 17.6088 12 17.8021 12 17.9999C12 18.2651 11.8946 18.5195 11.7071 18.707C11.5196 18.8946 11.2652 18.9999 11 18.9999Z"
      fill={`var(${fill})`}/>
  </svg>
)
