import {FC} from "react";
import {DefaultIconPropsType} from "../../../helpers/types";

export const FacebookSvg: FC<DefaultIconPropsType> = ({height, width, fill}) => (
  <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M25.0054 12.5764C25.0054 5.63064 19.4078 0 12.5027 0C5.59765 0 0 5.63064 0 12.5764C0 18.8536 4.57206 24.0565 10.5492 25V16.2118H7.37464V12.5764H10.5492V9.80566C10.5492 6.6537 12.4157 4.91265 15.2716 4.91265C16.6395 4.91265 18.0703 5.15829 18.0703 5.15829V8.25326H16.4938C14.9406 8.25326 14.4563 9.2227 14.4563 10.2173V12.5764H17.9238L17.3695 16.2118H14.4563V25C20.4333 24.0565 25.0054 18.8536 25.0054 12.5764Z"
          fill={`var(${fill})`}/>
  </svg>

)
