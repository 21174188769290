import {lazy} from "react";

const PageEvent = lazy(() => import(/* webpackChunkName:'PageEvent' */ '@pages/Event'));
const PageError = lazy(() => import(/* webpackChunkName:'PageError' */ '@pages/Error'));
const PageManageEvent = lazy(() => import(/* webpackChunkName: 'PageManageEvent' */ '@pages/ManageEvent'))
const PageLandingOrganizer = lazy(()=> import(/* webpackChunkName: 'PageLandingOrganizer' */ '@pages/Landings/LandingOrganizer'))
const PageSettings = lazy(()=> import(/* webpackChunkName: 'PageSettings' */ '@pages/Settings'))
const PageReservations = lazy(()=> import(/* webpackChunkName: 'PageReservations' */ '@pages/Reservations'))
const PageOrganizers = lazy(()=> import(/* webpackChunkName: 'PageOrganizers' */ '@pages/Organizers'))
const PageManageOrganizers = lazy(()=> import(/* webpackChunkName: 'PageManageOrganizers' */ '@pages/ManageOrganizers'))
export {
  PageEvent,
  PageManageEvent,
  PageError,
  PageLandingOrganizer,
  PageSettings,
  PageReservations,
  PageOrganizers,
  PageManageOrganizers
}