export const QRSvg = () => (
  <svg width="326" height="311" viewBox="0 0 326 311" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M36.3731 126.866C36.3852 127.28 36.0593 127.625 35.6453 127.637L31.8032 127.749C31.4415 127.76 31.1558 128.063 31.1663 128.425L31.2783 132.267C31.2904 132.681 30.9645 133.026 30.5505 133.038C30.1365 133.05 29.791 132.724 29.7789 132.31L29.667 128.468C29.6323 127.28 30.571 126.285 31.7595 126.25L35.6016 126.138C36.0156 126.126 36.3611 126.452 36.3731 126.866Z"
        fill="var(--light-gray)"/>
      <path
        d="M37.0285 149.356C37.0406 149.77 36.7147 150.116 36.3007 150.128L32.4586 150.24C31.2701 150.275 30.275 149.336 30.2404 148.147L30.1284 144.305C30.1164 143.891 30.4423 143.546 30.8563 143.534C31.2703 143.522 31.6157 143.848 31.6278 144.262L31.7398 148.104C31.7503 148.465 32.0531 148.751 32.4149 148.741L36.257 148.629C36.671 148.617 37.0164 148.942 37.0285 149.356Z"
        fill="var(--light-gray)"/>
      <path
        d="M54.1182 143.606L54.2302 147.448C54.2649 148.637 53.3261 149.632 52.1377 149.666L48.2956 149.778C47.8816 149.791 47.5361 149.465 47.524 149.051C47.512 148.637 47.8379 148.291 48.2519 148.279L52.094 148.167C52.4557 148.157 52.7414 147.854 52.7309 147.492L52.6189 143.65C52.6068 143.236 52.9327 142.89 53.3467 142.878C53.7607 142.866 54.1062 143.192 54.1182 143.606Z"
        fill="var(--light-gray)"/>
      <path
        d="M53.6568 127.769L53.7687 131.611C53.7808 132.025 53.4549 132.371 53.0409 132.383C52.6269 132.395 52.2814 132.069 52.2694 131.655L52.1574 127.813C52.1469 127.451 51.844 127.165 51.4823 127.176L47.6402 127.288C47.2262 127.3 46.8807 126.974 46.8687 126.56C46.8566 126.146 47.1825 125.801 47.5965 125.789L51.4386 125.677C52.6271 125.642 53.6221 126.581 53.6568 127.769Z"
        fill="var(--light-gray)"/>
      <path
        d="M41.2833 140.885L41.398 144.821C41.4327 146.009 40.494 147.004 39.3055 147.039L35.3697 147.154C34.1813 147.188 33.1862 146.25 33.1515 145.061L33.0368 141.125C33.0022 139.937 33.9409 138.942 35.1294 138.907L39.0652 138.793C40.2536 138.758 41.2487 139.697 41.2833 140.885ZM34.6509 145.018C34.6614 145.379 34.9643 145.665 35.326 145.654L39.2618 145.54C39.6235 145.529 39.9092 145.226 39.8987 144.865L39.784 140.929C39.7734 140.567 39.4706 140.281 39.1089 140.292L35.1731 140.407C34.8113 140.417 34.5257 140.72 34.5362 141.082L34.6509 145.018Z"
        fill="var(--accent)"/>
      <path
        d="M51.0294 140.601L51.1441 144.537C51.1788 145.725 50.24 146.72 49.0516 146.755L45.1158 146.87C43.9274 146.904 42.9323 145.966 42.8976 144.777L42.7829 140.841C42.7483 139.653 43.687 138.658 44.8754 138.623L48.8113 138.509C49.9997 138.474 50.9948 139.413 51.0294 140.601ZM44.397 144.734C44.4075 145.095 44.7104 145.381 45.0721 145.37L49.0079 145.256C49.3696 145.245 49.6553 144.942 49.6448 144.581L49.5301 140.645C49.5195 140.283 49.2167 139.997 48.855 140.008L44.9191 140.123C44.5574 140.133 44.2717 140.436 44.2823 140.798L44.397 144.734Z"
        fill="var(--accent)"/>
      <path
        d="M40.9996 131.139L41.1143 135.075C41.149 136.263 40.2103 137.259 39.0218 137.293L35.086 137.408C33.8976 137.443 32.9025 136.504 32.8678 135.315L32.7531 131.38C32.7185 130.191 33.6572 129.196 34.8457 129.161L38.7815 129.047C39.9699 129.012 40.965 129.951 40.9996 131.139ZM34.3672 135.272C34.3778 135.633 34.6806 135.919 35.0423 135.909L38.9781 135.794C39.3399 135.783 39.6255 135.48 39.615 135.119L39.5003 131.183C39.4897 130.821 39.1869 130.536 38.8252 130.546L34.8894 130.661C34.5276 130.671 34.242 130.974 34.2525 131.336L34.3672 135.272Z"
        fill="var(--accent)"/>
      <path
        d="M46.2198 130.049L46.2717 131.83L43.7416 131.903C43.0698 131.923 42.5393 132.485 42.5588 133.157L42.6408 135.969C42.6603 136.64 43.2228 137.171 43.8945 137.151L46.7058 137.069C47.3775 137.05 47.9081 136.487 47.8885 135.816L47.8148 133.285L49.5953 133.234C50.267 133.214 50.7976 132.652 50.778 131.98L50.7179 129.918C50.6984 129.246 50.1359 128.716 49.4642 128.735L47.4026 128.796C46.7309 128.815 46.2003 129.377 46.2198 130.049ZM44.1319 135.644L44.0664 133.395L46.3154 133.329L46.381 135.578L44.1319 135.644ZM49.2268 130.243L49.2705 131.742L47.7711 131.786L47.7274 130.287L49.2268 130.243Z"
        fill="var(--light-gray)"/>
      <path
        d="M38.3313 142.565L38.3532 143.315C38.3652 143.729 38.0394 144.075 37.6253 144.087L36.8757 144.109C36.4616 144.121 36.1162 143.795 36.1041 143.381L36.0823 142.631C36.0702 142.217 36.3961 141.872 36.8101 141.859L37.5598 141.838C37.9738 141.826 38.3193 142.151 38.3313 142.565Z"
        fill="var(--light-gray)"/>
      <path
        d="M38.0471 132.82L38.069 133.569C38.0811 133.983 37.7552 134.329 37.3412 134.341L36.5915 134.363C36.1775 134.375 35.832 134.049 35.8199 133.635L35.7981 132.885C35.786 132.471 36.1119 132.126 36.5259 132.114L37.2756 132.092C37.6896 132.08 38.0351 132.406 38.0471 132.82Z"
        fill="var(--light-gray)"/>
      <path
        d="M48.0769 142.282L48.0988 143.031C48.1108 143.445 47.785 143.791 47.3709 143.803L46.6213 143.825C46.2073 143.837 45.8618 143.511 45.8497 143.097L45.8279 142.347C45.8158 141.933 46.1417 141.588 46.5557 141.576L47.3054 141.554C47.7194 141.542 48.0649 141.867 48.0769 142.282Z"
        fill="var(--light-gray)"/>
    </g>
    <g clipPath="url(#clip1)">
      <path
        d="M296.074 204.76C295.91 205.141 295.469 205.316 295.089 205.152L291.559 203.63C291.227 203.486 290.84 203.64 290.697 203.972L289.174 207.502C289.01 207.882 288.569 208.057 288.189 207.893C287.808 207.729 287.633 207.288 287.797 206.908L289.319 203.378C289.79 202.287 291.061 201.781 292.153 202.252L295.683 203.775C296.063 203.939 296.238 204.38 296.074 204.76Z"
        fill="var(--accent)"/>
      <path
        d="M287.164 225.421C287 225.801 286.558 225.976 286.178 225.812L282.648 224.29C281.557 223.819 281.052 222.548 281.522 221.456L283.045 217.927C283.209 217.546 283.65 217.371 284.03 217.535C284.411 217.699 284.586 218.14 284.422 218.521L282.9 222.05C282.756 222.382 282.91 222.769 283.242 222.913L286.772 224.435C287.152 224.599 287.328 225.04 287.164 225.421Z"
        fill="var(--accent)"/>
      <path
        d="M305.082 227.431L303.56 230.961C303.089 232.053 301.818 232.558 300.726 232.087L297.196 230.565C296.816 230.401 296.641 229.959 296.805 229.579C296.969 229.199 297.41 229.023 297.791 229.187L301.32 230.71C301.652 230.853 302.039 230.699 302.182 230.367L303.705 226.837C303.869 226.457 304.31 226.282 304.69 226.446C305.071 226.61 305.246 227.051 305.082 227.431Z"
        fill="var(--accent)"/>
      <path
        d="M311.357 212.883L309.835 216.413C309.671 216.793 309.23 216.968 308.849 216.804C308.469 216.64 308.294 216.199 308.458 215.819L309.98 212.289C310.123 211.957 309.969 211.57 309.637 211.427L306.108 209.905C305.727 209.74 305.552 209.299 305.716 208.919C305.88 208.538 306.321 208.363 306.702 208.527L310.231 210.049C311.323 210.52 311.828 211.792 311.357 212.883Z"
        fill="var(--accent)"/>
      <path
        d="M294.6 219.541L293.041 223.157C292.57 224.248 291.299 224.754 290.207 224.283L286.591 222.723C285.5 222.252 284.994 220.981 285.465 219.889L287.025 216.274C287.496 215.182 288.767 214.677 289.859 215.148L293.474 216.707C294.566 217.178 295.071 218.449 294.6 219.541ZM286.843 220.483C286.699 220.816 286.853 221.203 287.185 221.346L290.801 222.905C291.133 223.049 291.52 222.895 291.663 222.563L293.223 218.947C293.366 218.615 293.212 218.228 292.88 218.085L289.265 216.525C288.932 216.382 288.545 216.536 288.402 216.868L286.843 220.483Z"
        fill="var(--light-gray)"/>
      <path
        d="M303.553 223.403L301.993 227.018C301.522 228.11 300.251 228.615 299.159 228.144L295.544 226.585C294.452 226.114 293.947 224.842 294.418 223.751L295.977 220.135C296.448 219.043 297.719 218.538 298.811 219.009L302.427 220.569C303.518 221.039 304.024 222.311 303.553 223.403ZM295.795 224.345C295.652 224.677 295.806 225.064 296.138 225.207L299.754 226.767C300.086 226.91 300.473 226.756 300.616 226.424L302.175 222.808C302.319 222.476 302.165 222.089 301.833 221.946L298.217 220.387C297.885 220.243 297.498 220.397 297.355 220.729L295.795 224.345Z"
        fill="var(--light-gray)"/>
      <path
        d="M298.461 210.589L296.902 214.204C296.431 215.296 295.16 215.801 294.068 215.33L290.453 213.771C289.361 213.3 288.856 212.029 289.327 210.937L290.886 207.321C291.357 206.23 292.628 205.724 293.72 206.195L297.335 207.755C298.427 208.226 298.932 209.497 298.461 210.589ZM290.704 211.531C290.561 211.863 290.714 212.25 291.047 212.393L294.662 213.953C294.994 214.096 295.381 213.942 295.525 213.61L297.084 209.995C297.227 209.662 297.074 209.275 296.741 209.132L293.126 207.573C292.794 207.429 292.407 207.583 292.263 207.915L290.704 211.531Z"
        fill="var(--light-gray)"/>
      <path
        d="M303.653 211.807L302.948 213.442L300.624 212.44C300.007 212.174 299.288 212.459 299.022 213.076L297.908 215.659C297.642 216.276 297.927 216.995 298.544 217.261L301.127 218.375C301.744 218.641 302.463 218.355 302.729 217.738L303.731 215.414L305.367 216.119C305.984 216.385 306.702 216.1 306.969 215.483L307.785 213.589C308.052 212.972 307.766 212.253 307.149 211.987L305.255 211.17C304.638 210.904 303.919 211.19 303.653 211.807ZM299.397 215.995L300.288 213.929L302.354 214.82L301.463 216.886L299.397 215.995ZM306.297 213.253L305.703 214.631L304.325 214.037L304.919 212.659L306.297 213.253Z"
        fill="var(--accent)"/>
      <path
        d="M291.214 219.816L290.917 220.505C290.753 220.886 290.312 221.061 289.932 220.897L289.243 220.6C288.863 220.436 288.687 219.994 288.851 219.614L289.148 218.925C289.312 218.545 289.754 218.37 290.134 218.534L290.823 218.831C291.203 218.995 291.378 219.436 291.214 219.816Z"
        fill="var(--accent)"/>
      <path
        d="M295.076 210.864L294.779 211.553C294.615 211.933 294.173 212.108 293.793 211.944L293.104 211.647C292.724 211.483 292.549 211.042 292.713 210.661L293.01 209.973C293.174 209.592 293.615 209.417 293.995 209.581L294.684 209.878C295.064 210.042 295.24 210.483 295.076 210.864Z"
        fill="var(--accent)"/>
      <path
        d="M300.167 223.678L299.87 224.367C299.706 224.747 299.265 224.922 298.884 224.758L298.196 224.461C297.815 224.297 297.64 223.856 297.804 223.476L298.101 222.787C298.265 222.407 298.706 222.231 299.087 222.395L299.775 222.692C300.156 222.856 300.331 223.298 300.167 223.678Z"
        fill="var(--accent)"/>
    </g>
    <g clipPath="url(#clip2)">
      <path
        d="M230.121 32.4387C229.812 32.7141 229.337 32.6865 229.062 32.3771L226.507 29.5058C226.266 29.2355 225.85 29.2113 225.58 29.4519L222.709 32.0074C222.399 32.2828 221.925 32.2551 221.65 31.9457C221.375 31.6363 221.402 31.1623 221.712 30.8869L224.583 28.3314C225.471 27.541 226.837 27.6205 227.627 28.5086L230.183 31.3799C230.458 31.6892 230.43 32.1633 230.121 32.4387Z"
        fill="var(--gray)"/>
      <path
        d="M213.313 47.3973C213.004 47.6727 212.53 47.6451 212.255 47.3357L209.699 44.4645C208.909 43.5763 208.988 42.2107 209.876 41.4202L212.748 38.8648C213.057 38.5894 213.531 38.617 213.806 38.9264C214.082 39.2358 214.054 39.7099 213.745 39.9853L210.874 42.5407C210.603 42.7813 210.579 43.1969 210.82 43.4672L213.375 46.3385C213.65 46.6479 213.623 47.1219 213.313 47.3973Z"
        fill="var(--gray)"/>
      <path
        d="M228.703 56.7927L225.832 59.3482C224.944 60.1386 223.578 60.0591 222.788 59.171L220.233 56.2997C219.957 55.9904 219.985 55.5163 220.294 55.2409C220.604 54.9655 221.078 54.9931 221.353 55.3025L223.908 58.1738C224.149 58.4441 224.565 58.4683 224.835 58.2277L227.706 55.6722C228.016 55.3968 228.49 55.4245 228.765 55.7339C229.04 56.0433 229.013 56.5173 228.703 56.7927Z"
        fill="var(--gray)"/>
      <path
        d="M240.539 46.2591L237.667 48.8146C237.358 49.09 236.884 49.0624 236.609 48.753C236.333 48.4436 236.361 47.9695 236.67 47.6941L239.541 45.1386C239.812 44.8981 239.836 44.4825 239.595 44.2121L237.04 41.3409C236.765 41.0315 236.792 40.5574 237.102 40.282C237.411 40.0066 237.885 40.0342 238.16 40.3436L240.716 43.2149C241.506 44.103 241.427 45.4687 240.539 46.2591Z"
        fill="var(--gray)"/>
      <path
        d="M222.538 45.2117L219.597 47.8295C218.708 48.6199 217.343 48.5405 216.552 47.6523L213.935 44.711C213.144 43.8229 213.224 42.4572 214.112 41.6668L217.053 39.049C217.941 38.2586 219.307 38.3381 220.097 39.2262L222.715 42.1675C223.505 43.0556 223.426 44.4213 222.538 45.2117ZM215.109 42.7873C214.839 43.0279 214.814 43.4435 215.055 43.7138L217.673 46.6551C217.913 46.9254 218.329 46.9496 218.599 46.709L221.541 44.0912C221.811 43.8507 221.835 43.435 221.594 43.1647L218.977 40.2234C218.736 39.9531 218.321 39.9289 218.05 40.1695L215.109 42.7873Z"
        fill="var(--light-gray)"/>
      <path
        d="M229.02 52.4949L226.079 55.1127C225.191 55.9031 223.825 55.8237 223.035 54.9355L220.417 51.9943C219.626 51.1061 219.706 49.7404 220.594 48.95L223.535 46.3322C224.424 45.5418 225.789 45.6213 226.58 46.5094L229.197 49.4507C229.988 50.3388 229.908 51.7045 229.02 52.4949ZM221.591 50.0705C221.321 50.3111 221.297 50.7267 221.537 50.997L224.155 53.9383C224.396 54.2086 224.811 54.2328 225.082 53.9922L228.023 51.3744C228.293 51.1339 228.318 50.7182 228.077 50.4479L225.459 47.5066C225.219 47.2363 224.803 47.2121 224.533 47.4527L221.591 50.0705Z"
        fill="var(--light-gray)"/>
      <path
        d="M229.821 38.7295L226.88 41.3473C225.992 42.1378 224.626 42.0583 223.835 41.1702L221.218 38.2289C220.427 37.3407 220.507 35.9751 221.395 35.1846L224.336 32.5669C225.224 31.7764 226.59 31.8559 227.38 32.744L229.998 35.6853C230.789 36.5734 230.709 37.9391 229.821 38.7295ZM222.392 36.3051C222.122 36.5457 222.098 36.9613 222.338 37.2316L224.956 40.1729C225.197 40.4432 225.612 40.4674 225.882 40.2268L228.824 37.6091C229.094 37.3685 229.118 36.9529 228.878 36.6825L226.26 33.7413C226.019 33.4709 225.604 33.4468 225.333 33.6873L222.392 36.3051Z"
        fill="var(--light-gray)"/>
      <path
        d="M234.012 42.028L232.681 43.2122L230.999 41.3214C230.552 40.8194 229.78 40.7745 229.278 41.2213L227.177 43.0911C226.675 43.5379 226.63 44.3098 227.077 44.8118L228.947 46.9127C229.393 47.4147 230.165 47.4596 230.667 47.0128L232.558 45.33L233.742 46.6606C234.189 47.1625 234.961 47.2075 235.463 46.7607L237.004 45.3895C237.506 44.9427 237.551 44.1708 237.104 43.6688L235.733 42.1281C235.286 41.6262 234.514 41.5812 234.012 42.028ZM228.384 44.0246L230.065 42.5287L231.561 44.2095L229.88 45.7053L228.384 44.0246ZM235.796 44.456L234.676 45.4532L233.679 44.3327L234.799 43.3355L235.796 44.456Z"
        fill="var(--gray)"/>
      <path
        d="M219.353 44.0302L218.793 44.5288C218.484 44.8042 218.009 44.7766 217.734 44.4672L217.235 43.9069C216.96 43.5975 216.988 43.1235 217.297 42.8481L217.857 42.3494C218.167 42.074 218.641 42.1017 218.916 42.4111L219.415 42.9713C219.69 43.2807 219.663 43.7548 219.353 44.0302Z"
        fill="var(--gray)"/>
      <path
        d="M226.636 37.5482L226.076 38.0469C225.766 38.3223 225.292 38.2946 225.017 37.9852L224.518 37.425C224.243 37.1156 224.27 36.6415 224.58 36.3661L225.14 35.8675C225.449 35.5921 225.924 35.6197 226.199 35.9291L226.698 36.4894C226.973 36.7988 226.945 37.2728 226.636 37.5482Z"
        fill="var(--gray)"/>
      <path
        d="M225.835 51.3134L225.275 51.812C224.965 52.0874 224.491 52.0598 224.216 51.7504L223.717 51.1901C223.442 50.8807 223.47 50.4067 223.779 50.1313L224.339 49.6326C224.649 49.3572 225.123 49.3849 225.398 49.6943L225.897 50.2545C226.172 50.5639 226.145 51.038 225.835 51.3134Z"
        fill="var(--gray)"/>
    </g>
    <g clipPath="url(#clip3)">
      <path
        d="M148.154 227.335C147.801 227.552 147.339 227.443 147.122 227.09L145.104 223.819C144.914 223.511 144.509 223.415 144.201 223.605L140.929 225.623C140.577 225.84 140.115 225.731 139.897 225.378C139.68 225.026 139.789 224.564 140.142 224.346L143.413 222.328C144.425 221.704 145.756 222.019 146.38 223.031L148.398 226.303C148.616 226.655 148.506 227.117 148.154 227.335Z"
        fill="var(--accent)"/>
      <path
        d="M129.004 239.148C128.652 239.365 128.19 239.256 127.972 238.903L125.954 235.632C125.33 234.62 125.646 233.289 126.657 232.665L129.929 230.647C130.281 230.429 130.743 230.539 130.961 230.891C131.178 231.244 131.069 231.706 130.716 231.923L127.445 233.941C127.137 234.131 127.041 234.536 127.231 234.844L129.249 238.116C129.466 238.468 129.357 238.93 129.004 239.148Z"
        fill="var(--accent)"/>
      <path
        d="M142.529 251.073L139.258 253.091C138.246 253.715 136.915 253.4 136.291 252.388L134.273 249.116C134.055 248.764 134.165 248.302 134.517 248.084C134.87 247.867 135.332 247.976 135.549 248.329L137.567 251.6C137.757 251.908 138.162 252.004 138.47 251.814L141.742 249.796C142.094 249.579 142.556 249.688 142.774 250.041C142.991 250.393 142.882 250.855 142.529 251.073Z"
        fill="var(--accent)"/>
      <path
        d="M156.014 242.754L152.742 244.772C152.39 244.99 151.928 244.88 151.71 244.528C151.493 244.175 151.602 243.713 151.955 243.496L155.226 241.478C155.534 241.288 155.63 240.883 155.44 240.575L153.422 237.303C153.205 236.951 153.314 236.489 153.667 236.271C154.019 236.054 154.481 236.163 154.699 236.516L156.717 239.787C157.341 240.799 157.026 242.13 156.014 242.754Z"
        fill="var(--accent)"/>
      <path
        d="M138.468 238.597L135.117 240.664C134.105 241.289 132.774 240.973 132.15 239.961L130.083 236.61C129.459 235.598 129.774 234.267 130.786 233.643L134.137 231.576C135.149 230.951 136.48 231.267 137.104 232.279L139.172 235.63C139.796 236.642 139.48 237.973 138.468 238.597ZM131.573 234.919C131.265 235.109 131.169 235.515 131.359 235.823L133.427 239.174C133.617 239.482 134.022 239.578 134.33 239.388L137.681 237.32C137.989 237.13 138.085 236.725 137.895 236.417L135.828 233.066C135.638 232.758 135.233 232.662 134.925 232.852L131.573 234.919Z"
        fill="var(--gray)"/>
      <path
        d="M143.587 246.895L140.236 248.962C139.224 249.587 137.893 249.271 137.269 248.259L135.201 244.908C134.577 243.896 134.893 242.565 135.905 241.941L139.256 239.874C140.268 239.249 141.599 239.565 142.223 240.577L144.29 243.928C144.914 244.94 144.599 246.271 143.587 246.895ZM136.692 243.218C136.384 243.408 136.288 243.813 136.478 244.121L138.545 247.472C138.735 247.78 139.14 247.876 139.448 247.686L142.8 245.619C143.108 245.429 143.204 245.023 143.014 244.715L140.946 241.364C140.756 241.056 140.351 240.96 140.043 241.15L136.692 243.218Z"
        fill="var(--gray)"/>
      <path
        d="M146.766 233.478L143.415 235.545C142.403 236.17 141.072 235.854 140.448 234.842L138.381 231.491C137.756 230.479 138.072 229.148 139.084 228.524L142.435 226.457C143.447 225.832 144.778 226.148 145.402 227.16L147.469 230.511C148.094 231.523 147.778 232.854 146.766 233.478ZM139.871 229.801C139.563 229.991 139.467 230.396 139.657 230.704L141.724 234.055C141.914 234.363 142.32 234.459 142.628 234.269L145.979 232.202C146.287 232.012 146.383 231.606 146.193 231.298L144.125 227.947C143.935 227.639 143.53 227.543 143.222 227.733L139.871 229.801Z"
        fill="var(--gray)"/>
      <path
        d="M150.321 237.454L148.805 238.389L147.476 236.235C147.123 235.663 146.371 235.485 145.799 235.838L143.405 237.314C142.833 237.667 142.655 238.419 143.008 238.991L144.484 241.385C144.837 241.957 145.589 242.135 146.161 241.782L148.316 240.454L149.251 241.97C149.604 242.541 150.356 242.72 150.928 242.367L152.683 241.284C153.255 240.931 153.434 240.179 153.081 239.607L151.998 237.852C151.645 237.28 150.893 237.101 150.321 237.454ZM144.432 238.443L146.347 237.262L147.528 239.177L145.613 240.358L144.432 238.443ZM151.656 240.155L150.38 240.943L149.592 239.666L150.869 238.878L151.656 240.155Z"
        fill="var(--accent)"/>
      <path
        d="M135.537 236.881L134.899 237.274C134.546 237.492 134.084 237.382 133.867 237.03L133.473 236.391C133.255 236.039 133.365 235.577 133.717 235.359L134.356 234.966C134.708 234.748 135.17 234.858 135.388 235.21L135.782 235.849C135.999 236.201 135.89 236.663 135.537 236.881Z"
        fill="var(--accent)"/>
      <path
        d="M143.835 231.762L143.197 232.155C142.844 232.373 142.382 232.263 142.164 231.911L141.771 231.273C141.553 230.92 141.663 230.458 142.015 230.241L142.654 229.847C143.006 229.629 143.468 229.739 143.686 230.091L144.079 230.73C144.297 231.082 144.187 231.544 143.835 231.762Z"
        fill="var(--accent)"/>
      <path
        d="M140.656 245.179L140.017 245.572C139.665 245.79 139.203 245.68 138.985 245.328L138.592 244.69C138.374 244.337 138.484 243.875 138.836 243.658L139.474 243.264C139.827 243.046 140.289 243.156 140.506 243.508L140.9 244.147C141.118 244.499 141.008 244.961 140.656 245.179Z"
        fill="var(--accent)"/>
    </g>
    <g opacity="0.2" clipPath="url(#clip4)">
      <path
        d="M204.243 91.7926C202.958 93.4174 200.6 93.693 198.975 92.4086L183.899 80.4891C182.48 79.367 180.412 79.6087 179.29 81.0281L167.371 96.1041C166.086 97.7288 163.728 98.0044 162.103 96.72C160.479 95.4356 160.203 93.0773 161.487 91.4526L173.407 76.3766C177.094 71.7133 183.887 70.9189 188.551 74.6058L203.627 86.5252C205.251 87.8096 205.527 90.1679 204.243 91.7926Z"
        fill="var(--light-gray)"/>
      <path
        d="M134.47 180.043C133.186 181.667 130.827 181.943 129.203 180.659L114.127 168.739C109.463 165.052 108.669 158.259 112.356 153.595L124.275 138.519C125.56 136.895 127.918 136.619 129.543 137.903C131.167 139.188 131.443 141.546 130.159 143.171L118.239 158.247C117.117 159.666 117.359 161.734 118.778 162.856L133.854 174.775C135.479 176.06 135.755 178.418 134.47 180.043Z"
        fill="var(--light-gray)"/>
      <path
        d="M218.409 212.943L206.489 228.019C202.802 232.683 196.009 233.477 191.345 229.79L176.269 217.871C174.645 216.586 174.369 214.228 175.653 212.603C176.938 210.979 179.296 210.703 180.921 211.987L195.997 223.907C197.416 225.029 199.484 224.787 200.606 223.368L212.525 208.292C213.81 206.667 216.168 206.391 217.793 207.676C219.417 208.96 219.693 211.319 218.409 212.943Z"
        fill="var(--light-gray)"/>
      <path
        d="M267.54 150.8L255.621 165.877C254.336 167.501 251.978 167.777 250.353 166.492C248.729 165.208 248.453 162.85 249.737 161.225L261.657 146.149C262.779 144.73 262.537 142.662 261.118 141.54L246.042 129.621C244.417 128.336 244.141 125.978 245.426 124.353C246.71 122.728 249.069 122.453 250.693 123.737L265.769 135.657C270.433 139.344 271.227 146.137 267.54 150.8Z"
        fill="var(--light-gray)"/>
      <path
        d="M177.994 161.271L165.784 176.715C162.097 181.378 155.304 182.173 150.64 178.486L135.197 166.276C130.533 162.589 129.739 155.795 133.426 151.132L145.636 135.688C149.323 131.025 156.116 130.23 160.78 133.917L176.224 146.127C180.887 149.814 181.681 156.608 177.994 161.271ZM139.309 155.783C138.187 157.203 138.429 159.27 139.848 160.392L155.292 172.602C156.711 173.725 158.779 173.483 159.901 172.064L172.111 156.62C173.233 155.2 172.991 153.133 171.572 152.011L156.128 139.801C154.709 138.678 152.641 138.92 151.519 140.34L139.309 155.783Z"
        fill="var(--accent)"/>
      <path
        d="M216.236 191.506L204.026 206.95C200.339 211.613 193.545 212.407 188.882 208.72L173.438 196.51C168.775 192.823 167.981 186.03 171.667 181.366L183.878 165.923C187.565 161.259 194.358 160.465 199.021 164.152L214.465 176.362C219.128 180.049 219.923 186.843 216.236 191.506ZM177.551 186.018C176.429 187.437 176.67 189.505 178.09 190.627L193.533 202.837C194.953 203.959 197.02 203.717 198.142 202.298L210.353 186.854C211.475 185.435 211.233 183.368 209.814 182.245L194.37 170.035C192.951 168.913 190.883 169.155 189.761 170.574L177.551 186.018Z"
        fill="var(--accent)"/>
      <path
        d="M208.229 123.03L196.018 138.473C192.332 143.137 185.538 143.931 180.875 140.244L165.431 128.034C160.768 124.347 159.973 117.553 163.66 112.89L175.87 97.4464C179.557 92.7831 186.351 91.9887 191.014 95.6756L206.458 107.886C211.121 111.573 211.916 118.366 208.229 123.03ZM169.543 117.542C168.421 118.961 168.663 121.028 170.082 122.151L185.526 134.361C186.946 135.483 189.013 135.241 190.135 133.822L202.345 118.378C203.467 116.959 203.226 114.891 201.806 113.769L186.363 101.559C184.943 100.437 182.876 100.678 181.754 102.098L169.543 117.542Z"
        fill="var(--accent)"/>
      <path
        d="M231.728 135.633L226.204 142.619L216.276 134.77C213.64 132.686 209.8 133.135 207.716 135.771L198.995 146.802C196.911 149.438 197.36 153.277 199.996 155.361L211.027 164.083C213.663 166.167 217.502 165.718 219.586 163.082L227.436 153.154L234.422 158.678C237.058 160.761 240.898 160.313 242.982 157.677L249.378 149.587C251.462 146.951 251.012 143.111 248.377 141.028L240.287 134.632C237.651 132.548 233.812 132.997 231.728 135.633ZM205.75 150.35L212.727 141.525L221.552 148.502L214.575 157.327L205.75 150.35ZM242.622 146.039L237.971 151.922L232.087 147.271L236.739 141.387L242.622 146.039Z"
        fill="var(--light-gray)"/>
      <path
        d="M161.285 158.219L158.959 161.161C157.675 162.786 155.316 163.061 153.692 161.777L150.75 159.451C149.126 158.167 148.85 155.808 150.134 154.184L152.46 151.242C153.745 149.617 156.103 149.342 157.727 150.626L160.669 152.952C162.294 154.236 162.57 156.594 161.285 158.219Z"
        fill="var(--light-gray)"/>
      <path
        d="M191.52 119.978L189.194 122.919C187.91 124.544 185.551 124.82 183.927 123.535L180.985 121.21C179.361 119.925 179.085 117.567 180.369 115.942L182.695 113C183.979 111.376 186.338 111.1 187.962 112.385L190.904 114.71C192.529 115.995 192.804 118.353 191.52 119.978Z"
        fill="var(--light-gray)"/>
      <path
        d="M199.527 188.454L197.201 191.396C195.917 193.02 193.558 193.296 191.934 192.012L188.992 189.686C187.367 188.401 187.091 186.043 188.376 184.418L190.702 181.477C191.986 179.852 194.345 179.576 195.969 180.861L198.911 183.187C200.535 184.471 200.811 186.829 199.527 188.454Z"
        fill="var(--light-gray)"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" transform="translate(30.3032 150.303) rotate(-91.6694)"/>
      </clipPath>
      <clipPath id="clip1">
        <rect width="24" height="24" fill="white" transform="translate(280.668 223.436) rotate(-66.6694)"/>
      </clipPath>
      <clipPath id="clip2">
        <rect width="24" height="24" fill="white" transform="translate(208.266 42.8538) rotate(-41.6694)"/>
      </clipPath>
      <clipPath id="clip3">
        <rect width="24" height="24" fill="white" transform="translate(124.822 233.797) rotate(-31.6694)"/>
      </clipPath>
      <clipPath id="clip4">
        <rect width="120" height="120" fill="white" transform="translate(105.669 162.053) rotate(-51.6694)"/>
      </clipPath>
    </defs>
  </svg>

)
