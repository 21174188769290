import React, {FC, useState} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import Button from "@components/Button";
import {BtnAuthTypeInner} from '../modules';
import {TextError} from "@components/Input";
import {SubmitHandler, useForm} from "react-hook-form";
import {PrelaunchLocationSubscribeMutationVariables, usePrelaunchLocationSubscribeMutation} from "@api/generated";
import {FormI} from './LocationOffer.interface';
import {useTranslation} from "react-i18next";
import {formatLang} from 'helpers/language/formatLang';
import {TextField} from '@components/FormControl/TextField';
import {TextFieldPhone} from "@components/UI/ReactForm/TextField/controlled/TextFieldPhone";
import {TextArea} from "@components/UI/ReactForm/TextArea";


const cx = classNames.bind(s);

type LocationOfferProps = {}


export const LocationOffer: FC<LocationOfferProps> = (): JSX.Element => {
  const {handleSubmit, register, control, formState: {errors}} = useForm<FormI>()
  const [_, {language}] = useTranslation()
  const [fetchError, setFetchError] = useState(null)
  const onSubmit: SubmitHandler<FormI> = async (data) => {
    try {
      const variables: PrelaunchLocationSubscribeMutationVariables = {
        email: data?.email,
        phone: data?.phone,
        locationName: data?.locationName ?? '',
        comments: data?.comment,
        language: formatLang(language)
      }
      const result = await subscribe({variables})
      setFetchError(null);
    } catch (e) {
      setFetchError(e.message)
    }
  };

  const [subscribe, {data, loading, error}] = usePrelaunchLocationSubscribeMutation()
  return (
    <div>
      <p>Enter the E-mail address that you used during registration:</p>
      <form action='modals/ModalSign/LocationOffer/index' onSubmit={handleSubmit(onSubmit)}>
        <TextField
          placeholder="Name or location"
        />
        <TextField
          placeholder={'email'}
        />
        <TextFieldPhone
          placeholder="Phone"
          control={control} name={'phone'}/>
        <TextArea placeholder="Additional comments"/>
      </form>
      <Button loading={loading}
              onClick={handleSubmit(onSubmit)}
              gap={{top: 32}}
              widthMax>
        <BtnAuthTypeInner
          text={'Subscribe'}/>
      </Button>

      {fetchError && <TextError error={fetchError} defaultTextError={'response from api rejected'}/>}
    </div>
  );
};

