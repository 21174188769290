import {FC} from "react";
import {ColorEnum, DefaultIconPropsType} from "../../helpers/types";

export const CopyIcon: FC<Omit<DefaultIconPropsType, 'orientation'>>
  = ({height = 22, width = 22, fill=ColorEnum.gray,className}) => {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.41667 5.49967V2.74967C6.41667 2.50656 6.51324 2.2734 6.68515 2.10149C6.85706 1.92958 7.09022 1.83301 7.33333 1.83301H18.3333C18.5764 1.83301 18.8096 1.92958 18.9815 2.10149C19.1534 2.2734 19.25 2.50656 19.25 2.74967V15.583C19.25 15.8261 19.1534 16.0593 18.9815 16.2312C18.8096 16.4031 18.5764 16.4997 18.3333 16.4997H15.5833V19.2497C15.5833 19.7557 15.1708 20.1663 14.6603 20.1663H3.67308C3.5522 20.1671 3.43236 20.1439 3.32046 20.0982C3.20856 20.0524 3.1068 19.985 3.02102 19.8999C2.93524 19.8147 2.86714 19.7134 2.82063 19.6018C2.77412 19.4902 2.75012 19.3706 2.75 19.2497L2.75275 6.41634C2.75275 5.91034 3.16525 5.49967 3.67583 5.49967H6.41667ZM4.58608 7.33301L4.58333 18.333H13.75V7.33301H4.58608ZM8.25 5.49967H15.5833V14.6663H17.4167V3.66634H8.25V5.49967Z"
        fill={`var(${fill})`}/>
    </svg>

  )
}