import {ColorEnum, DefaultIconPropsType} from "../../helpers/types";
import {FC} from "react";

export const PlusIcon: FC<DefaultIconPropsType & { className?:string}> = ({fill =ColorEnum.black, width = 12, height = 13,className }) => {
  return (
    <svg  className={className} width={width} height={height} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.087 5.44545H12V7.60303H7.087V12.5H4.90072V7.60303H0V5.44545H4.90072V0.5H7.087V5.44545Z"
            fill={`var(${fill})`}/>
    </svg>

  )
}