import React, {FC} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import Select from '@components/Select';
import {useForm} from "react-hook-form";
import {Button} from "@components/modules";
import { CurrencyList } from 'helpers/types';

const cx = classNames.bind(s);

type SelectEnvironmentPropsType = {}
export const languageOption = [{label: '🇪🇸  Spain', value: 'Spain', icon: '🇪🇸'}, {
  label: '🇧🇷  Brazil',
  value: 'Brazil',
  icon: '🇧🇷'
}];

export const SelectEnvironment: FC<SelectEnvironmentPropsType> = (): JSX.Element => {

  const {control, handleSubmit} = useForm({
    defaultValues: {
      language: languageOption[0],
      currency: CurrencyList[0]
    }
  })
  const onSubmit = (data: any) => console.log(data)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Select control={control as any}
              label={'Language'}
              id='language'
              options={languageOption as any}
      />
      <Select control={control as any}
              label={'Currency'}

              id='currency'
              options={CurrencyList as any}
      />
      <Button type={"submit"} widthMax gap={{top: 25}}> Search</Button>
    </form>
  );
};

