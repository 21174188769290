import React, {FC, memo} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import {ContainerBgIconProps} from './index.props';

const cx = classNames.bind(s);

const ContainerBgIcon: FC<ContainerBgIconProps> = ({classNames = '', component, icons}): JSX.Element => {
    return (
      <div className={`${cx('Component')}${classNames ? ' ' + classNames : ''}`}>
        <div className={cx('ComponentItem')}>{component}</div>
        <div className={cx('ComponentIcons')}> {icons?.map((el, i) => (
          <div key={i} style={{
            position: 'absolute',
            top: el.axis.top,
            left: el.axis.left,
            right: el.axis.right,
            bottom: el.axis.bottom,
            transform: el.transform ?? 'none'
          }}>{el.icon}</div>
        ))}
        </div>
      </div>
    );
  }
;

export default memo(ContainerBgIcon);
