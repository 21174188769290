import { DefaultIconPropsType } from "helpers/types";
import {FC} from "react";

export const AppleIcon: FC<Omit<DefaultIconPropsType, 'orientation'>>
  = ({className}) => (
  <svg className={className} width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4736 5.76923C11.602 5.76923 13.0165 5.00636 13.8589 3.98919C14.6217 3.06739 15.178 1.78004 15.178 0.492689C15.178 0.317864 15.1621 0.143039 15.1303 0C13.8748 0.0476796 12.3649 0.84234 11.459 1.90718C10.7438 2.71774 10.0922 3.98919 10.0922 5.29243C10.0922 5.48315 10.124 5.67387 10.1399 5.73744C10.2193 5.75334 10.3465 5.76923 10.4736 5.76923ZM6.50032 25C8.04196 25 8.72536 23.9669 10.6484 23.9669C12.6033 23.9669 13.0324 24.9682 14.7489 24.9682C16.4336 24.9682 17.562 23.4107 18.6268 21.8849C19.8188 20.1367 20.3115 18.4202 20.3433 18.3407C20.232 18.309 17.0057 16.9898 17.0057 13.2867C17.0057 10.0763 19.5486 8.63001 19.6917 8.51875C18.007 6.10299 15.4482 6.03941 14.7489 6.03941C12.8576 6.03941 11.316 7.18372 10.3465 7.18372C9.29752 7.18372 7.91481 6.10299 6.27781 6.10299C3.16275 6.10299 0 8.67768 0 13.541C0 16.5607 1.1761 19.7552 2.62238 21.8214C3.86205 23.5696 4.94278 25 6.50032 25Z"
      fill="white"/>
  </svg>


)
