import {SetStateAction, useRef} from "react";
import { useEffect } from "react";
import { Ref } from "react";
import { Dispatch } from "react";
import {useState} from "react";

type UseDropdown = {
  onClose?(): void,
  isOpen?: boolean
}

export function useDropdown({onClose, isOpen = false}: UseDropdown = {}): {
  open: boolean, toggle: any, ref: Ref<any>, setOpen: Dispatch<SetStateAction<boolean>>,
} {
  const [open, setOpen] = useState(isOpen)
  const ref = useRef<HTMLDivElement>(null)
  // @todo check reqirement of using state
  const toggle = () => setOpen(!open)

  useEffect(() => {
    const mousedownListener = (e: MouseEvent) => {

      if (ref.current && e.target && !ref.current.contains(e.target as Node)) {
        document.removeEventListener('mousedown', mousedownListener)
        setOpen(false)
      }
    }

    const keydownListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setOpen(false)
        e.preventDefault()
        e.stopPropagation()
      }
    }

    if (open) {
      document.addEventListener('mousedown', mousedownListener)
      document.addEventListener('keydown', keydownListener)
    } else {
      document.removeEventListener('mousedown', mousedownListener)
      document.removeEventListener('keydown', keydownListener)
      onClose?.()
    }

    return () => {
      document.removeEventListener('keydown', keydownListener)
      document.removeEventListener('mousedown', mousedownListener)
    }
  }, [open])

  return {open, toggle, ref,  setOpen, }
}