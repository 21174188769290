import {Control, Controller, FieldError} from "react-hook-form";
import {TextField} from "../../index";
import React, {InputHTMLAttributes} from "react";
import {TextFieldPropsType} from "../../index.props";
import {FieldValues} from "react-hook-form/dist/types";
import {RegisterOptions} from "react-hook-form/dist/types/validator";
import {parsePhoneNumberFromString} from "libphonenumber-js";

export const normalizePhoneNumber = (value: string) => {
  const phoneNumber = parsePhoneNumberFromString(value)
  debugger
  if (!phoneNumber) {
    return value
  }
  return (
    phoneNumber.formatInternational()
  );
};

export type TextFieldPhoneI<TFieldValues, TName> = {
  rules?: Omit<RegisterOptions<TFieldValues, any>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>
  control: Control<TFieldValues>,
  name: TName,
} & TextFieldPropsType
  & InputHTMLAttributes<HTMLInputElement>

export const TextFieldPhone =
  <TFieldValues extends FieldValues,
    TName extends import("react-hook-form/dist/types")
      .Path<TFieldValues>>
  ({
     control,
     name,
     rules = {},
     ...inputProps
   }: TextFieldPhoneI<TFieldValues, TName>) => {
    return (
      <Controller
        control={control}
        name={name as any}
        rules={{
          ...rules,
          minLength: {value: 9, message: 'Min length 9 symbols'}
        }}
        render={({
                   field: {onChange, onBlur, value, ...fieldProps},
                   formState: {errors}
                 }) => {
          return (
            <TextField
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const val = e?.target?.value?.replace(/\D/, '').trim();
                if (val.replace(' ', '').length > 15) return;
                onChange(val === '' ? val : normalizePhoneNumber('+' + val));
              }}
              view={'border-gray'}
              value={value ?? ''}
              error={errors[name] as FieldError}
              {...inputProps}
              {...fieldProps}
            />
          )
        }}
      />
    )
  }