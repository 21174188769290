import {Currency} from "@api/generated";

export type Maybe<T> = null | T

export type GapType = {
  top?: number | string,
  bottom?: number | string,
  left?: number | string,
  right?: number | string,
}

export type DefaultIconPropsType = {
  orientation?: Orientation,
  fill?: ColorEnum | string,
  width?: number | string,
  height?: number | string,
  className?:string
}
export enum Orientation {
  Top,
  Right,
  Bottom,
  Left

}

export enum ColorEnum {
  black = '--black',
  accent = '--accent',
  white = '--white',
  light_gray = '--light-gray',
  lighter_gray = '--lighter-gray',
  gray = '--gray',
  dark_gray = '--dark-gray',
  transparent = 'transparent',
  red = '--red'
}

export const CurrencyList: Array<{ label: Currency, value: Currency }> = [
  {
    value: Currency.Pln,
    label: Currency.Pln,
  },
  {
    value: Currency.Gbp,
    label: Currency.Gbp,
  },
  {
    value: Currency.Eur,
    label: Currency.Eur,
  },
  {
    value: Currency.Rub,
    label: Currency.Rub,
  },
  {
    value: Currency.Eur,
    label: Currency.Eur
  }

]
export enum FormMessage {
  Required = 'Field is required',
  NotValid = 'Field is not valid',
  MaxLength = 'MaxLength',
  MinLength = 'MinLength'
}
