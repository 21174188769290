import React, {FC} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import {Button} from '@components/modules';
import {OfferPropsType} from './index.props';
import { ColorEnum } from "../../../../helpers/types"

const cx = classNames.bind(s);


const Offer: FC<OfferPropsType> = ({title, onClick, bgImg, icon, buttonText, children}): JSX.Element => {
  const style = {
    backgroundImage: `url(${bgImg})`
  }
  return (
    <div className={cx('Offer')} style={style}>
      <div className={cx('OfferIcon')}>
        {icon}
      </div>
      <h3 dangerouslySetInnerHTML={{__html: title}}/>
      <Button textColor={ColorEnum.white} minWidth={160}
              onClick={onClick}
              outline>{buttonText}
        {children}
      </Button>
    </div>
  );
};

export default Offer;
