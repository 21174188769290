import {useSelector} from "react-redux";
import {ApplicationState} from "@store/store";
import React from "react";
import classNames from "classnames/bind";
import s from './index.module.scss';
import {FacebookSvg} from "@icons/apps/facebook";
import {AppleIcon} from "@icons/apps/apple";
import {ModalType} from "../../types/modal-types";
import {Button, Typography} from "@components/modules";
import {useTranslation} from "react-i18next";
import {IconOrientation} from "@components/Button/index.props";
import {FontWeight, TextColor, TypographyVariant} from "@components/UI/Typography/index.props";

const cx = classNames.bind(s);

export const AuthWithApp = (): JSX.Element => {
  const {modalType} = useSelector((state: ApplicationState) => state.landing)
  return (modalType === ModalType.Registration || modalType === ModalType.Login) ? <>
    <div className={cx('App')}>
      <AppleID/>
      <Facebook/>
    </div>
  </> : <span/>;
}
const Facebook = () => {
  const [t] = useTranslation()
  return (
  <Button
    widthMax={true}
    classNames={cx('Facebook')}
    icon={<FacebookSvg/>}
    iconOrientation={IconOrientation.Left}
  >
    <Typography
      tag={TypographyVariant.p14}
      color={TextColor.White}
      weight={FontWeight.Medium}
    >
      {t('Sign in with Facebook')}
    </Typography>
  </Button>
  )
}
const AppleID = () => {
  const [t] = useTranslation()
  return (
    <Button
      widthMax={true}
      classNames={cx('Apple')}
      icon={<AppleIcon/>}
      iconOrientation={IconOrientation.Left}
    >
      <Typography
        tag={TypographyVariant.p14}
        color={TextColor.White}
        weight={FontWeight.Medium}
      >
        {t('Sign in with Apple')}
      </Typography>
    </Button>
  )
}
