import React from "react";

export const crown = () => (
  <svg width="80" height="74" viewBox="0 0 80 74" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M57.0667 60.3334H22.9334C21.7167 60.3334 20.68 59.4567 20.47 58.2634L16.7034 36.5967C16.5467 35.6967 16.89 34.79 17.6 34.22C18.3067 33.6467 19.2667 33.5134 20.1134 33.8534L30.33 38.0267L37.83 24.9234C38.7234 23.3667 41.2767 23.3667 42.17 24.9234L49.67 38.0267L59.8867 33.8534C60.7334 33.5134 61.69 33.6467 62.4 34.22C63.1067 34.79 63.45 35.6967 63.2967 36.5967L59.53 58.2634C59.32 59.4567 58.28 60.3334 57.0667 60.3334ZM25.0367 55.3334H54.9634L57.5967 40.19L49.5334 43.48C48.37 43.9567 47.04 43.4934 46.4167 42.41L40 31.2L33.5834 42.41C32.96 43.4934 31.6334 43.9567 30.4667 43.48L22.4034 40.19L25.0367 55.3334Z"
      fill="var(--accent)"/>
    <path
      d="M70.8333 73.6666H9.16667C4.11 73.6666 0 69.5566 0 64.5V9.49998C0 4.44331 4.11 0.333313 9.16667 0.333313H70.8333C75.89 0.333313 80 4.44331 80 9.49998V64.5C80 69.5566 75.89 73.6666 70.8333 73.6666ZM9.16667 5.33331C6.87 5.33331 5 7.20331 5 9.49998V64.5C5 66.7966 6.87 68.6666 9.16667 68.6666H70.8333C73.13 68.6666 75 66.7966 75 64.5V9.49998C75 7.20331 73.13 5.33331 70.8333 5.33331H9.16667Z"
      fill="white"/>
    <path
      d="M77.5 17H2.5C1.12 17 0 15.88 0 14.5C0 13.12 1.12 12 2.5 12H77.5C78.88 12 80 13.12 80 14.5C80 15.88 78.88 17 77.5 17Z"
      fill="var(--accent)"/>
  </svg>
)
