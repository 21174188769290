import React, {memo, FC} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import {MastercardSvg} from "@icons/payments/mastercard";
import {VisaSvg} from "@icons/payments/visa";
import {FondySvg} from '@icons/payments/fondy';
import {useLocation} from "react-router-dom";
import {Divider} from "@components/Divider";
import Logo from "@components/Header/Logo";
import {Typography} from "@components/modules";
import {useMedia, useMediaWithoutListener} from "../../helpers/hooks/useMedia";

const cx = classNames.bind(s);

type FooterProps = {}


function Resources() {
  return (
    <div className={cx('Resources')}>
      <div className={cx('List')}>
        <nav>
          <h3>For lorem ipsum</h3>
          <a>About us</a>
          <a>Events</a>
        </nav>
        <nav>
          <h3>For organizer</h3>
          <a>Become an organizer</a>
          <a>Pricing</a>
        </nav>
        <nav>
          <h3>Lorem ipsum</h3>
          <a>Terms of Use</a>
          <a>Privacy Policy</a>
        </nav>
      </div>
      <nav className={cx('Contacts')}>
        <h3>Contacts</h3>
        <a>email@gmail.com</a>
        <a>+ 1 234 567 890</a>
        <a>+ 1 234 567 890</a>
      </nav>
    </div>
  );
}

const Footer: FC<FooterProps> = (): JSX.Element => {
  const {landscape} = useMediaWithoutListener()
  const {pathname} = useLocation()
  const isInverse = /event/.test(pathname)
  return (
    <footer className={cx('Component', 'container', {'ComponentInverse': isInverse})}>
      <Resources/>
      <Divider gap={{y: landscape ? 20: 38}}/>
      <div className={cx('LogoWrap')}>
        <Logo/>
        <Typography>© satchel, 2021</Typography>
      </div>
    </footer>
  );
};

export default memo(Footer);
