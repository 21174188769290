import { ColorEnum } from "../../helpers/types";
import {FC} from "react";

type ChevronTypeProps = {
  orientation?: ChevronOrientation
  fill?: ColorEnum,
  width?: string | number,
  height?: string | number
}

export enum ChevronOrientation {
  Bottom = 'bottom',
  Right = 'right',
  Left = 'left',
  Top = 'top'

}

export const chevron: FC<ChevronTypeProps> = ({
                                                fill = ColorEnum.light_gray,
                                                orientation = ChevronOrientation.Bottom,
                                                height = '5',
                                                width = '8'
                                              }) => {
  const style = {
    transform: orientation === ChevronOrientation.Right ?
      'rotate(-90deg)' : orientation === ChevronOrientation.Left ?
        'rotate(90deg)' : orientation === ChevronOrientation.Top ?
          'scale(1, -1)' : 'rotate(0deg)'
  }
  return (
    <svg style={style} width={width} height={height} viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.00012 4.33329C3.91238 4.3338 3.82541 4.31698 3.74418 4.28381C3.66296 4.25063 3.58908 4.20175 3.52679 4.13996L0.860124 1.47329C0.797965 1.41113 0.748658 1.33734 0.715017 1.25613C0.681377 1.17491 0.664063 1.08787 0.664062 0.99996C0.664062 0.912054 0.681377 0.825009 0.715017 0.743794C0.748658 0.662579 0.797965 0.588786 0.860124 0.526627C0.922283 0.464468 0.996076 0.415161 1.07729 0.381521C1.15851 0.34788 1.24555 0.330566 1.33346 0.330566C1.42136 0.330566 1.50841 0.34788 1.58962 0.381521C1.67084 0.415161 1.74463 0.464468 1.80679 0.526627L4.00012 2.73329L6.20012 0.613294C6.26145 0.545101 6.33619 0.490295 6.41966 0.452304C6.50314 0.414313 6.59355 0.393955 6.68526 0.392502C6.77696 0.39105 6.86797 0.408533 6.95261 0.44386C7.03725 0.479188 7.11369 0.531599 7.17714 0.597815C7.2406 0.664031 7.28971 0.742629 7.32141 0.828691C7.3531 0.914754 7.3667 1.00643 7.36135 1.09799C7.356 1.18955 7.33181 1.27901 7.2903 1.3608C7.2488 1.44258 7.19086 1.51492 7.12012 1.57329L4.45346 4.14663C4.33154 4.26417 4.16946 4.33091 4.00012 4.33329Z"
        fill={`var(${fill})`}/>
    </svg>
  )
}
