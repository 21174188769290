import React from 'react';
import s from './index.module.scss';
import classNames from 'classnames/bind'
import {Typography} from "@components/UI/Typography";
import {FontWeight, TypographyVariant} from "@components/UI/Typography/index.props";
import {SelectField} from "@components/UI/SelectField";
import {SocialController} from "@pages/ManageEvent/Steps/Speakers/SocialController";
import {Button, TextField} from "@components/modules";
import {ColorEnum} from "@helpers/types";
import {IconOrientation} from "@components/Button/index.props";
import {useTranslation} from "react-i18next";
import {Control, useFieldArray, UseFormRegister} from "react-hook-form";
import {PlusIcon} from "@icons/plus";
import {UseFieldArrayRemove, UseFieldArrayUpdate} from "react-hook-form/dist/types/fieldArray";
import {MediaI} from '@pages/ManageEvent/Steps/Speakers/index.props';
import {FieldValues} from "react-hook-form/dist/types";
import {useMedia} from "@helpers/hooks/useMedia";

const cx = classNames.bind(s)

type MediasPropsType<TFieldValues, TName> = {
  title?: string
  control: Control<TFieldValues, any>
  register: UseFormRegister<TFieldValues>;
  classNameLink?: string,
  name: TName
}

export function Medias<TFieldValues extends FieldValues,
  TName extends import("react-hook-form/dist/types")
    .Path<TFieldValues>>({
                           title,
                           control,
                           register,
                           classNameLink,
                           name
                         }: MediasPropsType<TFieldValues, TName>) {
  const [t] = useTranslation()
  const {fields, append, remove, update} = useFieldArray({
    control: control,
    name: (name as any)
  })
  return (
    <div className={cx('Component')}>
      <Typography
        className={'mt-sm-24px mt-48px mb-24px mb-sm-16px'}
        tag={TypographyVariant.h20}
        weight={FontWeight.Bold}
      >
        {t(title || 'Social medias')}
      </Typography>
      {fields.map((media, index) =>
        <Media
          key={media.id}
          classNameLink={classNameLink}
          register={register}
          update={update}
          remove={remove}
          index={index}
          name={name}
          {...media as any}/>
      )}
      <Button
        classNames={cx( 'Btn')}
        outline
        textColor={ColorEnum.black}
        iconOrientation={IconOrientation.Left}
        icon={<PlusIcon/>}
        outlineColor={ColorEnum.light_gray}
        onClick={() => {
          append({title: 'Twitter', link: ''} as any)
        }}
      >
        <Typography tag={TypographyVariant.p18} weight={FontWeight.Medium}>
          Add media
        </Typography>
      </Button>
    </div>
  );
}
;

function Media<TFieldValues extends FieldValues,
  TName extends import("react-hook-form/dist/types")
    .Path<TFieldValues>>
({link, title, remove, index, update, register, classNameLink, name}: MediaI & {
  remove: UseFieldArrayRemove,
  index: number,
  update: UseFieldArrayUpdate<TFieldValues, any>,
  register: UseFormRegister<TFieldValues>,
  classNameLink?: string,
  name: TName,
}) {
  const {phone}=useMedia()
  return (
    <div className={cx('Row')}>
      <SelectField
        isLabelGap
        renderContent={
          <SocialController
            onChange={(title) => update(index, {title, link} as any)}
          />
        }
        title={title}
      />
      <div className={cx('FieldLink')}>
        <TextField
          classNameComponent={cx(!phone && 'ml-16px', phone && 'mt-16px', 'Link', classNameLink)}
          label={'Link'}
          {...register(`${name}.${index}.link` as TName)}
        />
        <button onClick={() => remove(index)}>&times;</button>
      </div>
    </div>
  )
}
