import React, {FC, memo} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import ContainerBgIcon from '@components/ContainerBgIcon';
import {ContainerBgIconType} from '@components/ContainerBgIcon/index.props';
import {QRSvg} from "@icons/qr";
import {useTranslation} from "react-i18next";
import {CustomersIcon} from './index.icon';
import {CustomersPropsType} from './index.props';
import {Typography} from "@components/UI/Typography";
import {TypographyVariant} from "@components/UI/Typography/index.props";

const cx = classNames.bind(s);


const Customers: FC<CustomersPropsType> = (): JSX.Element => {
  const data = {ticketsSold: {}} as any
  const error = false
  const loading = false
  const [t] = useTranslation()
  const component = <div className={'container'}>
    <h1 className={cx('Title')}>
      <CustomersIcon/>
      <Typography tag={TypographyVariant.h44}>
        Already <span>{data?.ticketsSold?.total ?? 0} satisfied customers</span> <br/>have used our service</Typography>
    </h1>
  </div>
  return (
    <section className={cx('Component')}>
      <ContainerBgIcon icons={icons} component={component}/>
    </section>
  );
};

export default memo(Customers);

const icons: Array<ContainerBgIconType> = [
  {
    icon: <QRSvg/>,
    axis: {
      top: -50,
      right: 100
    },
  }
]
