import {PaperPropsType} from "@components/UI/Paper/index.props";
import {FC} from "react";
import s from './index.module.scss';
import classNames from "classnames/bind";

const cx = classNames.bind(s);

export const Paper: FC<PaperPropsType> = ({children, view ='paper', className, ...props}) => {
  return (
    <div className={cx( className || '', {Ticket: view === 'ticket'},{Component:view==="paper"})} {...props}>
      <div className={cx('Circle', 'Right')}/>
      <div className={cx('Circle', 'Left')}/>
      {children}
    </div>
  )
}