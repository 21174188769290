import {CSSProperties, FC} from 'react';
import s from './index.module.scss';
import classNames from "classnames/bind";
import {DividerPropsType} from "@components/Divider/index.props";
import {OrientationSymmetric} from '@ts_types/index';

const cx = classNames.bind(s);

export const Divider: FC<DividerPropsType> =
  ({
     gap, color = 'var(--light-gray)', height = 1, className, style, maxWidth = '100%',
     orientation = OrientationSymmetric.Horizontal, ...divProps
   }) => {
    const styles = {
      marginTop: gap?.y || gap?.top || 0,
      marginBottom: gap?.y || gap?.bottom || 0,
      backgroundColor: color,
      maxWidth,
      transform: orientation === OrientationSymmetric.Horizontal ? 'none' : 'rotate(90deg)',
      height,
      ...style
    } as CSSProperties

    return (
      <div
        className={cx('Divider',  className ?? '')}
        style={styles}
        {...divProps}
      />
    )
  }