import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import {ru, uk, lv, lt, en} from './modules';

const resources = {
  en: {
    translation: en
  },
  lt: {
    translation: lt
  },
  ru: {
    translation: ru
  },
  uk: {
    translation: uk
  },
  lv: {
    translation: lv
  }
};


export default i18n;

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "en",
    // supportedLngs: Object.keys(resources),
    interpolation: {
      escapeValue: false // react already safes from xss
    },
  });
