import React, {FC, memo} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import FilterEvents from './FilterEvents';
import CardEvent from "@pages/Dashboard/Events/CardEvent";
import {Button, Center} from '@components/modules';
import {EventsNotFoundSvg} from "@icons/errors/eventsNotFound";
import {useEventsQuery} from "../../../api/generated";
import {useTranslation} from "react-i18next";
import {CardEventsPlaceholder} from './CardEvent/CardEventsPlacehoder';
import {Typography} from "@components/UI/Typography";
import {TextColor, TypographyVariant} from "@components/UI/Typography/index.props";

const cx = classNames.bind(s);

type EventsProps = {}

const Events: FC<EventsProps> = (props) => {
    const data = {events: {events: []}} as any
    const loading = false
    const refetch = () => null
    const [t, {language}] = useTranslation()
    return (
      <section className={cx('Component')}>
        <div className={'container'}>
          <FilterEvents refetch={refetch}/>
          <div className={cx('Events')}>
            {(loading) && <CardEventsPlaceholder/>}
            {data?.events?.events?.map((event: any, i: number) =>
              <CardEvent key={event?.slug ?? i}
                         title={event?.name ?? 'No name'}
                         event={event}/>
            )}
          </div>
          <Center>
            <Button outline>
              Show more
            </Button>
          </Center>
          {!data?.events?.events?.length && <Center height={500}>
            <div className={cx('EventsNotFound')}>
              <EventsNotFoundSvg/>
              <Typography
                tag={TypographyVariant.h26}
                color={TextColor.White}
                dangerouslySetInnerHTML={{__html: t("Sorry, no events found. Change search or filter options")}}/>
            </div>
          </Center>}
        </div>
      </section>
    );
  }
;

export default memo(Events);
