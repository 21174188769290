import {configureStore} from '@reduxjs/toolkit'
import landingReducer from "@store/reducers/landingReducer";
import base from "@store/reducers/base";
import eventEdit from "@store/reducers/eventEdit";

export const store = configureStore({
  reducer: {
    landing: landingReducer,
    base,
    eventEdit
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type ApplicationState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
