import React, {FC, memo} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import Offer from "@pages/Dashboard/Offers/Offer";
import offer_location_img from "@img/offer_location_bg.png"
import offer_event_img from "@img/offer_event_bg.png"
import {crown as CrownSvg} from "@icons/crown";
import {dollarSign as DollarSignSvg} from "@icons/dollarSign";

const cx = classNames.bind(s);

type OffersProps = {
}


const Offers: FC<OffersProps> = (props): JSX.Element => {
  const titleOwnEvent = `Create your own event\n and sell tickets online\n using <span>Satchel service</span>!`
  const titleLocation = `Do you have a suitable location\n and want to <span>make\n money</span> on it?`
  return (
    <section className={cx('Component')}>
      <div className={'container'}>
        <div className={cx('Offers')}>
          <Offer
            icon={<CrownSvg/>}
            title={titleOwnEvent}
            onClick={() => {
              // dispatch(setModalType(ModalType.Login))
              // props.setIsOpen(true)
            }}
            buttonText={'Create event'}
            bgImg={offer_event_img}/>
          <Offer bgImg={offer_location_img}
                 icon={<DollarSignSvg/>}
                 title={titleLocation}
                 buttonText={'Offer location'}
                 onClick={() => {
                   // dispatch(setModalType(ModalType.LocationOffer))
                   // props.setIsOpen(true)
                 }}
          />
        </div>
      </div>
    </section>
  );
};

export default memo(Offers);
