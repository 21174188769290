import {ColorEnum, DefaultIconPropsType} from "../../helpers/types"
import {FC} from "react";

export const CompassSvg:FC<DefaultIconPropsType> = ({fill=ColorEnum.black, width=28, height=28}) => (
  <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 0.666626C11.3629 0.666626 8.78505 1.44861 6.59239 2.9137C4.39974 4.37878 2.69077 6.46116 1.6816 8.89751C0.672434 11.3339 0.408389 14.0148 0.922859 16.6012C1.43733 19.1876 2.70721 21.5634 4.57191 23.4281C6.43661 25.2928 8.81238 26.5626 11.3988 27.0771C13.9852 27.5916 16.6661 27.3275 19.1024 26.3184C21.5388 25.3092 23.6212 23.6002 25.0863 21.4076C26.5513 19.2149 27.3333 16.637 27.3333 14C27.3333 12.249 26.9885 10.5152 26.3184 8.89751C25.6483 7.27984 24.6662 5.80998 23.4281 4.57187C22.19 3.33375 20.7201 2.35163 19.1024 1.68157C17.4848 1.0115 15.751 0.666626 14 0.666626ZM14 24.6666C11.8903 24.6666 9.82804 24.041 8.07391 22.869C6.31979 21.6969 4.95262 20.031 4.14528 18.0819C3.33795 16.1328 3.12671 13.9881 3.53829 11.919C3.94986 9.84987 4.96576 7.94925 6.45752 6.45749C7.94928 4.96573 9.8499 3.94983 11.919 3.53825C13.9882 3.12667 16.1329 3.33791 18.082 4.14524C20.031 4.95258 21.6969 6.31975 22.869 8.07388C24.0411 9.828 24.6667 11.8903 24.6667 14C24.6667 16.8289 23.5429 19.542 21.5425 21.5424C19.5421 23.5428 16.829 24.6666 14 24.6666Z"
      fill={`var(${fill})`}/>
    <path
      d="M18.9067 9.09336C18.7241 8.89666 18.4865 8.75962 18.2248 8.70015C17.9631 8.64068 17.6896 8.66155 17.44 8.76003L11.8267 11.0267C11.6616 11.0937 11.5117 11.1932 11.3858 11.3191C11.2598 11.4451 11.1604 11.595 11.0933 11.76L8.75999 17.44C8.67722 17.6634 8.65582 17.905 8.69803 18.1394C8.74024 18.3739 8.84451 18.5928 8.99999 18.7734H9.06666C9.17409 18.9271 9.31275 19.0564 9.47358 19.1529C9.63441 19.2494 9.81378 19.3109 9.99999 19.3334C10.1733 19.3355 10.3454 19.3038 10.5067 19.24L16.12 16.9734C16.285 16.9063 16.4349 16.8069 16.5609 16.6809C16.6868 16.555 16.7863 16.4051 16.8533 16.24L19.1867 10.56C19.2917 10.3155 19.3211 10.0452 19.2712 9.78382C19.2213 9.52245 19.0944 9.28198 18.9067 9.09336ZM12.4 15.6134L13.3467 13.2934L15.6 12.3867L14.6533 14.7067L12.4 15.6134Z"
      fill={`var(${fill})`}/>
  </svg>

)
