import React, {FC, memo, useRef, useState} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import {useOnClickOutside} from "../../../../../helpers/hooks/useOnClickOutside";
import {CalendarSvg} from "@icons/calendar";
import CalendarPeriod from '@components/Period'

const cx = classNames.bind(s);

type PeriodProps = {}

const Period: FC<PeriodProps> = (): JSX.Element => {
  const ref = useRef<any>()
  const [isShowCalendar, setIsShowCalendar] = useState(false)
  useOnClickOutside(ref, () => setIsShowCalendar(false))
  return (
    <div className={cx('Filter')} onClick={() => setIsShowCalendar(!isShowCalendar)} ref={ref}>
      <div className={cx('FilterIcon')}>
        <CalendarSvg width={16} />
      </div>
      <div className={cx('FilterItem')} >
        <span className={cx('FilterItemLabel')}

        >Pick the date</span>
        <span id={'additionalFilter'} className={cx('FilterItemTarget')}
        >
          From - To
        </span>
      </div>
      {isShowCalendar && <CalendarPeriod classNameWrap={cx('Calendar')}
                                         onChange={(from: Date, to: Date) => {

                                         }}
      />}
    </div>
    // <div className={cx('Component')} ref={ref}>
    //   <Button outline
    //           height={'100%'}
    //           icon={<PeriodSvg/>}
    //           onClick={() => setIsShowCalendar(!isShowCalendar)}
    //   >Period</Button>
    //   {isShowCalendar && <CalendarPeriod classNameWrap={cx('Calendar')}
    //                                      onChange={(from: Date, to: Date) => {
    //
    //                                      }}
    //   />}
    // </div>
  );
};

export default memo(Period);
