import React, {FC} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import Button from '@components/Button';
import {ModalType} from "types/modal-types";

const cx = classNames.bind(s);

type AuthControlProps = {
  openModal: (modalType: ModalType) => void
}

const AuthControl: FC<AuthControlProps> = ({openModal}): JSX.Element => {
  const login = () => {
      openModal(ModalType.Login)
  }
  return (
    <div>
      <Button onClick={login} size={"small"} widthMax outline>
        Log in
      </Button>
    </div>
  );
};

export default AuthControl;
