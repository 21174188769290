import React from 'react';
import usePageViews from "@helpers/hooks/usePageViews";
import {AdaptiveHelper, Footer, Routes} from '@components/modules';
import {isDev} from '@helpers/constants';
import {useMe} from "@helpers/sing";
import {AppLayout} from "../layouts/AppLayout";

const App = () => {
  useMe()
  usePageViews();
  return (
    <AppLayout>
      {isDev && <AdaptiveHelper/>}
      <Routes/>
    </AppLayout>
  );
};

export default App;
