import React, {FC, memo} from 'react';
import s from './index.module.scss'
import classNames from "classnames/bind";
import TextButton from '@components/TextButton';
import {ModalType} from "@ts_types/modal-types";

const cx = classNames.bind(s);

type AmbienceProps = {
  openModal: (modalType?: ModalType) => void
}


const Ambience: FC<AmbienceProps> = ({openModal}): JSX.Element => {
  // if (pathname.includes('/create-event')) {
  //   return (
  //     <div className={cx('Component')}>
  //       <div className={cx('SelectLng')}>
  //         <Select options={LANGUAGE_OPTIONS}
  //                 control={control}
  //                 id={'language'}
  //         />
  //       </div>
  //
  //       <div className={cx('SelectCurrency')}>
  //         <Select options={CurrencyList}
  //                 control={control}
  //                 id={'currency'}
  //         />
  //       </div>
  //     </div>
  //   )
  // }
  return (
    <div className={cx('Component', 'ComponentSimple')}>
      <TextButton
        underline={false}
        onClick={() => openModal(ModalType.SelectEnvironment)}>
        🇧🇷 <span>EUR</span>
      </TextButton>


    </div>
  );
};

export default memo(Ambience);
