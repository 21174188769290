import React, {memo} from 'react';
import Button from "@components/Button";
import s from './index.module.scss'

const BtnTicketBuy = () => {
  return (
    <div className={s.Btn}>
      <Button widthMax size={"small"}>
        {'Buy ticket'}
      </Button>
    </div>
  );
};

export default memo(BtnTicketBuy);
